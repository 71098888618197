import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'

import { Validate } from 'src/util/validation'
import { CreatePasswordReq } from 'src/types/auth'

type Props = {
    loading: boolean
    token: string
    onSubmit: (formData: CreatePasswordReq) => Promise<any>
}

const fields = {
    password: 'password',
    confirmPassword: 'confirm_password',
}

const CreatePasswordForm = (props: Props) => {
    const { t } = useTranslation()
    const { token } = props
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [isFormDirty, setIsFormDirty] = useState(false)

    useEffect(() => {
        if (isFormDirty) {
            const passwordErrMsg = Validate.password(password, {
                validateEmptyState: true,
            })
            if (passwordErrMsg) {
                setErrMessage(passwordErrMsg)
                setIsSubmitDisabled(true)
            } else if (password !== confirmPassword) {
                setErrMessage('Passwords have to be equal.')
                setIsSubmitDisabled(true)
            } else {
                setErrMessage('')
                setIsSubmitDisabled(false)
            }
        }
    }, [password, confirmPassword, isFormDirty])

    const handleSubmit = () => {
        props
            .onSubmit({ password, confirm_password: confirmPassword, token })
            .catch(() =>
                setErrMessage('Something went wrong. Please try again.')
            )
    }

    const setValue = (value: any, field: string) => {
        if (!isFormDirty) {
            setIsFormDirty(true)
        }
        if (field === fields.password) {
            setPassword(value)
        }
        if (field === fields.confirmPassword) {
            setConfirmPassword(value)
        }
    }

    return (
        <Form
            name="create-password"
            layout="vertical"
            onFinish={handleSubmit}
            className="login-form"
        >
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: t('createPasswordForm.enterPassword'),
                    },
                ]}
            >
                <Input
                    type="password"
                    name={fields.password}
                    placeholder="Password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setValue(e.target.value, fields.password)}
                />
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: t('createPasswordForm.enterPasswordConfirm'),
                    },
                ]}
            >
                <Input
                    type="password"
                    name={fields.confirmPassword}
                    placeholder="Confirm password"
                    autoComplete="off"
                    value={confirmPassword}
                    onChange={(e) =>
                        setValue(e.target.value, fields.confirmPassword)
                    }
                />
            </Form.Item>

            <Button
                type="primary"
                htmlType="submit"
                shape="round"
                block
                disabled={isSubmitDisabled || !props.token}
            >
                {props.loading ? 'Submitting...' : 'Create password'}
            </Button>

            {errMessage && <p style={{ marginTop: '10px' }}>{errMessage}</p>}
            {!props.token && (
                <p style={{ marginTop: '10px' }}>
                    An <b>invitation token</b> must be provided in order to
                    create a password.
                </p>
            )}
        </Form>
    )
}

export default CreatePasswordForm

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { Card, Modal, Row, Col, Form, Input, Upload, Button, Drawer, message } from 'antd'
import {
    PlusCircleOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    LoadingOutlined,
    PlusOutlined,
    SyncOutlined,
    CheckOutlined,
    DragOutlined,
} from '@ant-design/icons'
import { editorConfig } from 'src/util/wysiwyg'

import { DndContext, closestCenter, PointerSensor, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    rectSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

// @ts-ignore
import OpenAI from '@tectalic/openai'

import ImageAPI from 'src/api/image'
import { useDisease } from 'src/context/disease'
import { beforeUpload } from 'src/util/images'
import PROMPTS from 'src/util/propmts'
import './style.scss'

const { Meta } = Card
const { confirm } = Modal
const { TextArea } = Input
const IMG_BASE_URL = process.env.REACT_APP_IMG_BASE_URL

const ImageSelector = (props: any) => {
    const getDefaultNewImage = () => ({
        id: '',
        title: '',
        description: '',
        imageName: '',
    })

    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [isUploadingNewImage, setIsUploadingNewImage] = React.useState(false)
    const [mode, setMode] = React.useState('create')
    const [newImage, setNewImage] = React.useState(getDefaultNewImage())
    const [editImageIndex, setEditImageIndex] = React.useState<any>(null)
    const { t } = useTranslation()
    const { images, diseaseMode, groupedTabs } = props
    const [orderedImages, setOrderedImages] = React.useState<any>([])
    const [selectedNewTab, setSelectedNewTab] = React.useState<any>(null)

    const [isDrawerVisible, setIsDrawerVisible] = React.useState(false)
    const [isRephrasing, setIsRephrasing] = React.useState(false)
    const [rephrasePreview, setRephrasePreview] = React.useState('')

    const disease: any = useDisease()
    const diseaseForm = disease ? disease.disease : { tabs: {} }
    const setDiseaseForm: any = disease.setDisease

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    React.useEffect(() => {
        if (images && images.length) {
            setOrderedImages(images.sort((a: any, b: any) => a.ordering - b.ordering))
        }
    }, [images])

    const handleViewImage = (i: number, image: any) => {
        setMode('view')
        setNewImage({
            id: image.id,
            title: image.alt || image.title,
            description: image.description,
            imageName: image.filename || image.imageName,
        })
        setIsModalVisible(true)
    }

    const handleEditImage = (i: number, image: any) => {
        setRephrasePreview('')
        setMode('edit')
        setNewImage({
            id: image.id,
            title: image.alt || image.title,
            description: image.description,
            imageName: image.filename || image.imageName,
        })
        setEditImageIndex(i)
        setIsModalVisible(true)
    }

    const handleRemoveImage = (i: number, image: any) => {
        confirm({
            title: t('diseaseCreation.form.removeImageConfirm'),
            okText: t('yes'),
            cancelText: t('cancel'),
            onOk() {
                props.onRemove(i, image)
            },
        })
    }

    const handleShowAddNew = () => {
        setRephrasePreview('')
        setMode('create')
        setIsModalVisible(true)
    }

    const handleHideModal = () => {
        setNewImage({ ...getDefaultNewImage() })
        setEditImageIndex(null)
        setIsModalVisible(false)
    }

    const handleUpdateNew = (field: string, value: any) => {
        let ni: any = newImage
        ni[field] = value
        setNewImage({ ...ni })
    }

    const handleUpload = async (file: any): Promise<any> => {
        setIsUploadingNewImage(true)

        try {
            const res = await ImageAPI.upload(file, newImage.title, newImage.description)

            if (!res || !res.data || res.status !== 200) {
                throw new Error('Bad response')
            }

            setIsUploadingNewImage(false)
            handleUpdateNew('id', res.data.id)
            handleUpdateNew('imageName', res.data.filename)
            handleUpdateNew('ordering', res.data.ordering)
            return Promise.resolve(IMG_BASE_URL + '/1/' + res.data.filename)
        } catch (error) {
            setIsUploadingNewImage(false)
            message.error(t('diseaseCreation.form.addImageError'))
            console.log('Error uploading image: ', error)
            return Promise.reject('error')
        }
    }

    const handleSubmit = () => {
        if (mode === 'create') {
            props.onAdd(newImage)
            handleHideModal()
        } else if (mode === 'edit') {
            props.onEdit(editImageIndex, newImage)
            handleUpdateImage(newImage)
        }
    }

    const handleUpdateImage = async (imageDetails: any) => {
        try {
            const res = await ImageAPI.update(imageDetails.id, {
                name: imageDetails.title,
                description: imageDetails.description,
            })

            if (!res || res.status !== 200) {
                throw new Error('Bad response')
            }

            handleHideModal()
            setIsUploadingNewImage(false)
            message.success('Image updated successfully')
        } catch (error) {
            setIsUploadingNewImage(false)
            message.error(t('diseaseCreation.form.addImageError'))
            console.log('Error uploading image: ', error)
        }
    }

    const handleShowDrawer = () => {
        setIsDrawerVisible(true)
    }

    const handleHideDrawer = () => {
        setIsDrawerVisible(false)
    }

    const handleRephrase = (value: string | undefined, opts?: any) => {
        setIsRephrasing(true)

        const command = PROMPTS.rephrase

        OpenAI(process.env.REACT_APP_GPT_TOKEN)
            .chatCompletions.create({
                model: 'gpt-4',
                messages: [
                    {
                        role: 'user',
                        content: `${command} ` + value,
                    },
                ],
            })
            .then((response: { data: { choices: { message: { content: string } }[] } }) => {
                setIsRephrasing(false)
                setRephrasePreview(response.data.choices[0].message.content.trim())
            })
            .catch((error: any) => {
                setIsRephrasing(false)
                message.error('Error rephrasing image description')
                console.log('Error rephrasing image description:', error)
            })
    }

    const handleSaveRephrased = () => {
        setNewImage({ ...newImage, description: rephrasePreview })
        handleHideDrawer()
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setOrderedImages((orderedImages: any) => {
                const oldIndex = orderedImages.findIndex((i: any) => i.id === active.id)
                const newIndex = orderedImages.findIndex((i: any) => i.id === over.id)

                return arrayMove(orderedImages, oldIndex, newIndex)
            })
        }
    }

    const handleSaveOrder = () => {
        props.onSaveOrder(orderedImages)
    }

    const handleMoveToTab = (imageIndex: number, newTabId: string) => {
        const currentTabId = props.tabId
        let newDiseaseForm = { ...diseaseForm }

        // Update new tab
        if (!newDiseaseForm.tabs[newTabId]) {
            newDiseaseForm.tabs[newTabId] = { images: [], description: '', keywords: [], note: '' }
        }

        if (!newDiseaseForm.tabs[newTabId].images) {
            newDiseaseForm.tabs[newTabId].images = []
        }

        // Move image to new tab
        let movedImage = orderedImages[imageIndex]
        movedImage.ordering = newDiseaseForm.tabs[newTabId].images ? newDiseaseForm.tabs[newTabId].images.length + 1 : 1

        // Update current tab
        const currentTab = newDiseaseForm.tabs[currentTabId]
        currentTab.images.splice(imageIndex, 1)

        const newTab = newDiseaseForm.tabs[newTabId]
        const newTabImages = newTab.images
        newTabImages.push(movedImage)

        // Update diseaseForm
        newDiseaseForm.tabs = {
            ...newDiseaseForm.tabs,
            [currentTabId]: {
                ...currentTab,
                images: currentTab.images,
            },
            [newTabId]: {
                ...newTab,
                images: newTabImages,
            },
        }

        setDiseaseForm(newDiseaseForm)

        message.success('Image moved to the selected tab')
    }

    const isAddImageBtnDisabled = newImage.title && newImage.imageName ? false : true

    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            {diseaseMode !== 'create' && orderedImages && orderedImages.length ? (
                <div style={{ position: 'absolute', top: '-43px', left: '70px' }}>
                    {/* <small
                        style={{
                            marginRight: 10,
                            background: '#a3caff',
                            padding: '9px 14px',
                            borderRadius: '6px',
                            fontWeight: '600'
                        }}
                    >
                        After adding or deleting images, first save the disease (click "Save Changes" on the left) and then click "Save Order"
                    </small> */}

                    <Button onClick={handleSaveOrder} style={{ marginTop: 5, borderRadius: 2 }}>
                        Save Order
                    </Button>
                </div>
            ) : null}
            <div className="image-selector-wrapper">
                <div className="image-selector-item">
                    <div className="add-image-card" onClick={handleShowAddNew}>
                        <PlusCircleOutlined style={{ marginBottom: 20 }} />
                        {!orderedImages || !orderedImages.length
                            ? t('diseaseCreation.form.addImage')
                            : t('diseaseCreation.form.addAnotherImage')}
                    </div>
                </div>

                {orderedImages && orderedImages.length ? (
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={orderedImages} strategy={rectSortingStrategy}>
                            {orderedImages.map((image: any, i: number) => {
                                return (
                                    <ImageCard
                                        data-no-dnd="true"
                                        key={i}
                                        index={i}
                                        image={image}
                                        enableDrag={diseaseMode !== 'create'}
                                        handleEditImage={handleEditImage}
                                        handleViewImage={handleViewImage}
                                        handleRemoveImage={handleRemoveImage}
                                    />
                                )
                            })}
                        </SortableContext>
                    </DndContext>
                ) : null}
            </div>

            {/* Add & Edit Image Modal */}
            <Modal
                visible={isModalVisible}
                className={`add-new-image-modal --${mode}`}
                maskClosable={false}
                title={
                    mode === 'view'
                        ? `${props.tabName} Image`
                        : `${props.tabName}: ${mode === 'create' ? t('diseaseCreation.form.addImage') : 'Edit image'}`
                }
                cancelText={t('close')}
                okText={mode === 'create' ? t('diseaseCreation.form.addImage') : t('diseaseCreation.form.editImage')}
                okButtonProps={{
                    disabled: isAddImageBtnDisabled,
                }}
                onOk={handleSubmit}
                onCancel={handleHideModal}
                width={1000}
            >
                <Form name="addNewImage" layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label={t('diseaseCreation.form.imageTitleLabel')}>
                                {mode !== 'view' ? (
                                    <Input
                                        placeholder={t('diseaseCreation.form.imageTitle')}
                                        value={newImage.title}
                                        onChange={(e) => {
                                            handleUpdateNew('title', e.target.value)
                                        }}
                                    />
                                ) : (
                                    <h3>{newImage.title}</h3>
                                )}
                            </Form.Item>
                            <Form.Item label={t('diseaseCreation.form.imageDescription')}>
                                {mode !== 'view' ? (
                                    <TextArea
                                        defaultValue=""
                                        value={newImage.description}
                                        rows={15}
                                        placeholder={t('diseaseCreation.form.imageDescriptionPlaceholder')}
                                        onChange={(e) => {
                                            handleUpdateNew('description', e.target.value)
                                        }}
                                        maxLength={1000}
                                    />
                                ) : (
                                    <div>
                                        <p>{newImage.description}</p>
                                    </div>
                                )}

                                {mode !== 'view' && process.env.REACT_APP_GPT_TOKEN ? (
                                    <>
                                        <Button
                                            disabled={!newImage.description}
                                            onClick={handleShowDrawer}
                                            style={{ marginTop: 5, borderRadius: 2 }}
                                        >
                                            Rephrase
                                        </Button>
                                        <Drawer
                                            title="Rephrase"
                                            placement="right"
                                            visible={isDrawerVisible}
                                            onClose={handleHideDrawer}
                                            width={640}
                                        >
                                            <h4>Original</h4>
                                            <ReactQuill
                                                readOnly={true}
                                                value={newImage.description || ''}
                                                modules={{
                                                    toolbar: false,
                                                }}
                                            />
                                            <br />
                                            <Button
                                                type="primary"
                                                onClick={() => handleRephrase(newImage.description)}
                                                style={{ borderRadius: 3 }}
                                            >
                                                <SyncOutlined spin={isRephrasing} />
                                                Rephrase
                                            </Button>

                                            <br />
                                            <br />
                                            <hr />
                                            <br />
                                            <h4>Rephrased</h4>
                                            <ReactQuill
                                                formats={editorConfig.formats}
                                                modules={editorConfig.modules}
                                                value={rephrasePreview || ''}
                                            />
                                            <br />
                                            <Button
                                                type="primary"
                                                onClick={handleSaveRephrased}
                                                style={{ borderRadius: 3 }}
                                            >
                                                <CheckOutlined />
                                                Use Rephrased Text
                                            </Button>
                                        </Drawer>
                                    </>
                                ) : null}
                            </Form.Item>

                            {/* Move image to another tab */}
                            {mode === 'edit' && groupedTabs && groupedTabs.length > 1 ? (
                                <Form.Item
                                    label={t('diseaseCreation.form.moveImageToTab')}
                                    style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '12px',
                                        borderRadius: '3px',
                                        width: '90%',
                                        background: '#f6f6f6',
                                    }}
                                >
                                    <select
                                        className="ant-select ant-select-enabled"
                                        defaultValue=""
                                        onChange={(e) => {
                                            setSelectedNewTab(e.target.value)
                                        }}
                                        style={{
                                            width: '90%',
                                            height: '32px',
                                            border: '1px solid #ccc',
                                            padding: '0 6px',
                                            borderRadius: '3px',
                                        }}
                                    >
                                        <option value=""></option>
                                        {groupedTabs.map((tab: any, i: number) => {
                                            return (
                                                <option key={i} value={tab.id}>
                                                    {tab.step.name} - {tab.name}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    <Button
                                        type="default"
                                        disabled={!selectedNewTab}
                                        onClick={() => {
                                            handleMoveToTab(editImageIndex, selectedNewTab)
                                            handleHideModal()
                                        }}
                                        style={{
                                            borderRadius: 3,
                                            marginTop: 5,
                                            background: !selectedNewTab ? '#eee' : '#2a78e9',
                                            color: !selectedNewTab ? '#ccc' : '#fff',
                                        }}
                                    >
                                        Move Image To The Selected Tab
                                    </Button>
                                </Form.Item>
                            ) : null}
                        </Col>
                        <Col span={12}>
                            {mode !== 'view' ? (
                                <Form.Item label={t('diseaseCreation.form.imageUpload')}>
                                    <Upload
                                        name="image"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        action={handleUpload}
                                        disabled={!(newImage.title && newImage.description)}
                                    >
                                        {newImage.imageName ? (
                                            <img
                                                src={IMG_BASE_URL + '/1/' + newImage.imageName}
                                                alt="avatar"
                                                width="100%"
                                            />
                                        ) : isUploadingNewImage ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <div className="avatar-uploader-action">
                                                <PlusOutlined />
                                                <p>{t('diseaseCreation.form.imageUploadAreaDescription')}</p>
                                            </div>
                                        )}
                                    </Upload>

                                    {isUploadingNewImage && (
                                        <p>
                                            <LoadingOutlined /> {t('diseaseCreation.form.imageUploading')}
                                        </p>
                                    )}
                                </Form.Item>
                            ) : (
                                <img src={IMG_BASE_URL + '/1/' + newImage.imageName} alt="avatar" width="100%" />
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

const ImageCard = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.image.id,
        transition: null,
    })

    const fileName = props.image.imageName || props.image.filename

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <div className="image-selector-item" ref={setNodeRef} style={style}>
            <Card
                hoverable
                style={{ height: 260, width: 220 }}
                className="image-card"
                cover={<img alt="" src={IMG_BASE_URL + '/1/' + fileName} />}
            >
                <Meta
                    title={props.image.alt || props.image.title}
                    description={
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    props.image.description && props.image.description.length > 30
                                        ? `${props.image.description.substring(0, 30)}...`
                                        : props.image.description,
                            }}
                        ></div>
                    }
                />
                {props.enableDrag ? <DragOutlined {...attributes} {...listeners} /> : <span></span>}
                <EditOutlined onClick={() => props.handleEditImage(props.index, props.image)} />
                <EyeOutlined onClick={() => props.handleViewImage(props.index, props.image)} />
                <DeleteOutlined onClick={() => props.handleRemoveImage(props.index, props.image)} />
            </Card>
        </div>
    )
}

export default ImageSelector

export const validateEmail = (email: string) => {
    const errMsg = 'Please enter a valid email'
    if (!email) {
        return errMsg
    }

    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(String(email).toLowerCase())
    if (!isValid) {
        return errMsg
    }
}

type PasswordValidationConfig = {
    validateEmptyState: boolean
}

export const validatePassword = (
    password: string,
    config?: PasswordValidationConfig
) => {
    const errMsg = 'Password has to have at least 8 characters.'

    if (config && config.validateEmptyState && !password) {
        return errMsg
    }

    // TODO: add regex-based validation when we have the password rules
    if (!!password && password.length < 8) {
        return errMsg
    }
}

export const Validate = {
    email: validateEmail,
    password: validatePassword,
}

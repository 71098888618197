import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Section } from "src/types";

const { TextArea } = Input;

type Props = {
  onOk: Function;
  onCancel: Function;
  isVisible: boolean;
  section?: Section;
}

const SectionModal = (props: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const { section } = props;

  useEffect(() => {
    form.setFieldsValue({
      name: section?.name || '',
      description: section?.description || '',
    });
  }, [form, section]);

  const onFormSubmit = () => {
    setIsSubmitting(true);
    form.validateFields(['name'])
      .then(() => {
        props.onOk(props.section?.id, form.getFieldsValue());
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsSubmitting(false)
      });
  }


  return (
    <Modal
      maskClosable={false}
      visible={props.isVisible}
      title={props.section?.id ? t("sections.edit_section") : t("sections.create_section")}
      onCancel={() => props.onCancel()}
      onOk={() => onFormSubmit()}
      okText={props.section?.id ? t("saveChanges") : t("create")}
      okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input placeholder={t("name")} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onFormSubmit();
            }
          }} />
        </Form.Item>
        <Form.Item
          label={t("description")}
          name="description"
        >
          <TextArea rows={4} placeholder={t("description")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SectionModal;

import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Table,
  Checkbox,
  Popconfirm,
  message,
  Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ListViewHeader, { EditableTitle } from 'src/components/common/list-view/header';
import KeywordGroupsAPI from 'src/api/keyword-groups';
import KeywordsAPI from 'src/api/keywords';
import withPrimaryLayout from 'src/components/layout/primaryLayout';
import { KeywordGroup } from 'src/types/keywordGroup';
import { Keyword } from 'src/types/keyword';
import KeywordModal from './modal/keyword';

const KeywordGroupPage = () => {
  const { t } = useTranslation();
  const { keywordGroupId }: any = useParams();

  const [keywordGroup, setKeywordGroup] = useState<KeywordGroup>();
  const [keywordGroupNameEditVisible, setKeywordGroupNameEditVisible] = useState(false);
  const [newKeywordName, setNewKeywordName] = useState<any>('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalKeyword, setModalKeyword] = useState<any>(null);
  const [keywords, setKeywords] = useState<any>([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [dataSource, setDataSource] = useState<any>(null);
  const [columns, setColumns] = useState<any>([]);

  const fetchKeywordGroup = useCallback(
    async () => {
      setIsDataLoading(true);
      try {
        const res = await KeywordGroupsAPI.findById(keywordGroupId);
        if (!res || res.status !== 200) {
          throw new Error('Bad response');
        }
        setKeywordGroup(res.data);
        const sortedKeywords = (res.data && res.data.keywords) ? res.data.keywords.sort((a : any, b : any) => a.ordering - b.ordering) : [];
        setKeywords(sortedKeywords);
        setNewKeywordName(res.data.name);
      } catch (error) {
        message.error(t('errors.apiCommunication', { api: 'keyword group' }));
      } finally {
        setIsDataLoading(false);
      }
    },
    [t, keywordGroupId],
  )

  useEffect(() => {
    fetchKeywordGroup();
  }, [fetchKeywordGroup]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredKeywords(
        keywords.filter((keyword: any) => keyword.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
      );
    } else {
      setFilteredKeywords(keywords);
    }
  }, [searchQuery, keywords])

  const onDeleteKeyword = useCallback(
    async (keyword: Keyword) => {
      try {
        await KeywordsAPI.delete(keyword.id);
        message.success(t('keywords.keywordDeleted', { name: keyword.name }));
        setKeywords(keywords.filter((k: Keyword) => k.id !== keyword.id));
        setSearchQuery('');
      } catch (error) {
        message.error(t('errors.apiCommunication', { api: 'keyword group' }));
      }
    },
    [t, keywords]
  );

  const onSearch = (searchQuery: string) => {
    setCurrentPage(1);
    setSearchQuery(searchQuery);
  };

  const onPageChange = (page: number) => setCurrentPage(page);

  useEffect(() => {
    if (filteredKeywords && filteredKeywords.length >= 0) {
      const dataSource = filteredKeywords
      .map((keyword: any) => ({
          id: keyword.id,
          key: keyword.id,
          name: keyword.name,
          description: keyword.description,
          ordering: keyword.ordering,
          comment_enabled: keyword.comment_enabled,
        })
      );
      const columns = [
        {
          title: t('keywords.table.columns.name'),
          dataIndex: 'name',
          key: `name`,
          width: '30%',
          render: (val: any) => val,
        },
        {
          title: t('keywords.table.columns.description'),
          dataIndex: 'description',
          key: `description`,
          ellipsis: {
            showTitle: false,
          },          
          render: (description: string) => (
            <Tooltip placement='topLeft' title={description}>
              {description}
            </Tooltip>
          ),
        },
        {
          title: t('comment_enabled'),
          dataIndex: 'comment_enabled',
          key: `comment_enabled`,
          width: '15%',
          render: (val: any) => <div style={{textAlign: 'center'}}><Checkbox checked={val} disabled/></div>,
        },
        {
          title: 'Edit/Delete',
          width: '10%',
          key: 'operation',
          align: 'right' as const,
          render: (value: any, record: any) => (
            <div style={{ marginTop: '-15px' }}>
              <Button
                type='primary'
                style={{ marginTop: 15 }}
                shape='round'
                size='small'
                onClick={() => {
                  setModalVisible(true);
                  setModalKeyword(record);
                }}
              >
                <EditOutlined />
              </Button>
    
              <Popconfirm
                placement='topLeft'
                title={t('keywords.deletePopup')}
                onConfirm={() => onDeleteKeyword(record)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Button
                  danger
                  type='primary'
                  shape='round'
                  size='small'
                  style={{ marginTop: 15, marginLeft: 10 }}
                >
                  <CloseCircleOutlined />
                </Button>
              </Popconfirm>
            </div>
          )
        },
      ]
      setDataSource(dataSource);
      setColumns(columns);
    }
  }, [filteredKeywords, filteredKeywords.length, onDeleteKeyword, t, keywordGroup]);

  const renderKeywordsList = () => {
    return (
      <Table
        pagination={{
          pageSize: 30,
          hideOnSinglePage: true,
          current: currentPage,
          onChange: onPageChange,
        }}
        loading={isDataLoading}
        dataSource={dataSource}
        columns={columns}
      />
    );
  };

  const onApplyKeywordModalChanges = async (id: any, formValues: any) => {
    const keyword = { id, ...formValues } as Keyword;
    keyword.keyword_group_id = Number(keywordGroupId);
    try {
      const res = await KeywordsAPI.save(keyword);

      if (!res || res.status !== 200) {
        throw new Error('Bad response');
      }

      setModalVisible(false);
      setModalKeyword(null);

      message.success(t('keywords.updated'));

      if (!id) {
        fetchKeywordGroup();
      } else {
        const k = keywords.map((k: Keyword) => {
          if (k.id === id) {
            return keyword;
          }
          return k;
        });
        const s = k.sort((a : any, b : any) => a.ordering - b.ordering);
        setKeywords(s);
      }
      
    } catch (error) {
      message.error(t('errors.apiCommunication', { api: 'keyword group' }));
    }
  }

  const editableTitle = {
    isTitleEditable: true,
    newTitleEditVisible: keywordGroupNameEditVisible,
    setNewTitleVisible: setKeywordGroupNameEditVisible,
    newTitleValue: newKeywordName,
    setNewTitleValue: setNewKeywordName,
    onTitleClick: () => {
      setKeywordGroupNameEditVisible(!keywordGroupNameEditVisible);
      setNewKeywordName(keywordGroup?.name);
    },
    onSaveNewTitle: () => {
      if (keywordGroup) {
        keywordGroup.name = newKeywordName;
        setKeywordGroupNameEditVisible(!keywordGroupNameEditVisible);
        try {
          KeywordGroupsAPI.save(keywordGroup);
        } catch (error) {
          console.error(error);
        }
      }
    }
  } as EditableTitle;

  return (
    <div className='page keywords'>
      <KeywordModal
        onOk={onApplyKeywordModalChanges}
        onCancel={() => { setModalVisible(false); setModalKeyword(null); }}
        isVisible={modalVisible}
        keyword={modalKeyword}
        keywordGroupName={keywordGroup?.name || ''}
      />
      <div className='container m-t-11'>
        <ListViewHeader
          editableTitle={editableTitle}
          title={keywordGroup?.name || ''}
          buttonLabel={t('keywords.add')}
          isLoading={isDataLoading}
          onButtonClick={() => {
            setModalVisible(true);
            setModalKeyword({});
          }}
          onSearch={onSearch}
        />
        <div className='page-content m-t-8'>
          {renderKeywordsList()}
        </div>
      </div>
    </div>
  );
};

export default withPrimaryLayout(KeywordGroupPage);

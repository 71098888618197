import * as React from 'react';
import { Tabs } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
};

const MICROSCOPIC_TABS = [
    {
        "id": 14,
        "name": "Microscopic",
        "step_id": 4
    },
    {
        "id": 15,
        "name": "Grading",
        "step_id": 4
    },
    {
        "id": 16,
        "name": "Staging",
        "step_id": 4
    },
    {
        "id": 17,
        "name": "Cytology",
        "step_id": 4
    },
    {
        "id": 18,
        "name": "Small biopsy",
        "step_id": 4
    },
    {
        "id": 19,
        "name": "Frozen section",
        "step_id": 4
    },
    {
        "id": 20,
        "name": "Pattern/Algor approach",
        "step_id": 4
    },
    {
        "id": 21,
        "name": "Reporting",
        "step_id": 4
    },
    {
        "id": 22,
        "name": "Therapy effects",
        "step_id": 4
    },
    {
        "id": 23,
        "name": "Artefacts",
        "step_id": 4
    },
    {
        "id": 24,
        "name": "Normal",
        "step_id": 4
    }
];

const Microscopic = (props: Props) => {
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {MICROSCOPIC_TABS.map(((mt, key) => (
            <TabPane
            key={key}
            tab={props.tabs[mt.id].name}
            closable={false}>
                <Tab
                tab={props.tabs[mt.id]}
                diseaseForm={diseaseForm}
                tabDescPlaceholder={'todo'}
                />
            </TabPane>            
        )))}
      </Tabs>
    </div>
  );
};

export default Microscopic;

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Form, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { useDisease } from 'src/context/disease'
import { editorConfig } from 'src/util/wysiwyg'
import RichText from 'src/components/common/inputs/rich-text'

const { TabPane } = Tabs

type Props = {
    step: any
    tabs: {}
}

const Resources = (props: Props) => {
    const { t } = useTranslation()
    const disease: any = useDisease()
    const diseaseForm = disease ? disease.disease : {}
    const setDiseaseForm: any = disease.setDisease

    const handleReferencesChange = (d: string) => {
        let df = diseaseForm || {}
        df.references = d
        setDiseaseForm({ ...df })
    }

    const handleResourcesChange = (d: string) => {
        let df = diseaseForm || {}
        df.resources = d
        setDiseaseForm({ ...df })
    }

    return (
        <div>
            <Tabs defaultActiveKey="0" type="card" size="large">
                <TabPane tab={t('diseaseCreation.form.references_resources')} key="0">
                    <Row>
                        <Col span={20}>
                            <Form.Item label={t('diseaseCreation.form.references')}>
                                <RichText
                                    editorConfig={editorConfig}
                                    value={diseaseForm?.resources || ''}
                                    placeholder={t('diseaseCreation.form.referencesPlaceholder')}
                                    enableRephrase={true}
                                    onChange={handleResourcesChange}
                                    onUseRephrased={(text: string) => {
                                      handleResourcesChange(text)
                                    }}
                                />
                            </Form.Item>
                            <p>
                                <InfoCircleOutlined style={{ marginRight: 5 }} />
                                Tip: You can easily create formatted references using{' '}
                                <a
                                    href="https://www.citethisforme.com/vancouver/source-type"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    this tool
                                </a>
                                .
                            </p>
                        </Col>

                        <Col span={20}>
                            <Form.Item label={t('diseaseCreation.form.resources')}>
                                <RichText
                                    editorConfig={editorConfig}
                                    value={diseaseForm?.references || ''}
                                    placeholder={t('diseaseCreation.form.resourcesPlaceholder')}
                                    enableRephrase={true}
                                    onChange={handleReferencesChange}
                                    onUseRephrased={(text: string) => {
                                      handleReferencesChange(text)
                                    }}
                                />                                
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Resources

import { AuthProvider } from 'src/context/auth'
import { UserProvider } from 'src/context/user'

export const AppProviders = (props: any) => {
    return (
        <AuthProvider>
            <UserProvider>{props.children}</UserProvider>
        </AuthProvider>
    )
}

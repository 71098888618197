import { useCallback, useEffect, useState } from 'react'
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Table, Popconfirm, message, Tooltip } from 'antd'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'

import withPrimaryLayout from 'src/components/layout/primaryLayout'
import ListViewHeader from 'src/components/common/list-view/header'
import { Section, SearchParams } from 'src/types'
import { SectionsAPI } from 'src/api'
import SectionModal from './modal/section'
import { qp } from 'src/util/url'

const Sections = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchSectionsErrorMsg = t('sections.fetchError')

    const [isDataLoading, setSectionsLoading] = useState<boolean>(false)
    const [sections, setSections]: [Array<Section>, any] = useState([])
    const [totalSections, setTotalSections] = useState(0)
    const [activeSection, setActiveSection]: [Section, any] = useState(
        {} as Section
    )
    const [isSectionModalVisible, setSectionModalVisible] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [searchQParams, setSearchParams] = useState<SearchParams>({
        q: searchParams.get('q') || '',
        page: Number(searchParams.get('page') || 1),
    })

    const memoizedGetSections = useCallback(
        async (searchParams: SearchParams) => {
            setSectionsLoading(true)
            try {
                const res = await SectionsAPI.list(
                    searchParams.page,
                    searchParams.q
                )
                if (
                    !res ||
                    !res.data
                ) {
                    throw new Error('Bad response')
                }

                if (res?.data) {
                    const data = res.data.map((item: Section) => {
                        return { key: item.id, ...item }
                    })
                    setSections(data)
                    setTotalSections(parseInt(res.headers['total-items']))
                    setTotalPages(parseInt(res.headers['total-pages']))
                }
            } catch (error) {
                console.error(error)
                message.error(fetchSectionsErrorMsg)
            } finally {
                setSectionsLoading(false)
            }
        },
        [fetchSectionsErrorMsg]
    )

    useEffect(() => {
        memoizedGetSections(searchQParams)
    }, [memoizedGetSections, searchQParams])

    const onSaveSectionModalChanges = async (id: number, formValues: any) => {
        try {
            const section = { id, ...formValues } as Section
            const result = await SectionsAPI.save(section)
            if (!result || result.status !== 200) {
                throw new Error('Bad response')
            }
            setSectionModalVisible(false)
            setActiveSection(null)

            if (!id) {
                onPageChange(totalPages)
            } else {
                onPageChange(1)
            }

            message.success(
                id
                    ? t('sections.updated', { name: section.name })
                    : t('sections.added', { name: section.name })
            )
        } catch (error) {
            console.error(error)
            message.error(t('sections.errorCreating'))
        }
    }

    const onDeleteSection = async (section: Section) => {
        try {
            const result = await SectionsAPI.delete(section.id)
            if (result?.status === 200) {
                message.success(
                    t('sections.sectionDeleted', { name: section.name })
                )           
                onSearch('')
            }
        } catch (error) {
            console.error(error)
            message.error(t('sections.errorDeleting'))
        }
    }

    const onPageChange = (page: number) => {
        const q = searchParams.get('q') || ''
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: q, page }),
        })
        setSearchParams({ page, q })

        if (page === searchQParams.page) {
            memoizedGetSections(searchQParams)
        }
    }

    const onSearch = (searchQuery: string) => {
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery }),
        })
        setSearchParams({
            q: searchQuery.trim(),
            page: 1,
        })
    }

    const renderSectionsList = () => {
        const sectionsPagination: TablePaginationConfig = {
            pageSize: 30,
            total: totalSections,
            hideOnSinglePage: true,
            showSizeChanger: false,
            current: Number(searchQParams.page),
            onChange: onPageChange,
        }
        const columns = [
            {
                title: t('sections.table.columns.name'),
                dataIndex: 'name',
                key: 'name',
                width: 400,
                render: (name: string, record: Section) => (
                    <Link to={`/sections/${record.id}/chapters`}>{name}</Link>
                ),
            },
            {
                title: t('sections.table.columns.description'),
                dataIndex: 'description',
                key: 'description',
                ellipsis: {
                    showTitle: false,
                },
                render: (description: string) => (
                    <Tooltip placement="topLeft" title={description}>
                        {description}
                    </Tooltip>
                ),
            },
            {
                title: t('sections.table.columns.chapters'),
                dataIndex: 'chapters_count',
                key: 'chapters_count',
                align: 'center' as const,
                render: (count: number) => (
                    <span
                        style={{
                            color: '#6213ea',
                            fontSize: '18px',
                            fontWeight: 600,
                        }}
                    >
                        {count}
                    </span>
                ),
            },
            {
                title: 'Edit/Delete',
                key: 'action',
                align: 'right' as const,
                render: (value: any, record: any) => (
                    <>
                        <Button
                            type="primary"
                            shape="round"
                            title={t('edit')}
                            style={{ marginLeft: 10 }}
                            size="small"
                            onClick={() => {
                                setActiveSection(record)
                                setSectionModalVisible(true)
                            }}
                        >
                            <EditOutlined />
                        </Button>
                        {record.chapters_count === 0 && (
                            <Popconfirm
                                placement="topLeft"
                                title={t('sections.deletePopup', {
                                    name: record.name,
                                })}
                                onConfirm={() => onDeleteSection(record)}
                                okText={t('yes')}
                                cancelText={t('no')}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    style={{ marginLeft: 10 }}
                                >
                                    <CloseCircleOutlined />
                                </Button>
                            </Popconfirm>
                        )}
                    </>
                ),
            },
        ]

        return (
            <Table
                pagination={sectionsPagination}
                columns={columns}
                dataSource={sections}
                loading={isDataLoading}
            />
        )
    }

    return (
        <div className="page">
            <SectionModal
                onOk={onSaveSectionModalChanges}
                onCancel={() => {
                    setSectionModalVisible(false)
                    setActiveSection(null)
                }}
                section={activeSection}
                isVisible={isSectionModalVisible}
            />
            <div className="container">
                <ListViewHeader
                    buttonLabel={t('sections.add')}
                    title={t('sections.sections')}
                    onSearch={onSearch}
                    isLoading={isDataLoading}
                    defaultValue={searchQParams?.q}
                    onButtonClick={() => {
                        setSectionModalVisible(true)
                        setActiveSection({})
                    }}
                />
                <div>{renderSectionsList()}</div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(Sections)

const PROMPTS = {
    rephrase: `Objective: Create a unique rephrasing to enhance clarity, facilitate education, and strictly avoid plagiarism while preserving the original meaning.
Technical Terms: Retain essential technical terms and concepts.
Plagiarism Avoidance: Emphasize creating original content that significantly differs in wording from the original while maintaining the same context and meaning.
Tone and Style: Ensure an educational and professional style suitable for pathologists.
Length Requirement: Keep the length similar or shorter, prioritizing information and meaning retention.
Formatting Instructions: Format the rephrased text with clear, logical sections, maintaining consistency in punctuation and spacing throughout.
Exclude Meta-Comments: Avoid including any additional comments or explanations within the rephrased content.
Each bullet point should be new HTML paragraph.`,

    rephraseWithBullets: `Objective: Convert text to a unique, bulleted format to ensure clarity, educational value, and avoidance of plagiarism while preserving the original meaning.
Technical Terms: Retain essential technical terms and concepts.
Plagiarism Avoidance: Focus on altering the structure and wording to create a distinct and original version of the content.
Tone and Style: Maintain an educational and professional tone.
Exclude Meta-Comments: Remove any additional comments or explanations from the rephrased bullet points.
Format Specification: Convert the content into distinct sections or bullet points, reflecting key aspects.
Detailed Formatting Instructions:
Format subpoints with indents.
Maintain consistency in detail levels, spacing, and punctuation for readability and coherence.
Each bullet point should be new HTML paragraph`,

    mergeAndSummarize: `Objective: Merge texts and create an original, summarized version for educational purposes, without losing the essence of the original texts and ensuring plagiarism is avoided.
Technical Terms: Ensure retention of essential technical terms and concepts.
Plagiarism Avoidance: Craft a summary that is substantially different in wording and structure from the original texts while maintaining accurate and comprehensive content.
Tone and Style: Keep the summary educational and professional.
Exclude Meta-Comments: Omit any additional commentary from the summary.
Length Requirement: Ensure the summary is concise and shorter than the combined original texts.
Format Specification: Summarize the content into distinct sections or bullet points, reflecting key aspects.
Detailed Formatting Instructions:
Format subpoints with indents.
Maintain consistency in detail levels, spacing, and punctuation for readability and coherence.
Each section or bullet point should be distinctly marked and worded differently from the original content.
Each bullet point should be new HTML paragraph.`,
}

export default PROMPTS;

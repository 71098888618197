import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CardItem } from './CardItem'

import './style.scss'

type Props = {
    cardItem: CardItem
}

const NavigationCard = (props: Props) => {
    const { cardItem } = props
    const { t } = useTranslation()

    return (
        <Card className="nav-card-item">
            <Link to={cardItem.link} target={cardItem.targetBlank ? '_blank' : '_self'}>
                {cardItem.icon}
                <div className="title">{t(cardItem.titleTranslationKey)}</div>
                {/* <div className="description">{t(cardItem.descriptionTranslationKey)}</div> */}
                <div className="button">{t(cardItem.buttonTextTranslationKey)}</div>
            </Link>
        </Card>
    )
}

export default NavigationCard

import * as React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { useDisease } from 'src/context/disease';
import { Button } from 'antd';

const Confirmation = (props: any) => {
  const disease: any = useDisease();
  const diseaseForm = disease ? disease.disease : { tabs : {} };

  return (
    <div className='confirmation-step'>
      <h1 className='icon'>
        <CheckCircleOutlined />
      </h1>
      <h1 className='title'>
        {
          props.mode === 'create' ?
          <span>Disease <strong>{diseaseForm.title}</strong> saved</span>
          :
          <span>"{diseaseForm.title}" saved</span>
        }
      </h1>
      {
          // props.mode === 'create' ?
          // <p>
          //   {/* You can now submit the disease for review. */}
          //   You can make changes by editing the disease.
          // </p>
          // :
          // null
      }

      {
        diseaseForm.status !== 1 ? 
        <div style={{ marginTop: 30 }}>
          <Button
            type='primary'
            shape='round'
            size='large'
            style={{ padding: '10px 30px', height: 'auto' }}
            onClick={props.onSubmitForReview}>
            Submit Disease for Review
          </Button>
        </div>
        :
        <p>This disease is already submitted for review</p>
      }
      <div style={{ marginTop: 15 }}>
        <Button
          type='link'
          size='middle'
          style={{ fontSize: 16 }}
          onClick={props.onEdit}>
          Edit Disease
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;

import { Layout } from 'antd'
import React, { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAuth } from 'src/context/auth'
import { message } from 'antd'

import UserMenu from 'src/components/layout/header/userMenu'
import BreadCrumbs from 'src/components/layout/breadCrumbs'
// import Footer from 'src/components/layout/footer'
import { protectedRoutes } from 'src/router/routes'
import { AuthSvc } from 'src/service/auth'
import { AuthAPI } from 'src/api/auth'
import API from 'src/api/api'
import './style.scss'

const { Header, Content } = Layout

const PubAppUrl = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001' :  process.env.REACT_APP_PUBLIC_URL || 'https://app.pathriver.com';
const DomainUrl = 'pathriver.com';

const PublicAppLink = () => {
    const { t } = useTranslation()
    return (
        <span
            style={{
                marginRight: '29px',
                fontSize: '16px',
                color: '#000',
                fontWeight: '600',
                cursor: 'pointer',
            }}
            onClick={async () => {
                message.info('Opening Public App in new tab...');
                const rt = AuthSvc.getRefreshToken()
                const res = await AuthAPI.refreshToken(rt);
                const newToken = res.data;
                AuthSvc.setToken(res.data);
                API.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;

                document.cookie = `sharedData=${newToken}_prdata_${rt}; Domain=${DomainUrl}; SameSite=None; Secure`
                window.open(`${PubAppUrl}?adminLogin=y`, '_blank')
            }}
        >
            {t('publicApp')}
        </span>
    )
}

const DashboardLink = () => {
    const { t } = useTranslation()
    return <Link to={protectedRoutes.root.route}>{t('dashboard')}</Link>
}

const MyContributionLink = () => {
    const { t } = useTranslation()
    return <Link to={protectedRoutes.myContribution.route}>{t('routes.myContribution')}</Link>
}

const Links = () => {
    const auth = useAuth()
    const user = auth.user

    if (!user) {
        return null
    }

    const isAuthor = user.roleID === 30

    if (!user) {
        return null
    }

    return (
        <React.Fragment>
            <PublicAppLink />
            <DashboardLink />
            {isAuthor ? <MyContributionLink /> : null}
        </React.Fragment>
    )
}

const withPrimaryLayout = (Page: ComponentType, showBreadcrumbs: boolean = true) => {
    return () => (
        <Layout className="layout">
            <Header className="main-header">
                <div className="inner container">
                    <Link to={protectedRoutes.root.route} className="logo-container">
                        <div className="logo" />
                        <span>editor</span>
                    </Link>

                    <section>
                        <Links />
                        <UserMenu />
                    </section>
                </div>
            </Header>

            {showBreadcrumbs ? <BreadCrumbs /> : null}

            <Content className="main-content">
                <Page />
            </Content>
            {/* <Footer /> */}
        </Layout>
    )
}

export default withPrimaryLayout

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import ImageAPI from 'src/api/image';

type Props = {
  isModalOpen: boolean;
  onCloseModal: any;
  onUpload: any;
  originalFileName: string;
  imgSrc: string;
};

const CropperModal = (props: Props) => {
  const { t } = useTranslation();
  const [cropper, setCropper] = useState<any>(null);
  const [isUploading, setIsUploading] = useState(false);

  const {
    isModalOpen,
    onCloseModal,
    onUpload,
    originalFileName,
    imgSrc,
  } = props;

  useEffect(() => {
    if (!isModalOpen) {
      setCropper(null);
    }
  }, [isModalOpen]);

  const handleCrop = async () => {
    // image in dataUrl
    if (cropper) {
      cropper.getCroppedCanvas().toBlob(function (blob: any) {
        blob.lastModifiedDate = new Date();
        blob.name = originalFileName;
        setIsUploading(true);
        ImageAPI.upload(blob)
          .then((res: any) => {
            onUpload(res.data.filename);
          })
          .then(() => onCloseModal())
          .catch(() => {
            message.error(t('accountSettings.uploadError'));
          })
          .finally(() => {
            setIsUploading(false);
          });
      });
    }
  };

  const onCropperInit = (cropper: any) => {
    setCropper(cropper);
  };

  return (
    <Modal
      visible={isModalOpen}
      closable
      maskClosable={false}
      title={t('accountSettings.cropAndAdjustImage')}
      cancelText={t('close')}
      onCancel={onCloseModal}
      okText={
        !isUploading ? t('accountSettings.crop') : t('accountSettings.cropping')
      }
      onOk={handleCrop}>
      <Cropper
        src={imgSrc}
        style={{ height: '100%', width: '100%' }}
        initialAspectRatio={1}
        guides={false}
        crop={handleCrop}
        onInitialized={onCropperInit}
        zoomOnWheel={false}
        zoomable={false}
        viewMode={1}
      />
    </Modal>
  );
};

export default CropperModal;

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, message } from 'antd'

import { useAuth } from 'src/context/auth'
import LoginForm from 'src/components/auth/form'
import PublicLayout from 'src/components/layout/publicLayout'
import { LoginReq } from 'src/types/auth'
import { protectedRoutes } from 'src/router/routes'
import './style.scss'

const LoginPage = (props: any) => {
    const auth = useAuth()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (credentials: LoginReq) => {
        setLoading(true)

        try {
            await auth.login(credentials)
            setLoading(false)
            navigate(protectedRoutes.root.route)
        } catch (error) {
            setLoading(false)

            message.error('Problem logging you in, check your credentials')
        }
    }

    return (
        <PublicLayout>
            <Row>
                <Col span={24}>
                    <div className="login-logo-wrapper">
                        <div className="login-logo"></div>
                        <span>editor</span>
                    </div>
                    <LoginForm loading={loading} onSubmit={handleSubmit} />
                </Col>
            </Row>
        </PublicLayout>
    )
}
export default LoginPage

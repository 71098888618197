import * as React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import 'antd/dist/antd.css'

import Role from 'src/types/role'
import * as Pages from 'src/pages'
import { publicRoutes, protectedRoutes } from 'src/router/routes'
import { AuthenticatedRoute } from 'src/router/AuthenticatedRoute'
import { useAuth } from 'src/context/auth'
import './styles/general.scss'
import TabKeywords from './pages/settings/components/TabKeywords'
// import WizardStepsAPI from 'src/api/wizardSteps'

export const Root = () => {
    const auth = useAuth()
    const user = auth.user

    const HomePage = user && user.roleID <= 30 ? Pages.Dashboard : Pages.Home


    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Routes>
                    <Route path={publicRoutes.login.route} element={<Pages.Login />} />
                    <Route path={publicRoutes.createPassword.route} element={<Pages.CreatePassword />} />
                    <Route path={protectedRoutes.root.route} element={<AuthenticatedRoute element={<HomePage />} />} />
                    <Route
                        path={protectedRoutes.myContribution.route}
                        element={<AuthenticatedRoute element={<Pages.MyContribution />} />}
                    />
                    <Route
                        path={protectedRoutes.keywordGroups.index.route}
                        element={
                            <AuthenticatedRoute
                                roles={[Role.SuperAdmin, Role.Admin, Role.Moderator]}
                                element={<Pages.Keywords />}
                            />
                        }
                    />
                    <Route
                        path={protectedRoutes.keywordGroups.keywordGroup.route}
                        element={
                            <AuthenticatedRoute
                                roles={[Role.SuperAdmin, Role.Admin, Role.Moderator]}
                                element={<Pages.KeywordGroup />}
                            />
                        }
                    />
                    <Route
                        path={protectedRoutes.account.route}
                        element={<AuthenticatedRoute element={<Pages.Account />} />}
                    />
                    <Route
                        path={protectedRoutes.diseaseCreation.index.route}
                        element={
                            <AuthenticatedRoute
                                roles={[Role.SuperAdmin, Role.Admin, Role.Editor, Role.Author, Role.Moderator]}
                                element={<Pages.DiseaseCreation />}
                            />
                        }
                    />
                    <Route
                        path={protectedRoutes.diseaseEdit.index.route}
                        element={
                            <AuthenticatedRoute
                                roles={[Role.SuperAdmin, Role.Admin, Role.Editor, Role.Author, Role.Moderator]}
                                element={<Pages.DiseaseCreation />}
                            />
                        }
                    />
                    <Route
                        path={protectedRoutes.settings.index.route}
                        element={
                            <AuthenticatedRoute
                                roles={[Role.SuperAdmin, Role.Admin, Role.Moderator]}
                                element={<Pages.Settings />}
                            />
                        }
                    >
                        <Route path={protectedRoutes.settings.keywords.route} element={<TabKeywords />} />
                    </Route>
                    <Route
                        path={protectedRoutes.sections.index.route}
                        element={<AuthenticatedRoute element={<Pages.Sections />} />}
                    />
                    <Route
                        path={protectedRoutes.sections.chapters.route}
                        element={<AuthenticatedRoute element={<Pages.Chapters />} />}
                    />
                    <Route
                        path={protectedRoutes.users.route}
                        element={<AuthenticatedRoute element={<Pages.Users />} />}
                    />
                    <Route
                        path={protectedRoutes.adminDiseases.route}
                        element={<AuthenticatedRoute element={<Pages.AdminDiseases />} />}
                    />
                    <Route
                        path={protectedRoutes.diseases.route}
                        element={<AuthenticatedRoute element={<Pages.Diseases />} />}
                    />
                    <Route
                        path={protectedRoutes.stats.route}
                        element={<AuthenticatedRoute element={<Pages.Stats />} />}
                    />
                    <Route
                        path={protectedRoutes.supersections.route}
                        element={<AuthenticatedRoute element={<Pages.SuperSections />} />}
                    />
                    <Route path={publicRoutes.unauthorized.route} element={<Pages.Unauthorized />} />
                    <Route path='*' element={<Pages.NotFound />} />
                </Routes>
            </Router>
        </React.Suspense>
    )
}

import * as React from 'react';

const useTabs = (step: any): [any, any] => {
  const [activeTabKey, setActiveTabKey] = React.useState('0');

  return [
    activeTabKey,
    setActiveTabKey
  ];
};

export default useTabs;

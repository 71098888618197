import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Switch, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

import { Keyword } from 'src/types/keyword';

const { TextArea } = Input;

type Props = {
  onOk: any
  onCancel: any,
  isVisible: boolean,
  keyword?: Keyword | null,
  keywordGroupName: string;
}

const KeywordModal = (props: Props) => {
  const { t } = useTranslation();
  const { keyword } = props;
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: keyword?.name || '',
      description: keyword?.description || '',
      comment_enabled: keyword?.comment_enabled || false,
      ordering: keyword?.ordering || 0,
    });
  }, [form, keyword]);

  const onFormSubmit = () => {
    setIsSubmitting(true);
    form.validateFields(['name'])
      .then(() => {
        props.onOk(props.keyword?.id, form.getFieldsValue());
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsSubmitting(false)
      });
  }

  const title = props.keyword?.id ? `${props.keywordGroupName}: ${t("keywords.edit")}` : `${props.keywordGroupName}: ${t("keywords.add")}`; 

  return (
    <Modal
      visible={props.isVisible}
      title={title}
      onCancel={() => props.onCancel()}
      onOk={() => onFormSubmit()}
      okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
      okText={props.keyword?.id ? t("saveChanges") : t("add")}
      forceRender
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Form.Item
          label={t("keywords.form.name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input placeholder={t("keywords.form.name")} />
        </Form.Item>

        <Form.Item
          label={t("keywords.form.description")}
          name="description"
        >
          <TextArea rows={4} placeholder={t("keywords.form.description")} />
        </Form.Item>

        <Form.Item
          label={t("keywords.form.commentEnabled")}
          name="comment_enabled"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={t("keywords.form.order")}
          name="ordering"
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder={t("keywords.form.order")}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default KeywordModal;

import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, Tag, message } from "antd";
import { useTranslation } from "react-i18next";

import { Chapter } from "src/types";
import { getRole } from 'src/util/roles';
import { ChaptersAPI } from 'src/api';

const { TextArea } = Input;
const { Option } = Select;

type Props = {
  onOk: Function;
  onCancel: Function;
  isVisible: boolean;
  chapter?: Chapter;
  users: any;
}

const ChapterModal = (props: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chapterEditors, setChapterEditors] = useState<any>([]);
  const [form] = Form.useForm();

  const { chapter } = props;

  useEffect(() => {
    form.setFieldsValue({
      name: chapter?.name || '',
      description: chapter?.description || '',
    });
    setChapterEditors(props.chapter?.assigned_users || [])
  }, [form, chapter, props.chapter?.assigned_users]);


  const onFormSubmit = () => {
    setIsSubmitting(true);
    form.validateFields(['name'])
      .then(() => {
        props.onOk(props.chapter?.id, form.getFieldsValue(), chapterEditors);
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsSubmitting(false)
      });
  }

  const handleSelectUser = async (uID: any) => {
    if (!props.chapter?.id || !uID) {
      message.error(t('chapters.errorAssigning'))
      return
    }

    const user = props.users.find((u: any) => u.id === uID)
    if (!user) {
      message.error(t('chapters.errorAssigning'))
      return
    }

    const ce = chapterEditors
    if (ce.find((ce : any) => ce.id === user.id)) return

    ce.push(user)
    setChapterEditors(JSON.parse(JSON.stringify(ce)))

    const assignResult = await ChaptersAPI.assign(props.chapter.id, user.id);
    if (!assignResult || assignResult.status !== 200) {
      message.error(t('chapters.errorAssigning'));
      return;
    }
    message.success(t('chapters.assigned'));
  }

  const handleRemoveUser = async (user: any) => {
    if (!props.chapter?.id || !user.id) {
      message.error(t('chapters.errorUnAssigning'));
      return;
    }

    const ce = chapterEditors.filter((u: any) => u.id !== user.id)
    setChapterEditors(ce)

    const unassignResult = await ChaptersAPI.unassign(props.chapter.id, user.id);
    if (!unassignResult || unassignResult.status !== 200) {
      message.error(t('chapters.errorUnAssigning'));
    }
    message.success(t('chapters.unassigned'));
  }


  return (
    <Modal
      maskClosable={false}
      visible={props.isVisible}
      title={props.chapter?.id ? t("chapters.edit_chapter") : t("chapters.create_chapter")}
      onCancel={() => props.onCancel()}
      onOk={() => onFormSubmit()}
      okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
      okText={props.chapter?.id ? t("saveChanges") : t("create")}
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input placeholder={t("name")} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onFormSubmit();
            }
          }} />
        </Form.Item>
        <Form.Item
          label={t("description")}
          name="description"
        >
          <TextArea rows={4} placeholder={t("description")} />
        </Form.Item>
        <Form.Item
          label="Editors"
          name="editor"
        >
          <Select
            placeholder="Select users to assign as editors"
            showSearch
            allowClear
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) !==
              -1
            }
            onSelect={(u: any) => handleSelectUser(u)}>
            {props.users.map(
              (u: any, k: number) => (
                <Option key={u.id} value={u.id}>
                  {`${u.first_name} ${u.last_name} | ${u.email} | ${getRole(u.role)}`}
                </Option>
              )
            )}
          </Select>
          {
            chapterEditors?.length ? chapterEditors.map((u: any, k: number) => (
              <Tag key={u.id} closable onClose={() => handleRemoveUser(u)} style={{marginTop : 5}}>
                {`${u.first_name} ${u.last_name} | ${u.email} | ${getRole(u.role)}`}
              </Tag>
            )) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChapterModal;

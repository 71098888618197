import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, Dropdown, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons';

import { protectedRoutes, publicRoutes } from 'src/router/routes'
import { useAuth } from 'src/context/auth'
import './style.scss'

const MainHeaderMenu = (): JSX.Element => {
    const auth = useAuth()
    const user = auth.user
    const navigate = useNavigate()
    const { t } = useTranslation()

    const onLogout = () => {
        auth.logout()
        // remove the cookie
        navigate(publicRoutes.login.route)
    }

    const renderMenu = () => (
        <Menu>
            <Menu.Item>
                <Link to={protectedRoutes.account.route}>
                    {t('menu.accountSettings')}
                </Link>
            </Menu.Item>
            <Menu.Item>
                <div onClick={onLogout}>{t('menu.signOut')}</div>
            </Menu.Item>
        </Menu>
    )

    const renderAvatar = (user: any) => {
        if (!user || !user.avatar) {
            return <Avatar size={42} className="user-avatar" icon={<UserOutlined />} />
        }

        return <Avatar size={42} className="user-avatar" src={user.avatar} />
    }

    return (
        <Dropdown overlay={renderMenu()} placement="bottomLeft" arrow>
            {renderAvatar(user)}
        </Dropdown>
    )
}

export default MainHeaderMenu

import React from 'react'
import { Input, Row, Col, PageHeader, Button, Popover, Typography, Spin, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import './style.scss'
import { EditOutlined } from '@ant-design/icons'

const { Search } = Input

export type EditableTitle = {
    isTitleEditable: boolean
    newTitleEditVisible: boolean
    setNewTitleVisible: any
    newTitleValue: string
    setNewTitleValue: any
    onTitleClick: any
    onSaveNewTitle: any
}

type Props = {
    title: string
    isLoading: boolean

    defaultValue?: string
    editableTitle?: EditableTitle
    hideSearch?: boolean
    onSearch?: any    
    hideButton?: boolean
    buttonLabel?: string
    onButtonClick?: any    
}

const ListViewHeader = (props: Props) => {
    const { onSearch, onButtonClick, isLoading, buttonLabel, title, defaultValue, editableTitle } = props

    const { t } = useTranslation()

    const renderUpdateTitleForm = () => {
        if (editableTitle) {
            const { newTitleValue, setNewTitleValue, onTitleClick, onSaveNewTitle } = editableTitle
            return (
                <>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Input
                                size="large"
                                placeholder={t('keywords.form.name')}
                                value={newTitleValue}
                                onChange={(e) => setNewTitleValue(e.target.value)}
                            />
                        </Col>
                        <Col xs={24}>
                            <Row justify="space-between">
                                <Button type="primary" danger shape="round" size="large" onClick={onTitleClick}>
                                    {t('cancel')}
                                </Button>

                                <Button type="primary" shape="round" size="large" onClick={onSaveNewTitle}>
                                    {t('save')}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </>
            )
        }
    }

    return (
        <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: 20, position: 'relative' }}>
            {isLoading && (
                <Space className="container-loader-wrapper" size="large">
                    <Spin size="large" />
                </Space>
            )}
            <Col span={6}>
                {editableTitle ? (
                    <Popover
                        content={renderUpdateTitleForm()}
                        trigger="click"
                        placement="bottomLeft"
                        visible={editableTitle.newTitleEditVisible}
                        onVisibleChange={() => editableTitle.setNewTitleVisible(!editableTitle.newTitleEditVisible)}
                    >
                        <div
                            className="list-view-header__editable-title"
                            onClick={() => editableTitle.setNewTitleVisible(!editableTitle.newTitleEditVisible)}
                        >
                            <Typography.Title level={3}>
                                {title}
                                <EditOutlined />
                            </Typography.Title>
                        </div>
                    </Popover>
                ) : (
                    <PageHeader title={title} />
                )}
            </Col>
            <Col span={12}>
                {!props.hideSearch && (
                    <Search
                        className="header-search-bar"
                        placeholder={t('searchListPlaceholder')}
                        allowClear
                        enterButton
                        size="large"
                        defaultValue={defaultValue}
                        onSearch={onSearch}
                        loading={isLoading}
                    />
                )}
            </Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!props.hideButton && (
                    <Button type="primary" size="large" className="list-view-header-btn" onClick={onButtonClick}>
                        {buttonLabel}
                    </Button>
                )}
            </Col>
        </Row>
    )
}

export default ListViewHeader

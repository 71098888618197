import { CardItemsGenerator } from './CardItemsGenerator';
import { CardsNavigationCategory } from './constants';

export class CardsNavigation {
  categories: CardsNavigationCategory[]

  constructor(roleID: number) {
    this.categories = [CardsNavigationCategory.DISEASES]

    if (roleID < 10) {
      this.categories.push(CardsNavigationCategory.ADMIN)
    }
  }

  build() {    
    return this.categories.map(category => ({
      category,
      cardItems: new CardItemsGenerator(category).generate()
    }))
  }
}

import API from "./api";

const baseURL = "/tabs";

const WizardTabs = {
  list: (): any => {
    return API.get(baseURL);
  },

  setKeywordGroups: (tabID: number, kgIDs: number[]): any => {
    return API.put(baseURL + `/${tabID}/keyword_groups`, {
        keyword_group_ids: kgIDs
    });
  },

//   detachKeywordGroup: (stepID: number, kgID: number): any => {
//     return API.post(baseURL + `/${stepID}/attach/${kgID}`);
//   },
};

export default WizardTabs;

import API from "./api";
import { Keyword } from 'src/types';

const baseURL = "/keywords";

const Keywords = {
  list: (page?: number, searchQuery?: string): any => {
    let qp = page ? `?page=${page}` : '';
    if (searchQuery) {
      if (qp) {
        qp += '&';
      } else {
        qp += '?';
      }
      qp += `q=${searchQuery}`;
    }
    return API.get(`${baseURL}${qp}`);
  },

  findById(id: number) {
    return API.get(`${baseURL}/${id}`);
  },

  save(keyword: Keyword) {
    if (keyword.id) {
      return API.put(`${baseURL}/${keyword.id}`, { ...keyword });
    } else {
      return API.post(`${baseURL}`, { ...keyword });
    }
  },

  delete(keywordId: number) {
    return API.delete(`${baseURL}/${keywordId}`)
  }
};

export default Keywords;

import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'

import { AppProviders } from 'src/context/providers'
import { Root } from 'src/root'
import './i18n'
import './index.less'

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracesSampleRate: 1.0,
    })
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true })

ReactDOM.render(
    <AppProviders>
        <Root />
    </AppProviders>,
    document.getElementById('root')
)

export const getDiseaseStatus = (status: number, t: any) => {
    switch (status) {
        case 0:
            return t("diseases.status.draft");
        case 1:
            return t("diseases.status.submitted");
        case 2:
            return t("diseases.status.changesRequested");
        case 3:
            return t("diseases.status.approved");
        case 4:
            return t("diseases.status.published");
        default:
            return t("diseases.status.draft");
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Table,
  Popconfirm,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'

import ListViewHeader from 'src/components/common/list-view/header';
import withPrimaryLayout from "src/components/layout/primaryLayout";
import { SuperSection } from "src/types";
import { SectionsAPI, SuperSectionsAPI } from 'src/api';
import SuperSectionModal from "./modal/supersection"

const SuperSections = () => {
  const { t } = useTranslation();
  const { sectionId }: any = useParams();
  const fetchSuperSectionsErrorMsg = t('supersections.fetchError');

  const [isSuperSectionsLoading, setSuperSectionsLoading] = useState<boolean>(false);
  const [supersections, setSuperSections]: [Array<SuperSection>, any] = useState([]);
  const [activeSuperSection, setActiveSuperSection]: [SuperSection, any] = useState({} as SuperSection);
  const [isSuperSectionModalVisible, setSuperSectionModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSuperSections, setFilteredSuperSections] = useState<any>([]);
  const [sections, setSections] = useState<any>([])

  const getSuperSections = useCallback(
    async () => {
      setSuperSectionsLoading(true)

      try {
        const result = await SuperSectionsAPI.list()
        if (!result || result.status !== 200) {
          throw new Error('Bad response');
        }

        if (result?.data) {
          const data = result.data.map((item: SuperSection) => {
            return { key: item.id, ...item };
          });
          setSuperSections(data);
          setFilteredSuperSections(data);
        }
      } catch (error) {
        console.error(error);
        message.error(fetchSuperSectionsErrorMsg);
      } finally {
        setSuperSectionsLoading(false)
      }
    }, [fetchSuperSectionsErrorMsg]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredSuperSections(
        supersections.filter((supersection: any) => supersection.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
      );
    } else {
      setFilteredSuperSections(supersections);
    }
  }, [searchQuery, supersections]);

  const getSections = useCallback(
    async () => {
      try {
        const result = await SectionsAPI.list()
        if (!result || result.status !== 200) {
          throw new Error('Bad response');
        }

        if (result?.data) {
          setSections(result?.data)
        }
      } catch (error) {
        console.error(error);
        message.error(t('errors.apiCommunication', { api: 'users' }));
      }
    }, [t]);

  const onSaveSuperSectionModalChanges = async (id: number, formValues: any, sections: any) => {
    try {
      const supersection = { id, supersection_id: Number(sectionId), ...formValues, section_ids: sections } as SuperSection;
      const result = await SuperSectionsAPI.save(supersection);
      if (!result || result.status !== 200) {
        throw new Error('Bad response');
      }

      setActiveSuperSection(null);
      setSuperSectionModalVisible(false);
      message.success(id ? t('supersections.updated', { name: supersection.name }) : t('supersections.added', { name: supersection.name }));

      getSuperSections()

    } catch (error) {
      console.error(error)
      message.error(t('supersections.errorCreating'));
    }
  }

  const onDeleteSuperSection = async (supersection: SuperSection) => {
    try {
      const result = await SuperSectionsAPI.delete(supersection.id)
      if (result?.status === 200) {
        message.success(t('supersections.supersectionDeleted', { name: supersection.name }));
        await getSuperSections();
      }
    } catch (error) {
      console.error(error);
      message.error(t('supersections.errorDeleting'));
    }
  }

  useEffect(() => {
    getSuperSections();
    getSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSuperSections, getSections]);

  const renderSuperSectionsList = () => {
    const columns = [
      {
        title: t('supersections.table.columns.name'),
        dataIndex: 'name',
        key: 'name',
        width: 800,
        render: (name: string) => name,
      },
      {
        title: t('supersections.table.columns.sections'),
        dataIndex: 'sections',
        key: 'sections',
        ellipsis: {
          showTitle: false,
        },
        render: (sections: []) => (
          <div>
            { sections ? sections.length : 0 }
          </div>
        ),
      },
      {
        title: 'Edit/Delete',
        key: 'action',
        align: 'right' as const,
        render: (value: any, record: any) => (
          <>
            <Button
              type='primary'
              shape='round'
              size='small'
              onClick={() => {
                setActiveSuperSection(record)
                setSuperSectionModalVisible(true)
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              placement='topLeft'
              title={t('supersections.deletePopup', { name: record.name })}
              onConfirm={() => onDeleteSuperSection(record)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                danger
                type='primary'
                shape='round'
                size='small'
                style={{ marginLeft: 10 }}
              >
                <CloseCircleOutlined />
              </Button>
            </Popconfirm>
          </>
        )
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={filteredSuperSections}
        loading={isSuperSectionsLoading}
        pagination={false}
      />
    );
  };

  const onSearch = (searchQuery: string) => setSearchQuery(searchQuery);

  return (
    <div className="page">
      <SuperSectionModal
        isVisible={isSuperSectionModalVisible}
        onCancel={() => {
          setSuperSectionModalVisible(false);
          setActiveSuperSection({});
        }}
        supersection={activeSuperSection}
        sections={sections}
        onOk={onSaveSuperSectionModalChanges}
      />
      <div className="container m-t-11">
        <ListViewHeader
          title={t('routes.supersections.index')}
          buttonLabel={t('supersections.add')}
          isLoading={isSuperSectionsLoading}
          onButtonClick={() => setSuperSectionModalVisible(true)}
          onSearch={onSearch}
        />
        <div className='page-content m-t-8'>
          {renderSuperSectionsList()}
        </div>
      </div>
    </div>
  );
};

export default withPrimaryLayout(SuperSections);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import { Section } from 'src/types/section';

const { Option } = Select;

// NOTE:
// Scrolling via the scrollbar produces an error.
// Somehow the Select component from antd enters into an infinite loop when scrolled via scroll bar.
// To prevent errors prop `virtual` is set to false.
const ChapterSelector = (props: any) => {
  const { t } = useTranslation();
  const { chapters, selected, onSelect, disabled } = props;

  try {
    return (
      <Select
        disabled={disabled}
        placeholder={t('chapters.select')}
        value={selected}
        onSelect={onSelect}
        showSearch
        virtual={false}
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {chapters.map((s: Section, k: number) => (
          <Option key={k} value={s.id}>
            {s.name}
          </Option>
        ))}
      </Select>
    );
  } catch (error) {
    return null;
  }
};

export default ChapterSelector;

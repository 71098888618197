enum Role {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  User = 'USER',
  Moderator = 'MODERATOR',
  Author = 'AUTHOR'
}

export default Role;

import { AxiosResponse } from 'axios'
import { ApiResponse, Disease, SearchParams, SearchParamsAll } from 'src/types'
import API from './api'

const baseURL = '/diseases'

const Diseases = {
    list: (searchParams: SearchParamsAll): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        const params: any = {
            page: searchParams.page,
            q: searchParams.q,
            sort_section: searchParams.sort_section,
            sort_chapter: searchParams.sort_chapter,
            section_id: searchParams.section_id,
            chapter_id: searchParams.chapter_id,
        };

        Object.keys(params).forEach(key => (!params[key] || params[key] === undefined) && delete params[key]);

        let qp = '';
        Object.keys(params).forEach(key => {
            if (qp) {
                qp += '&';
            } else {
                qp += '?';
            }
            qp += `${key}=${params[key]}`;
        });

        return API.get(`${baseURL}${qp}`)
    },

    listShort: (searchParams: SearchParams): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        return API.get(`${baseURL}/list_short?all=true&q=${searchParams.q}&page=${searchParams.page}`)
    },

    listMyDraft: (searchParams: SearchParams): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        return API.get(`${baseURL}/my/draft?q=${searchParams.q}&page=${searchParams.page}`)
    },

    listMyReview: (searchParams: SearchParams): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        return API.get(`${baseURL}/my/review?q=${searchParams.q}&page=${searchParams.page}`)
    },
    
    listMyPublished: (searchParams: SearchParams): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        return API.get(`${baseURL}/my/published?q=${searchParams.q}&page=${searchParams.page}`)
    },    

    listSubmitted: (): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        return API.get(`${baseURL}/submitted`)
    },

    search: (searchParams: SearchParamsAll): Promise<AxiosResponse<ApiResponse<Disease[]>>> => {
        const params: any = {
            q: searchParams.q,
            page: searchParams.page,
            section_id: searchParams.section_id,
            chapter_id: searchParams.chapter_id,
        }

        Object.keys(params).forEach(key => (!params[key] || params[key] === undefined) && delete params[key])

        let qp = ''
        Object.keys(params).forEach(key => {
            if (qp) {
                qp += '&'
            } else {
                qp += '?'
            }
            qp += `${key}=${params[key]}`
        })

        return API.get(`${baseURL}/search${qp}`)
    },

    get: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.get(`${baseURL}/${id}`)
    },

    duplicate: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.post(`${baseURL}/${id}/duplicate`)
    },

    editPublished: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/edit`, {})
    },

    // TODO: replace any with disease type
    create: (disease: any): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.post(baseURL, disease)
    },

    update: (disease: any, id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}`, disease)
    },

    delete: (id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.delete(`${baseURL}/${id}`)
    },

    submitForReview: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.post(`${baseURL}/${id}/submit`)
    },

    approve: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/approve`)
    },

    publish: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/approve`)
    },    

    decline: (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/decline`)
    },

    assign: (id: number, user_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/assign-user`, {
            user_id,
        })
    },

    unassign: (id: number, user_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/unassign-user`, {
            user_id,
        })
    },

    orderImages: (id: number, tabId: any, imageIds: any): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${id}/reorder-images`, {
            image_ids: imageIds,
            tab_id: tabId,
        })
    }
}

export default Diseases

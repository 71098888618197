import axios from 'axios'

import { AuthAPI } from 'src/api/auth'
import { AuthSvc } from 'src/service/auth'

const API = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // Maybe we should add here response transformer to handle this issue??
    /* const d = res.data;
      (
        See:
        src/context/auth.tsx:20;
        src/components/common/modals/cropper-modal/index.tsx:43
        src/pages/account/index.tsx:101
      )
    */
    headers: {
        'Content-Type': 'application/json',
    },
})
// API.defaults.headers.common['Content-Type'] = 'application/json';
API.defaults.headers.common['Authorization'] = `Bearer ${AuthSvc.getToken()}`

API.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url.includes('refresh-token')) {
      AuthSvc.removeToken()
      AuthSvc.removeUser()
      AuthSvc.removeRefreshToken()
      alert('Refresh token expired. Please log in again.')
      window.location.replace('/login')
    }

    if (window.location.pathname !== '/login' && window.location.pathname !== '/activate' &&  error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = AuthSvc.getRefreshToken()
      
      try {
        const res = await AuthAPI.refreshToken(refreshToken);
        AuthSvc.setToken(res.data);
        API.defaults.headers.common['Authorization'] = 'Bearer ' + res.data;
        originalRequest.headers['Authorization'] = 'Bearer ' + res.data;
        return API(originalRequest);
      } catch (error) {
        AuthSvc.removeToken()
        AuthSvc.removeUser()
        AuthSvc.removeRefreshToken()
        window.location.replace('/login')
      }
    }
    return Promise.reject(error);
  });

export default API

import React, { useState, useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { KeywordGroup } from "src/types/keywordGroup";

const { TextArea } = Input;

type Props = {
  onOk: any
  onCancel: any,
  isVisible: boolean,
  keywordGroup?: KeywordGroup,
}

const KeywordGroupModal = (props: Props) => {
  const { t } = useTranslation();
  const { keywordGroup } = props;
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: keywordGroup?.name || '',
      description: keywordGroup?.description || '',
    });
  }, [form, keywordGroup]);

  const onFormSubmit = () => {
    setIsSubmitting(true);
    form.validateFields(['name'])
      .then(() => {
        props.onOk(props.keywordGroup?.id, form.getFieldsValue());
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsSubmitting(false)
      });
  }

  return (
    <Modal
      visible={props.isVisible}
      title={props.keywordGroup?.id ? t("save") : t("add")}
      onCancel={() => props.onCancel()}
      onOk={() => onFormSubmit()}
      okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
      okText={props.keywordGroup?.id ? t("saveChanges") : t("add")}
      forceRender
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Form.Item
          label={t("keywords.form.name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input placeholder={t("keywords.form.name")} />
        </Form.Item>
        <Form.Item
          label={t("keywords.form.description")}
          name="description"
        >
          <TextArea rows={4} placeholder={t("keywords.form.description")} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default KeywordGroupModal;

import * as React from 'react';
import 'react-quill/dist/quill.snow.css';

import * as FormSteps from './steps';
import { Section } from 'src/types/section';
import './style.scss';

type Props = {
  mode: string;
  step: any;
  sections: Array<Section>;
  diseases: Array<{}>;
  tabs: {};
  onEditFromConfirmation: any;
  onSubmitForReviewFromConfirmation: any;
  onGotChapters?: any;
};

const Form = (props: Props) => {
  const ID: number = props.step.id;
  // console.log('Form props : ', props);

  const steps: any = {
    // TODO: handle edit mode where these nulls are real values
    // Keys are Step IDs
    1: <FormSteps.General tabs={props.tabs} sections={props.sections} diseases={props.diseases} onGotChapters={props.onGotChapters} />,
    2: <FormSteps.ClinicalFeatures step={props.step} tabs={props.tabs} />,
    3: <FormSteps.Gross step={props.step} tabs={props.tabs} />,
    4: <FormSteps.Microscopic step={props.step} tabs={props.tabs} />,
    5: <FormSteps.Immunohistochemistry step={props.step} tabs={props.tabs} />,
    // 6: <FormSteps.PathologicalFeatures step={props.step} tabs={props.tabs} />,
    // 7: <FormSteps.PathologicalFeaturesMicroscopic step={props.step} tabs={props.tabs} />,
    // 8: <FormSteps.Immunophenotype step={props.step} tabs={props.tabs} />,
    // 9: <FormSteps.Molecular step={props.step} tabs={props.tabs} />,
    6: <FormSteps.DiffDx step={props.step} tabs={props.tabs} diseases={props.diseases} sections={props.sections} />,
    7: <FormSteps.Treatment step={props.step} tabs={props.tabs} />,
    // 12: <FormSteps.Reporting step={props.step} tabs={props.tabs} />,
    8: <FormSteps.Resources step={props.step} tabs={props.tabs} />,
    100: <FormSteps.Confirmation mode={props.mode} onEdit={props.onEditFromConfirmation} onSubmitForReview={props.onSubmitForReviewFromConfirmation} />,
    // TODO: fali ancillary tests step sa backenda
  };

  return <div className='disease-creation-form-area'>{steps[ID]}</div>;
};

export default Form;

import React from 'react'
import ReactQuill from 'react-quill'
import { Button, Drawer, message } from 'antd'
import { SyncOutlined, CheckOutlined } from '@ant-design/icons'
// @ts-ignore
import OpenAI from '@tectalic/openai'

import PROMPTS from 'src/util/propmts'

type Props = {
    readOnly?: boolean
    theme?: string
    editorConfig: any
    value?: string
    placeholder?: string
    enableRephrase?: boolean
    onChange: (text: string, delta?: any, source?: string, editor?: any) => void
    onUseRephrased?: (text: string) => void
}

const RichText = (props: Props) => {
    const {
        value,
        readOnly,
        onChange,
        onUseRephrased,
        placeholder,
        editorConfig,
        theme = 'snow',
        enableRephrase = false,
    } = props

    const [isDrawerVisible, setIsDrawerVisible] = React.useState(false)
    const [isRephrasing, setIsRephrasing] = React.useState(false)
    const [isRephrasingBullets, setIsRephrasingBullets] = React.useState(false)
    const [isMergingAndSummarizing, setIsMergingAndSummarizing] = React.useState(false)
    const [rephrasePreview, setRephrasePreview] = React.useState('')

    const handleShowDrawer = () => {
        setIsDrawerVisible(true)
    }

    const handleHideDrawer = () => {
        setIsDrawerVisible(false)
    }

    const handleRephrase = (value: string | undefined, opts?: any) => {
        if (opts?.useBullets) {
            setIsRephrasingBullets(true)
        } else if (opts?.merge) {
            setIsMergingAndSummarizing(true)
        } else {
            setIsRephrasing(true)
        }

        let command = ''

        if (opts?.useBullets) {
            command = PROMPTS.rephraseWithBullets
        } else if (opts?.merge) {
            command = PROMPTS.mergeAndSummarize
        } else {
            command = PROMPTS.rephrase
        }

        OpenAI(process.env.REACT_APP_GPT_TOKEN)
            .chatCompletions.create(
                {
                    model: 'gpt-4',
                    messages: [
                        {
                            role: 'user',
                            content: `${command} ` + value,
                        },
                    ],
                },
                { timeout: 100000 }
            )
            .then((response: { data: { choices: { message: { content: string } }[] } }) => {
                setIsRephrasing(false)
                setIsRephrasingBullets(false)
                setIsMergingAndSummarizing(false)
                setRephrasePreview(response.data.choices[0].message.content.trim())
            })
            .catch((error: any) => {
                setIsRephrasing(false)
                setIsRephrasingBullets(false)
                setIsMergingAndSummarizing(false)
                message.error('Error rephrasing text')
                console.log('Error rephrasing text:', error)
            })
    }

    const handleSave = () => {
        onUseRephrased && onUseRephrased(rephrasePreview)
        handleHideDrawer()
    }

    return (
        <>
            <ReactQuill
                readOnly={readOnly}
                theme={theme}
                formats={editorConfig.formats}
                modules={editorConfig.modules}
                value={value || ''}
                placeholder={placeholder || ''}
                onChange={onChange}
            />
            {process.env.REACT_APP_GPT_TOKEN && enableRephrase && (
                <>
                    <Button disabled={!value} onClick={handleShowDrawer} style={{ marginTop: 5, borderRadius: 2 }}>
                        Rephrase
                    </Button>
                    <Drawer
                        title="Rephrase"
                        placement="right"
                        visible={isDrawerVisible}
                        onClose={handleHideDrawer}
                        width={640}
                    >
                        <h4>Original</h4>
                        <ReactQuill
                            readOnly={true}
                            theme={theme}
                            value={value || ''}
                            modules={{
                                toolbar: false,
                            }}
                        />
                        <br />
                        <Button type="primary" onClick={() => handleRephrase(value)} style={{ borderRadius: 3 }}>
                            <SyncOutlined spin={isRephrasing} />
                            Rephrase
                        </Button>

                        <Button
                            onClick={() =>
                                handleRephrase(value, {
                                    useBullets: true,
                                })
                            }
                            style={{ borderRadius: 3, marginLeft: 12 }}
                        >
                            <SyncOutlined spin={isRephrasingBullets} />
                            Rephrase with Bullets
                        </Button>

                        <Button
                            onClick={() =>
                                handleRephrase(value, {
                                    merge: true,
                                })
                            }
                            style={{ borderRadius: 3, marginLeft: 12 }}
                        >
                            <SyncOutlined spin={isMergingAndSummarizing} />
                            Merge and Summarize
                        </Button>

                        <br />
                        <br />
                        <hr />
                        <br />
                        <h4>Rephrased</h4>
                        <ReactQuill
                            theme={theme}
                            formats={editorConfig.formats}
                            modules={editorConfig.modules}
                            value={rephrasePreview || ''}
                        />
                        <br />
                        <Button type="primary" onClick={handleSave} style={{ borderRadius: 3 }}>
                            <CheckOutlined />
                            Use Rephrased Text
                        </Button>
                    </Drawer>
                </>
            )}
        </>
    )
}

export default RichText

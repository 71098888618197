import React from 'react';
import { useTranslation } from 'react-i18next';

import withPrimaryLayout from 'src/components/layout/primaryLayout';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('homePage')}</h1>
    </div>
  );
};

export default withPrimaryLayout(HomePage);

import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Card, Table, Menu } from 'antd'

import DiseaseAPI from 'src/api/diseases'
import { protectedRoutes } from 'src/router/routes'
import withPrimaryLayout from 'src/components/layout/primaryLayout'
import LoadingPlaceholder from 'src/components/common/loadingPlaceholder'
import ListViewHeader from 'src/components/common/list-view/header'
import { SearchParams } from 'src/types'
import { qp } from 'src/util/url'
import './style.scss'

const MyContributionPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(true)
    const [current, setCurrent] = useState('draft')
    const [items, setItems]: [any, any] = useState({})
    const [totalItems, setTotalItems] = useState(0)
    const [errorMsg, setErrorMsg]: [string | null, any] = useState(null)
    const [searchQParams, setSearchParams] = useState<SearchParams>({
        q: searchParams.get('q') || '',
        page: Number(searchParams.get('page') || 1),
    })

    const columns = [
        {
            title: t('keywords.table.columns.name'),
            dataIndex: 'title',
            key: `title`,
            width: 200,
            render: (val: any, record: any) => (
                <Link
                    to={protectedRoutes.diseaseEdit.index.route.replace(':id', record.id)}
                    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                >
                    {val}
                </Link>
            ),
        },
        {
            title: t('keywords.table.columns.section'),
            dataIndex: 'section',
            key: 'section',
            width: 50,
            align: 'left' as const,
            render: (val: any) => <span>{`${val?.name}`}</span>,
        },
        {
            title: t('keywords.table.columns.chapter'),
            dataIndex: 'chapter',
            key: 'chapter',
            width: 50,
            render: (val: any) => <span>{`${val?.name}`}</span>,
        },
    ]

    const menuItems: any = [
        {
            key: 'draft',
            label: 'Draft',
        },
        {
            key: 'review',
            label: 'Review',
        },
        {
            key: 'published',
            label: 'Published',
        },
    ]

    const getItems = useCallback(async () => {
        setErrorMsg(null)

        let ReqAPI = null
        if (current === 'draft') {
            ReqAPI = DiseaseAPI.listMyDraft(searchQParams)
        } else if (current === 'review') {
            ReqAPI = DiseaseAPI.listMyReview(searchQParams)
        } else if (current === 'published') {
            ReqAPI = DiseaseAPI.listMyPublished(searchQParams)
        }

        try {
            const res = (await ReqAPI) as any
            setItems(res?.data)
            setTotalItems(parseInt(res.headers['total-items']))
        } catch (error) {
            console.log('Error getting my diseases: ', error)
            setItems([])
            setErrorMsg(t('contribution.errorLoading'))
        } finally {
            setIsLoading(false)
        }
    }, [current, searchQParams, t])

    useEffect(() => {
        getItems()
    }, [t, searchQParams, getItems])

    const renderEmptyState = () => {
        return !isLoading && (!items || items.length === 0) ? (
            <Card className="empty-state">
                <h1>{t(`contribution.noneYetTitle_${current}`)}</h1>
                <p>{t(`contribution.noneYetBody_${current}`)}</p>
                <Link to={protectedRoutes.diseaseCreation.index.route}>
                    <Button type="primary" shape="round" size="large">
                        {t('contribution.add')}
                    </Button>
                </Link>
            </Card>
        ) : null
    }

    const onPageChange = (page: number) => {
        const q = searchParams.get('q') || ''
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: q, page }),
        })

        setSearchParams({
            page,
            q,
        })
    }

    const onSearch = (searchQuery: string) => {
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery }),
        })
        setSearchParams({
            page: 1,
            q: searchQuery.trim(),
        })
    }

    const handleMenuClick = (e: any) => {
        setCurrent(e.key)
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: '', page: 1 }),
        })
        setSearchParams({
            page: 1,
            q: '',
        })
    }

    const renderMenu = () => {
        return (
            <Menu onClick={handleMenuClick} selectedKeys={[current]} mode="horizontal">
                {menuItems.map((item: any) => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
            </Menu>
        )
    }

    const renderContent = (diseases = []) => {
        return (
            <Table
                pagination={{
                    total: totalItems,
                    current: Number(searchQParams.page),
                    pageSize: 30,
                    onChange: onPageChange,
                    hideOnSinglePage: false,
                    pageSizeOptions: [],
                }}
                loading={isLoading}
                dataSource={diseases}
                columns={columns}
                rowKey="id"
            />
        )
    }

    const renderTable = () => {
        return (
            <div>
                {isLoading ? (
                    <LoadingPlaceholder>{t('contribution.loadingMy')}</LoadingPlaceholder>
                ) : items && items.length > 0 ? (
                    renderContent(items)
                ) : (
                    renderEmptyState()
                )}
            </div>
        )
    }

    return (
        <div className="page my-contribution">
            <div className="container">
                <ListViewHeader
                    buttonLabel={t('contribution.add')}
                    title={t('contribution.myTitle')}
                    onSearch={onSearch}
                    isLoading={isLoading}
                    onButtonClick={() => {
                        navigate('/create-disease')
                    }}
                />

                <div className="page-content">
                    {renderMenu()}
                    {!isLoading && errorMsg ? (
                        <div
                            style={{
                                background: '#fff',
                                padding: '50px 0',
                                fontSize: '16px',
                                color: '#585858',
                                textAlign: 'center',
                            }}
                        >
                            <p>{errorMsg}</p>
                        </div>
                    ) : (
                        renderTable()
                    )}
                </div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(MyContributionPage)

import { useEffect, useState, useCallback } from 'react'
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import { Button, Table, Popconfirm, message, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'

import ListViewHeader from 'src/components/common/list-view/header'
import KeywordGroupsAPI from 'src/api/keyword-groups'
import withPrimaryLayout from 'src/components/layout/primaryLayout'
import { KeywordGroup, SearchParams } from 'src/types'
import KeywordGroupModal from './modal/keywordGroup'
import { protectedRoutes } from 'src/router/routes'
import { qp } from 'src/util/url'

const KeywordsPage = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const fetchKeywordgroupsErrorMsg = t('keywordGroups.fetchError')

    const [keywordGroups, setKeywordGroups] = useState([])
    const [totalitems, setTotalItems] = useState(0)
    const [isModalVisible, setModalVisible] = useState(false)
    const [modalKeywordGroup, setModalKeywordGroup] = useState<any>()
    const [isDataLoading, setDataLoading] = useState<boolean>(false)
    const [searchQParams, setSearchParams] = useState<SearchParams>({
        q: searchParams.get('q') || '',
        page: Number(searchParams.get('page') || 1),
    })
    const [totalPages, setTotalPages] = useState(1)

    const memoizedGetKeywordGroups = useCallback(
        async (searchQParams: SearchParams) => {
            setDataLoading(true)
            try {
                const res = await KeywordGroupsAPI.list(
                    searchQParams.page,
                    searchQParams.q
                )

                if (
                    !res ||
                    res.status !== 200
                ) {
                    throw new Error('Bad response')
                }

                if (!res.data) {
                    setKeywordGroups([])
                    message.error('No results');
                }

                setKeywordGroups(res.data)
                setTotalItems(parseInt(res.headers['total-items']))
                setTotalPages(parseInt(res.headers['total-pages']))
            } catch (error) {
                message.error(fetchKeywordgroupsErrorMsg)
            } finally {
                setDataLoading(false)
            }
        },
        [fetchKeywordgroupsErrorMsg]
    )

    useEffect(() => {
        memoizedGetKeywordGroups(searchQParams)
    }, [memoizedGetKeywordGroups, searchQParams])

    const renderKeywordGroupList = () => {
        if (!keywordGroups) return null;

        const dataSource = keywordGroups.map((keyword: any) => ({
            id: keyword.id,
            key: keyword.id,
            name: keyword.name,
            description: keyword.description,
            keyword_count: keyword.keyword_count,
        }))
        const columns = [
            {
                title: t('keywords.table.columns.name'),
                dataIndex: 'name',
                key: `name`,
                width: '30%',
                align: 'left' as const,
                render: (val: any, record: any) => (
                    <Link
                        to={protectedRoutes.keywordGroups.keywordGroup.route.replace(
                            ':keywordGroupId',
                            record.id
                        )}
                    >
                        {val}
                    </Link>
                ),
            },
            {
                title: t('keywords.table.columns.description'),
                dataIndex: 'description',
                key: `description`,
                ellipsis: {
                    showTitle: false,
                },
                render: (description: string) => (
                    <Tooltip placement="topLeft" title={description}>
                        {description}
                    </Tooltip>
                ),
            },
            {
                title: t('keywords.keyword'),
                dataIndex: 'keyword_count',
                key: `keyword_count`,
                width: '10%',
                render: (val: any) => (
                    <span
                        style={{
                            color: '#6213ea',
                            fontSize: '18px',
                            fontWeight: 600,
                        }}
                    >
                        {val}
                    </span>
                ),
            },
            {
                title: 'Edit/Delete',
                width: '10%',
                key: 'operation',
                align: 'right' as const,
                render: (value: any, record: any) => (
                    <div style={{ marginTop: '-15px' }}>
                        <Button
                            style={{ marginTop: 15 }}
                            type="primary"
                            shape="round"
                            size="small"
                            onClick={() => {
                                setModalVisible(true)
                                setModalKeywordGroup(record)
                            }}
                        >
                            <EditOutlined />
                        </Button>

                        <Popconfirm
                            placement="topLeft"
                            title={t('keywordGroups.deletePopup')}
                            onConfirm={() => onDeleteKeywordGroup(record)}
                            okText={t('yes')}
                            cancelText={t('no')}
                        >
                            <Button
                                style={{ marginTop: 15, marginLeft: 10 }}
                                danger
                                type="primary"
                                shape="round"
                                size="small"
                            >
                                <CloseCircleOutlined />
                            </Button>
                        </Popconfirm>
                    </div>
                ),
            },
        ]

        return (
            <Table
                pagination={{
                    total: totalitems,
                    current: Number(searchQParams.page),
                    pageSize: 30,
                    onChange: onPageChange,
                    hideOnSinglePage: true,
                    pageSizeOptions: [],
                }}
                loading={isDataLoading}
                dataSource={dataSource}
                columns={columns}
            />
        )
    }

    const onPageChange = (page: number) => {
        const q = searchParams.get('q') || ''
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: q, page }),
        })

        setSearchParams({
            page,
            q,
        })
        if (page === searchQParams.page) {
            memoizedGetKeywordGroups(searchQParams)
        }
    }

    const onSearch = (searchQuery: string) => {
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery }),
        })
        setSearchParams({
            page: 1,
            q: searchQuery.trim(),
        })
    }

    const onApplyKeywordGroupModalChanges = async (
        id: any,
        formValues: any
    ) => {
        const keywordGroup = { id, ...formValues } as KeywordGroup
        try {
            const res = await KeywordGroupsAPI.save(keywordGroup)

            if (!res || res.status !== 200) {
                throw new Error('Bad response')
            }

            setModalVisible(false)
            setModalKeywordGroup(null)
            if (!id) {
                onPageChange(totalPages)
            } else {
                onPageChange(1)
            }
            message.success(t('keywordGroups.updated'))
        } catch (error) {
            message.error(
                t('errors.apiCommunication', { api: 'keyword group' })
            )
        }
    }

    const onDeleteKeywordGroup = async (keywordGroup: KeywordGroup) => {
        try {
            await KeywordGroupsAPI.delete(keywordGroup.id)
            message.success(
                t('keywordGroups.deleted', { name: keywordGroup.name })
            )
            onPageChange(1)
        } catch (error) {
            message.error(
                t('errors.apiCommunication', { api: 'keyword group' })
            )
        }
    }

    return (
        <div className="page keywords">
            <KeywordGroupModal
                onOk={onApplyKeywordGroupModalChanges}
                onCancel={() => {
                    setModalVisible(false)
                    setModalKeywordGroup(null)
                }}
                isVisible={isModalVisible}
                keywordGroup={modalKeywordGroup}
            />
            <div className="container">
                <ListViewHeader
                    buttonLabel={t('keywordGroups.add')}
                    title={t('keywordGroups.title')}
                    defaultValue={searchQParams?.q}
                    onSearch={onSearch}
                    isLoading={isDataLoading}
                    onButtonClick={() => {
                        setModalVisible(true)
                        setModalKeywordGroup({})
                    }}
                />
                <div className="page-content">{renderKeywordGroupList()}</div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(KeywordsPage)

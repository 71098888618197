import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, message, Form, Spin } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
// import { useAuth } from 'src/context/auth'
import mixpanel from 'mixpanel-browser'

import { SectionsAPI, DiseasesAPI } from 'src/api'
import { Section, Disease } from 'src/types'
import { useDisease } from 'src/context/disease'
import DiseaseCreationHeader from 'src/pages/diseaseCreation/components/Header'
import DiseaseCreationSideNav from 'src/pages/diseaseCreation/components/SideNavigation'
import DiseaseCreationForm from 'src/pages/diseaseCreation/components/Form'
import WizardStepsAPI from 'src/api/wizardSteps'
import { protectedRoutes } from 'src/router/routes'
import './style.scss'

const statuses: any = {
    0: 'Draft',
    1: 'Review',
    2: 'Changes requested',
    3: 'Approved',
    4: 'Published',
}

interface SubmitOptions {
    status: string
}

const isFormValid = (form: Disease) => {
    return !!(form.title && form.section_id && form.chapter_id)
}

const DiseaseFormLayout = (props: any) => {
    const { t } = useTranslation()
    const { id }: any = useParams()
    const navigate = useNavigate()

    const disease: any = useDisease()
    const diseaseForm = disease ? disease.disease : { tabs: {} }
    const setDiseaseForm: any = disease.setDisease
    const [activeStep, setActiveStep]: [any, any] = React.useState({})
    // const auth = useAuth()
    // const user = auth.user
    // const isEditor = user.roleID === 20
    // const isAdmin = user.roleID <= 10

    const [isLoadingSteps, setIsLoadingSteps] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [steps, setSteps] = React.useState([])
    const [tabs, setTabs] = React.useState([])
    const [diseases, setDiseases] = React.useState([])
    const [sectionChapters, setSectionChapters] = React.useState([])

    // Get form/wizard steps
    React.useEffect(() => {
        const getSteps = async () => {
            setIsLoadingSteps(true)

            try {
                const res = await WizardStepsAPI.list()

                if (!res || !res.data) {
                    throw new Error('Bad response')
                }

                const s = res.data
                const sorted = s.sort((a: any, b: any) => a.ordering - b.ordering)
                sorted.map((st: any, i: number) => (st.index = i))
                setSteps(sorted)
                setActiveStep(sorted[0])
                setTabs(getFlatTabs(sorted))

                let groupedTabs: any = []
                s.map((st: any) => {
                    if (st.id !== 1 && st.id !== 8 && st.tabs && st.tabs.length) {
                        const tabTabs = st.tabs
                            .filter((t: any) => t.id !== 28)
                            .map((t: any) => {
                                return {
                                    ...t,
                                    step: st,
                                }
                            })
                        groupedTabs = groupedTabs.concat(tabTabs)
                    }
                    return null
                })

                setDiseaseForm({
                    ...diseaseForm,
                    groupedTabs,
                })
            } catch (error) {
                message.error(t('diseaseCreation.errorGettingSteps'), 3000)
            } finally {
                setIsLoadingSteps(false)
            }
        }

        getSteps()

        const getDiseases = async () => {
            try {
                const res: any = await DiseasesAPI.listShort({ page: 1, q: '' })

                setDiseases(res.data)
            } catch (error) {
                console.error('Error getting existing diseases : ', error)
            }
        }

        getDiseases()
        // eslint-disable-next-line
    }, [])

    // Get info for a specific step
    React.useEffect(() => {
        const getStepInfo = async (id: number) => {
            // Get Step Info
        }

        getStepInfo(activeStep.id)
    }, [activeStep.id])

    const [sections, setSections]: [Array<Section>, any] = React.useState([])
    // Get sections
    React.useEffect(() => {
        const getSections = async () => {
            try {
                const res = await SectionsAPI.list()

                if (!res || !res.data) {
                    throw new Error('Bad response')
                }

                setSections(res?.data)
            } catch (error) {
                message.error(t('diseaseCreation.errorGettingSteps'))
            }
        }

        getSections()
        // eslint-disable-next-line
    }, [])

    const handleShowStep = (step: any) => {
        const s = !(step.children && step.children.length) ? step : step.children[0]
        setActiveStep(s)
    }

    const handleSubmit = async (opts: SubmitOptions) => {
        setIsSubmitting(true)
        try {
            const isCreate = diseaseForm.form_mode === 'create'
            const API = isCreate ? DiseasesAPI.create : DiseasesAPI.update

            // Backup Diff. Diagnosis tab since we remove it from the request
            const diff_dx_tab = { ...diseaseForm.tabs[28] }
            const _d = JSON.parse(JSON.stringify(diseaseForm))
            const df = prepareDisease(_d)

            // if (isEditor || isAdmin) {
            //     const status: Number = opts?.status === 'in_review' ? 1 : 0
            //     df.status = status
            // }

            mixpanel.track('Disease Form Submitted', {
                id,
                disease: df,
                mode: isCreate ? 'create' : 'edit',
            })

            const res = await API(df, id)

            // Reset Differential Diagnosis tab since it was deleted from request
            setDiseaseForm({
                ...diseaseForm,
                tabs: { ...diseaseForm.tabs, 28: diff_dx_tab },
                ...(isCreate ? { id: res.data } : {}),
            })

            setIsSubmitting(false)

            if (isCreate) {
                message.success('Disease created, app will reload')
                setTimeout(() => {
                    window.location.replace(`/edit-disease/${res.data}`)
                }, 300)
            } else {
                const msg = df.status === 1 ? 'Disease will move to Draft status, app will reload' : 'Disease saved'
                message.success(msg)

                if (df.status === 1) {
                    setTimeout(() => {
                        window.location.replace(`/edit-disease/${id}`)
                    }, 800)
                }
            }
        } catch (error: any) {
            mixpanel.track('Disease Form Submission Error', {
                id,
                error,
            })
            setIsSubmitting(false)
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
            console.error('Error submitting disease : ', error)
        }
    }

    const handleDelete = async () => {
        try {
            await DiseasesAPI.delete(diseaseForm.id)
            message.success('Disease deleted')
            navigate(protectedRoutes.diseases.route)
        } catch (error: any) {
            const msg = error?.response?.data || 'There has been an error deleting the disease, check console for logs'
            alert(msg)
            console.error('Error deleting disease : ', error)
        }
    }

    const handlePublish = async () => {
        setIsSubmitting(true)
        try {
            await DiseasesAPI.publish(id)
            message.success('Disease published')

            if (diseaseForm.parent_id) {
                message.success('Opening parent disease...')
                setTimeout(() => {
                    window.location.replace(`/edit-disease/${diseaseForm.parent_id}`)
                }, 500)
            } else {
                setTimeout(() => {
                    window.location.replace(`/edit-disease/${id}`)
                }, 500)
            }
        } catch (error: any) {
            setIsSubmitting(false)
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
            console.error('Error approving disease : ', error)
        }
    }

    const handleDecline = async () => {
        setIsSubmitting(true)
        try {
            await DiseasesAPI.decline(id)
            message.success('Disease declined')
        } catch (error: any) {
            setIsSubmitting(false)
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
            console.error('Error declining disease : ', error)
        }
    }

    const handleDuplicate = async () => {
        try {
            const res = await DiseasesAPI.duplicate(id)
            message.success('Disease duplicated')
            setTimeout(() => {
                window.open(`/edit-disease/${res.data}`, '_blank')
            }, 500)
        } catch (error: any) {
            setIsSubmitting(false)
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
            console.error('Error duplicating disease : ', error)
        }
    }

    const handleEditPublished = async () => {
        try {
            const res = await DiseasesAPI.editPublished(id)
            message.success('New draft disease created')
            setTimeout(() => {
                window.open(`/edit-disease/${res.data}`, '_blank')
            }, 500)
        } catch (error: any) {
            setIsSubmitting(false)
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
            console.error('Error editing published disease : ', error)
        }
    }

    const handleGotChapters = (chapters: any) => {
        setSectionChapters(chapters)
    }

    const handleEditFromConfirmation = () => {
        window.location.replace(`/edit-disease/${diseaseForm.id}`)
    }

    const handleSubmitForReviewFromConfirmation = async () => {
        const _id = diseaseForm.form_mode === 'create' ? diseaseForm.id : id
        try {
            await DiseasesAPI.submitForReview(_id)
            message.success('Disease submitted for review, redirecting to My Contribution')
            setTimeout(() => {
                navigate(protectedRoutes.myContribution.route)
            }, 600)
        } catch (error: any) {
            const msg = error?.response?.data || 'There has been an error, check console for logs'
            alert(msg)
        }
    }

    const DiseaseTitle = (props: any) => {
        const s = statuses[props.disease.status]
        const t = diseaseForm ? diseaseForm.title : ''

        if (props.disease.form_mode === 'edit') {
            return (
                <span>
                    <span className={`status-label status-${props.disease.status}`}>{s}</span> {t}
                </span>
            )
        }

        return <span>Add a new disease {t ? ` : ${t}` : ''}</span>
    }

    const isSubmitDisabled = !isFormValid(diseaseForm) || isSubmitting

    return (
        <div>
            <DiseaseCreationHeader>
                <DiseaseTitle disease={diseaseForm} />
            </DiseaseCreationHeader>

            <div className="container disease-creation-container">
                {isLoadingSteps ? (
                    <div style={{ textAlign: 'center', marginTop: 200 }}>
                        <Spin />
                        <p>Preparing Disease Creation Form...</p>
                    </div>
                ) : (
                    <div>
                        <Row gutter={32}>
                            {activeStep.id !== 100 && (
                                <Col span={4} className="disease-creation-nav-col">
                                    <DiseaseCreationSideNav
                                        disease={diseaseForm}
                                        mode={diseaseForm.form_mode}
                                        steps={steps}
                                        activeStep={activeStep}
                                        sectionChapters={sectionChapters}
                                        isSubmitDisabled={isSubmitDisabled}
                                        onShowStep={handleShowStep}
                                        onSubmit={handleSubmit}
                                        onSubmitForReview={handleSubmitForReviewFromConfirmation}
                                        onDelete={handleDelete}
                                        onPublish={handlePublish}
                                        onDecline={handleDecline}
                                        onDuplicate={handleDuplicate}
                                        onEditPublished={handleEditPublished}
                                    />
                                </Col>
                            )}

                            <Col
                                span={activeStep.id !== 100 ? 20 : 24}
                                className={props.disease.status === 4 ? `view-only` : ''}
                            >
                                <Form layout="vertical">
                                    <DiseaseCreationForm
                                        mode={diseaseForm.form_mode}
                                        step={activeStep}
                                        tabs={tabs}
                                        sections={sections}
                                        diseases={diseases}
                                        onGotChapters={handleGotChapters}
                                        onEditFromConfirmation={handleEditFromConfirmation}
                                        onSubmitForReviewFromConfirmation={handleSubmitForReviewFromConfirmation}
                                    />
                                </Form>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DiseaseFormLayout

// Turn step keyword groups into flat list of keywords
const prepareDisease = (df: any) => {
    let diffDiag: any = null

    for (const [key, value] of Object.entries(df.tabs)) {
        const v: any = value

        if (!v) {
            console.error('Value of disease tab undefined.')
            return {}
        }

        // 1. format images
        if (v.images && v.images.length) {
            df.tabs[key].images = v.images.map((img: any) => ({
                image_id: img.id || img.image_id,
                ordering: img.ordering,
            }))
        }

        // 2. format keywords
        if (v.keyword_groups && v.keyword_groups.length) {
            df.tabs[key].keywords = v?.keyword_groups
                ?.map((kg: any) => kg)
                .reduce((acc: any, curr: any = []) => curr.concat(acc))

            delete df.tabs[key].keyword_groups
        }

        // Differential Diagnosis tab
        if (parseInt(key) === 28 && df.tabs[28] && df.tabs[28].keywords) {
            diffDiag = []
            // eslint-disable-next-line no-loop-func
            df.tabs[28].keywords.selected.forEach((d: any) => {
                if (d.id) {
                    diffDiag.push({
                        diff_disease_id: d.id,
                        comment: d.comment,
                    })
                }
            })
            df.tabs[28] = null
            delete df.tabs[28]
        } else if (df.tabs[key].keywords && df.tabs[key].keywords.length) {
            df.tabs[key].keywords = df.tabs[key].keywords.filter((k: any) => !!k.name || !!k.keyword?.name)
            df.tabs[key].keywords = df.tabs[key].keywords.map((k: any) => {
                return {
                    keyword_id: k.keyword_id || k.id,
                    weight: k.weight,
                    comment: k.comment,
                    ...(k.options && { options: getIHCOptionIDs(k.options) }),
                }
            })
        }
    }

    if (Array.isArray(diffDiag)) {
        df.diff_diagnoses = diffDiag
    }

    delete df.form_mode
    delete df.groupedTabs

    return df
}

// Get flat list of tabs from steps. Object key corresponds to Tab ID.
const getFlatTabs = (steps: any) => {
    if (!steps || !steps.length) return null

    const flatTabs: any = {}
    steps.forEach((s: any) => {
        if (!s.tabs || !s.tabs.length) return

        s.tabs.forEach((tab: any) => {
            flatTabs[tab.id] = tab
        })
    })

    return flatTabs
}

const getIHCOptionIDs = (options: any) => {
    if (!options || !options.length) return []

    return options.map((o: any) => {
        if (typeof o === 'object') return o.id
        return o
    })
}

import * as React from 'react';
import { Form, Row, Col, Typography } from 'antd';

import { useDisease } from 'src/context/disease';
import KeywordGroup from 'src/pages/diseaseCreation/components/Form/KeywordGroup';

const { Text } = Typography;

const TabKeywordGroups = (props: any) => {
  const disease: any = useDisease();
  const diseaseForm = disease ? disease.disease : { tabs : {} };
  const setDiseaseForm: any = disease.setDisease;

  const [tabKeywordGroups, setTabKeywordGroups] = React.useState(
    (props && props.tab) ? props.tab.keyword_groups : []
  );

  React.useEffect(() => {
    if (props.type === 'diffdx') {
      let diffDxKG = props.tab.keyword_groups;

      if (props.selectedDiffDx && props.selectedDiffDx.length) {
        diffDxKG = [...props.tab.keyword_groups];
        diffDxKG[0].selected = props.selectedDiffDx.map((s : any) => ({
          ...s,
          id: s.diff_disease_id || s.id
        }));
      }

      setTabKeywordGroups(diffDxKG);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab, props.type]);

  React.useEffect(() => {
    if (disease) {
      mapSelectedKeywordsToGroups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapSelectedKeywordsToGroups = () => {
    if (!disease || !disease.disease || !disease.disease.tabs[props.tab.id] || !disease.disease.tabs[props.tab.id].keywords) return;
    const k = disease.disease.tabs[props.tab.id].keywords;
    let kGroups : any = {};
    k.forEach((_k : any) => {
      if (!_k.keyword) return;
      if (!kGroups[_k.keyword.keyword_group_id]) kGroups[_k.keyword.keyword_group_id] = {id: _k.keyword.keyword_group_id, keywords: []};
      kGroups[_k.keyword.keyword_group_id].keywords.push(_k);
    });

    let newKG = tabKeywordGroups && tabKeywordGroups.length ? [...tabKeywordGroups] : [];
    Object.keys(kGroups).forEach((_kgID : any) => {
      // eslint-disable-next-line eqeqeq
      const kgIndex = newKG.findIndex((_kg : any) => _kg.id == _kgID);
      if (kgIndex !== -1) {
        kGroups[_kgID].keywords.forEach((_kwd : any) => {
          _kwd.keyword_group_id = _kwd.id;
          _kwd.id = _kwd.keyword_id;
          _kwd.name = _kwd.keyword.name;
        });
        newKG[kgIndex].selected = kGroups[_kgID].keywords;
      }
    });

    setTabKeywordGroups([...newKG]);
  }

  const handleUpdateKeywordGroup = (keywords: [], i: number) => {
    if (!props.tab.id) {
      // TODO: make it a mandatory prop
      return;
    }

    let kg = tabKeywordGroups;
    kg[i].selected = keywords;
    const newKG = [...kg];
    setTabKeywordGroups(newKG);

    let filtered = newKG;
    if (props.type !== 'diffdx') {
      filtered = newKG.map((kg: any) => kg.selected)
        .filter((kg: any) => kg && kg.length);
    }

    let newDF = { ...diseaseForm };

    if(!newDF.tabs) {
      newDF.tabs = {};
    }


    if(!newDF.tabs[props.tab.id]) {
      newDF.tabs[props.tab.id] = {};
    }

    newDF.tabs[props.tab.id].keyword_groups = filtered;
    newDF.tabs[props.tab.id].keywords = filtered;
    setDiseaseForm({ ...newDF });
  };

  const nameField = props.nameField || 'name';

  return (
    <div>
      {tabKeywordGroups &&
        tabKeywordGroups.map((kg: any, i: number) => {
          if (!kg.keywords || !kg.keywords.length) {
            return (
              <Text disabled>No Keywords</Text>
            );
          }

          return (
            <Row key={i}>
              <Col span={24} key={i}>
                <Form.Item
                  label={kg[nameField]}
                  tooltip={
                    kg.description ? <span>{kg.description}</span> : null
                  }>
                  <KeywordGroup
                    key={i}
                    readOnly={props.readOnly}
                    disease={disease.disease}
                    group={kg}
                    nameField={nameField}
                    hideWeight={props.hideWeight}
                    type={props.type}
                    onUpdate={(keywords: []) =>
                      handleUpdateKeywordGroup(keywords, i)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
    </div>
  );
};

export default TabKeywordGroups;

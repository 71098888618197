import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'antd'

import { publicRoutes, protectedRoutes } from 'src/router/routes'
import './style.scss'

const flatten = (object: any): any => {
    let result = [] as any
    Object.keys(object).forEach((key) => {
        if (object[key].route) {
            result.push(object[key])
        } else {
            result.push(flatten(object[key]))
        }
    })
    return result.flatMap((item: any) => item)
}

const BreadCrumbs = () => {
    const { t } = useTranslation()
    const flattenedRoutes = flatten({ publicRoutes, protectedRoutes })
    const location = useLocation()

    const preparedRoutes = flattenedRoutes.reduce((acc: any, curr: any) => {
        acc[curr.route] = curr.key
        return acc
    }, {})

    const pathSnippets = location.pathname.split('/').filter((i) => i)
    const breadcrumbItems = pathSnippets.reduce(
        (acc: any, curr: any, index: number) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
            if (preparedRoutes[url]) {
                acc.push(
                    <Breadcrumb.Item key={url}>
                        <Link to={url}>{t(preparedRoutes[url])}</Link>
                    </Breadcrumb.Item>
                )
            } else {
                Object.keys(flattenedRoutes).forEach((k: any) => {
                    if (
                        flattenedRoutes[k].regexp &&
                        flattenedRoutes[k].regexp.test(url)
                    ) {
                        acc.push(
                            <Breadcrumb.Item key={url}>
                                <Link to={url}>
                                    {t(flattenedRoutes[k].key)}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    }
                })
            }
            return acc
        },
        []
    )

    breadcrumbItems.unshift(
        <Breadcrumb.Item key="home">
            <Link to="/">{t('routes.root')}</Link>
        </Breadcrumb.Item>
    )

    // Conditional used here (instead of &&) in order to return JSX element, and not JSX | boolean
    return breadcrumbItems.length > 1 ? (
        <div className="container breadcrumbs-container">
            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </div>
    ) : null
}

export default BreadCrumbs

import {
    FileTextOutlined,
    DatabaseOutlined,
    FileAddOutlined,
    AppstoreOutlined,
    DeploymentUnitOutlined,
    TeamOutlined,
    SettingOutlined,
    InfoCircleOutlined, AppstoreAddOutlined
} from '@ant-design/icons'
import { CardItem } from '../navigationCard/CardItem'
import { CardsNavigationCategory } from './constants'
import { protectedRoutes } from 'src/router/routes'

export class CardItemsGenerator {
    category: CardsNavigationCategory

    constructor(category: CardsNavigationCategory) {
        this.category = category
    }

    generate() {
        switch (this.category) {
            case CardsNavigationCategory.DISEASES:
                return this.generateEditorItems()
            case CardsNavigationCategory.ADMIN:
                return this.generateAdminItems()
            default:
                return []
        }
    }

    private generateEditorItems() {
        const myContributionCard = new CardItem(
            <FileTextOutlined />,
            this.getTranslationKeys('myContribution'),
            protectedRoutes.myContribution.route
        )

        const viewAllDiseasesCard = new CardItem(
            <DatabaseOutlined />,
            this.getTranslationKeys('allDiseases'),
            protectedRoutes.diseases.route
        )

        const addDiseaseCard = new CardItem(
            <FileAddOutlined />,
            this.getTranslationKeys('addDisease'),
            protectedRoutes.diseaseCreation.index.route,
            true
        )

        return [myContributionCard, viewAllDiseasesCard, addDiseaseCard]
    }

    private generateAdminItems() {
        const sectionsCard = new CardItem(
            <AppstoreOutlined />,
            this.getTranslationKeys('sections'),
            protectedRoutes.sections.index.route
        )

        const keywordGroupsCard = new CardItem(
            <DeploymentUnitOutlined />,
            this.getTranslationKeys('keywordGroups'),
            protectedRoutes.keywordGroups.index.route
        )

        const usersCard = new CardItem(
            <TeamOutlined />,
            this.getTranslationKeys('users'),
            protectedRoutes.users.route
        )

        const settingsCard = new CardItem(
            <SettingOutlined />,
            this.getTranslationKeys('settings'),
            protectedRoutes.settings.keywords.route
        )

        const statsCard = new CardItem(
            <InfoCircleOutlined />,
            this.getTranslationKeys('stats'),
            protectedRoutes.stats.route
        )

        const superSectionsCard = new CardItem(
            <AppstoreAddOutlined />,
            this.getTranslationKeys('supersections'),
            protectedRoutes.supersections.route
        )

        return [statsCard, superSectionsCard, sectionsCard, keywordGroupsCard, usersCard, settingsCard]
    }

    private getTranslationKeys(key: string) {
        return [
            `navigationCards.${key}.title`,
            `navigationCards.${key}.description`,
            `navigationCards.${key}.buttonText`,
        ]
    }
}

import * as React from 'react';

import { Disease } from 'src/types/disease';

const DiseaseContext = React.createContext(null);

const DiseaseProvider = (props: any) => {
  let d : any = props.disease;

  const [disease, setDisease]: [Disease, any] = React.useState(d);

  return <DiseaseContext.Provider value={{ disease, setDisease }} {...props} />;
};

const useDisease = () => React.useContext(DiseaseContext);

export { DiseaseProvider, useDisease };

import API from './api'
import { ApiResponse, Chapter } from 'src/types'
import { AxiosResponse } from 'axios'

const baseURL = '/chapters'

const Chapters = {
    list() {
        return API.get(baseURL)
    },

    save(chapter: Chapter) {
        if (chapter.id) {
            return API.put(`${baseURL}/${chapter.id}`, { ...chapter })
        } else {
            return API.post(`${baseURL}`, { ...chapter })
        }
    },

    delete: (chapter_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.delete(`${baseURL}/${chapter_id}`)
    },

    assign: (chapter_id: number, user_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${chapter_id}/assign-user`, {
            user_id,
        })
    },

    unassign: (chapter_id: number, user_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
        return API.put(`${baseURL}/${chapter_id}/unassign-user`, {
            user_id,
        })
    }
}

export default Chapters

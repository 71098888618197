import { message } from 'antd'

import { useDisease } from 'src/context/disease'
import { DiseasesAPI } from 'src/api'

const useImages = (tab: any) => {
    const disease: any = useDisease()
    const diseaseForm = disease
        ? disease.disease
        : {
              tabs: {},
          }
    const setDiseaseForm: any = disease.setDisease

    const addImage = (image: any) => {
        let df = diseaseForm || {}

        if (!df.tabs) {
            df.tabs = {}
        }

        if (!df.tabs[tab.id]) {
            df.tabs[tab.id] = {}
        }

        if (!df.tabs[tab.id].images || !df.tabs[tab.id].images.length) {
            df.tabs[tab.id].images = []
        }

        let tabImages = df.tabs[tab.id].images
        tabImages.push(image)

        df.tabs[tab.id].images = [...tabImages]
        setDiseaseForm({ ...diseaseForm })
    }

    const editImage = (index: number, image: any) => {
        let pf = diseaseForm.tabs[tab.id]
        pf.images[index] = { ...image }
        setDiseaseForm({
            ...diseaseForm,
            tabs: { ...diseaseForm.tabs, [tab.id]: pf },
        })
    }

    const removeImage = (index: number, image: any) => {
        let pf = diseaseForm.tabs[tab.id]
        pf.images.splice(index, 1)
        setDiseaseForm({
            ...diseaseForm,
            tabs: { ...diseaseForm.tabs, [tab.id]: {...pf} },
        })
    }

    const saveImageOrder = async (images: any) => {
        try {
            await DiseasesAPI.orderImages(
                diseaseForm.id,
                tab.id,
                images.map((image: any) => image.id)
            )

            let pf = diseaseForm.tabs[tab.id]
            pf.images = images.map((image: any, i: any) => {
                image.ordering = i
                return image
            })

            setDiseaseForm({
                ...diseaseForm,
                tabs: { ...diseaseForm.tabs, [tab.id]: pf },
            })

            message.success('Image order saved')
        } catch (error) {
            message.error('Error saving image order')
        }
    }

    return [addImage, editImage, removeImage, saveImageOrder]
}

export default useImages

import API from "./api";
import { User } from "src/types";

const baseURL = "/users";

const Users = {
  list: (page?: number, searchQuery?: string): any => {
    let qp = page ? `?page=${page}` : "";
    if (searchQuery) {
      if (qp) {
        qp += "&";
      } else {
        qp += "?";
      }
      qp += `q=${searchQuery}`;
    }
    return API.get(`${baseURL}${qp}`);
  },

  save(user: User) {
    if (user.id) {
      return API.put(`${baseURL}/${user.id}`, { ...user });
    } else {
      return API.post(`${baseURL}`, { ...user });
    }
  },

  delete(userId: number) {
    return API.delete(`${baseURL}/${userId}`);
  },

  stats() {
    return API.get(`${baseURL}/stats`);
  },

  updateSubscription(userId: number, subscriptionId: number) {
    return API.put(`${baseURL}/subscription/update`, { user_id: userId, subscription_plan_id: subscriptionId });
  }
};

export default Users;

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Select, Slider, InputNumber, Input, Tooltip, Checkbox } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import algoliasearch from 'algoliasearch/lite'

import RichText from 'src/components/common/inputs/rich-text'
import { editorConfig } from 'src/util/wysiwyg'
const { Option } = Select

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID || '',
    process.env.REACT_APP_ALGOLIA_TOKEN || ''
).initIndex(process.env.REACT_APP_ALGOLIA_DISEASE_INDEX_NAME || '')

const KeywordGroup = (props: any) => {
    const { t } = useTranslation()
    const _originalKeywords = props.group.keywords
        ? props.group.keywords.sort((a: any, b: any) => a.ordering - b.ordering)
        : []
    const [originalKeywords, setOriginalKeywords] = React.useState(_originalKeywords)
    const [sortedKeywords, setSortedKeywords] = React.useState(originalKeywords)

    React.useEffect(() => {
        const _ok = props.group.keywords ? props.group.keywords.sort((a: any, b: any) => a.ordering - b.ordering) : []
        setOriginalKeywords(_ok)
        setSortedKeywords(_ok)
    }, [props.group.keywords])

    const handleSearch = (q: string) => {
        searchClient
            .search(q, {
                filters: `status:4`,
            })
            .then((res: any) => {
                const d = res.hits
                    .filter((h: any) => {
                        return h.disease_id !== parseInt(props.disease.parent_id)
                    })
                    .map((h: any) => {
                        return {
                            id: h.disease_id,
                            title: h.title,
                            section: h.section,
                            chapter: h.chapter,
                            alternative_titles: h.alternative_titles,
                        }
                    })
                setSortedKeywords(d)
            })
            .catch((err: any) => {
                console.error('Algolia search error : ', err)
            })
    }

    const debouncedSearch = React.useMemo(() => debounce(handleSearch, 500), [])

    if (!props.group || !props.group.keywords) {
        return null
    }

    const { onUpdate } = props

    let selected = props.group.selected || [{ weight: 50 }]
    selected = selected.sort((a: any, b: any) => a.ordering - b.ordering)

    const handleChange = (field: string, value: any, i: number) => {
        let sk = selected
        const k: any = sk[i]
        k[field] = value
        onUpdate(sk)
    }

    const handleChangeName = (o: any, i: number) => {
        let sk = selected
        let k: any = sk[i]
        if (!k) {
            k = {}
        }
        k.id = o.value
        k.name = isDiffDxGroup ? o.children[0] : o.children
        k.section = isDiffDxGroup ? o.title.split(' | ')[0] : ''
        k.chapter = isDiffDxGroup ? o.title.split(' | ')[1] : ''
        onUpdate(sk)
        setSortedKeywords(filterKeywordOptions(sk))
    }

    const handleAdd = () => {
        let s = [...selected]
        s.push({ weight: 50 })
        onUpdate(s)
        setSortedKeywords(filterKeywordOptions(s))
    }

    const handleRemove = (i: number) => {
        let s = [...selected]
        s.splice(i, 1)
        onUpdate(s)
        setSortedKeywords(filterKeywordOptions(s))
    }

    // Filter out already selected keywords so they can't be selected more than once
    const filterKeywordOptions = (selectedKeywords: Array<any>) => {
        if (!selectedKeywords || !selectedKeywords.length) return originalKeywords

        const selectedKeywordNames = selectedKeywords.map((sk: any) => sk.name)
        return originalKeywords.filter((k: any) => {
            if (k.name) return selectedKeywordNames.indexOf(k.name) === -1
            return 1
        })
    }

    const getSelectedOptions = (options: Array<any>) => {
        if (!options || !options.length) return []

        return options.map((o: any) => {
            if (typeof o === 'object') return o.id
            return o
        })
    }

    const isIHCMarkersGroup = props.group.name === 'Positive IHC markers'
    const isNegativeMarkerGroup =
        props.group.name === 'Negative IHC markers' ||
        props.group.name === 'Negative molecular markers' ||
        props.group.name === 'Negative special stains'
    const isDiseaseGroup = props.group.name === 'Disease group'
    const isDiffDxGroup = props.type === 'diffdx'
    const hideWeight = props.hideWeight || isDiseaseGroup || isNegativeMarkerGroup

    let selectColSpan = 6
    if (isIHCMarkersGroup) selectColSpan = 4
    if (isDiffDxGroup) selectColSpan = 20

    let commentColSpan = 7
    if (isIHCMarkersGroup) commentColSpan = 3
    if (isDiffDxGroup) commentColSpan = 24

    const renderControls = (i: number) => {
        return (
            <React.Fragment>
                {!props.readOnly ? (
                    <Col span={11} className="keywordGroup-control">
                        {selected.length > 1 && (
                            <Tooltip title={t('keywords.remove')} placement="bottom">
                                <MinusCircleOutlined onClick={() => handleRemove(i)} />
                            </Tooltip>
                        )}
                    </Col>
                ) : null}
                {!props.readOnly ? (
                    <Col span={11} offset={1} className="keywordGroup-control">
                        {i === selected.length - 1 && (
                            <Tooltip title={t('keywords.add')} placement="bottom">
                                <PlusCircleOutlined onClick={handleAdd} />
                            </Tooltip>
                        )}
                    </Col>
                ) : null}
            </React.Fragment>
        )
    }

    return (
        <div>
            {selected.map((keyword: any, i: number) => {
                const selectedOptions = [...getSelectedOptions(keyword.options)]
                return (
                    <Row
                        key={i}
                        style={{
                            marginBottom: !isIHCMarkersGroup ? 15 : 65,
                            borderBottom: isDiffDxGroup ? '1px solid #dbdbdb' : 'none',
                            paddingBottom: isDiffDxGroup ? 15 : 0,
                        }}
                    >
                        <Col span={selectColSpan}>
                            <Select
                                placeholder={t('keywords.select')}
                                defaultValue={
                                    props.type === 'diffdx' && keyword.name
                                        ? `${keyword.name} | ${keyword.section} | ${keyword.chapter}`
                                        : keyword.name
                                }
                                value={
                                    props.type === 'diffdx' && keyword.name
                                        ? `${keyword.name} | ${keyword.section} | ${keyword.chapter}`
                                        : keyword.name
                                }
                                onSelect={(v, o: any) => handleChangeName(o, i)}
                                showSearch
                                onSearch={props.type === 'diffdx' ? debouncedSearch : () => {}}
                                disabled={props.readOnly}
                                filterOption={
                                    props.type === 'diffdx'
                                        ? false
                                        : (input, option: any) =>
                                              option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {sortedKeywords
                                    ? sortedKeywords.map((s: any, k: number) => (
                                          <Option
                                              key={k}
                                              value={s.id}
                                              title={isDiffDxGroup ? `${s.section} | ${s.chapter}` : ''}
                                          >
                                              {s[props.nameField]}
                                              {isDiffDxGroup ? (
                                                  <div>
                                                      <span style={{ color: '#222', fontSize: 12 }}>
                                                          {s.section} &#183; {s.chapter}
                                                          {s.alternative_titles?.length ? (
                                                              <div>
                                                                  &#40;
                                                                  {s.alternative_titles?.map((t: any, i: number) => (
                                                                      <span
                                                                          key={i}
                                                                          style={{ color: '#222', fontSize: 12 }}
                                                                      >
                                                                          {t}
                                                                          {i < s.alternative_titles.length - 1
                                                                              ? ', '
                                                                              : ''}
                                                                      </span>
                                                                  ))}
                                                                  &#41;
                                                              </div>
                                                          ) : null}
                                                      </span>
                                                  </div>
                                              ) : null}
                                          </Option>
                                      ))
                                    : null}
                            </Select>
                        </Col>
                        {isDiffDxGroup ? (
                            <Col span={3} offset={1}>
                                <Row style={{ justifyContent: 'flex-end', height: '100%' }}>{renderControls(i)}</Row>
                            </Col>
                        ) : null}
                        {!hideWeight ? (
                            <React.Fragment>
                                <Col span={4} offset={!isIHCMarkersGroup ? 1 : 0}>
                                    <Slider
                                        disabled={props.readOnly}
                                        min={0}
                                        max={100}
                                        onChange={(v: number) => handleChange('weight', v, i)}
                                        value={keyword.weight}
                                        style={{ marginLeft: 20 }}
                                    />
                                </Col>
                                <Col span={1}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        style={{ marginLeft: 5, width: '100%', borderRadius: 20 }}
                                        className="keywordGroup-weight-number"
                                        value={keyword.weight}
                                        readOnly
                                    />
                                </Col>
                            </React.Fragment>
                        ) : null}
                        {isIHCMarkersGroup ? (
                            <Col
                                span={8}
                                style={{
                                    marginLeft: 20,
                                    marginTop: -30,
                                }}
                            >
                                <Checkbox.Group
                                    value={selectedOptions}
                                    onChange={(checkedOptions: Array<any>) =>
                                        handleChange('options', checkedOptions, i)
                                    }
                                >
                                    <span>Staining pattern</span>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value={1}>cytoplasmic</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value={2}>membranous</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value={3}>nuclear</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value={4}>diffuse</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value={5}>focal</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value={6}>scattered</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        ) : null}
                        <Col span={commentColSpan} offset={!isIHCMarkersGroup && !isDiffDxGroup ? 1 : 0}>
                            {!isDiffDxGroup ? (
                                <Input
                                    placeholder={t('comment')}
                                    readOnly={props.readOnly}
                                    value={keyword.comment}
                                    onChange={(e: any) => handleChange('comment', e.target.value, i)}
                                />
                            ) : (
                                <div style={{ marginTop: 5 }}>
                                    <RichText
                                        readOnly={props.readOnly}
                                        editorConfig={editorConfig}
                                        value={keyword.comment || ''}
                                        enableRephrase={true}
                                        onChange={(text: string, delta?: any, source?: string, editor?: any) => {
                                            if (source === 'user') {
                                                handleChange('comment', text, i)
                                            }
                                        }}
                                        onUseRephrased={(text: string) => {
                                            handleChange('comment', text, i)
                                        }}
                                    />
                                </div>
                            )}
                        </Col>
                        {!isDiffDxGroup ? (
                            <Col span={1} offset={1}>
                                <Row style={{ justifyContent: 'flex-end', height: '100%' }}>{renderControls(i)}</Row>
                            </Col>
                        ) : null}
                    </Row>
                )
            })}
        </div>
    )
}

export default KeywordGroup

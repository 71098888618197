import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Col, Form, Button } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import TabKeywordGroups from 'src/pages/diseaseCreation/components/Form/TabKeywordGroups'
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';
import QuickCreate from 'src/pages/diseaseCreation/modal/quickCreate';
import { Section } from 'src/types/section';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
  diseases: Array<{}>;
  sections: Array<Section>;
};

const DiffDx = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);
  const [
    isQuickCreateVisible,
    setIsQuickCreateVisible
  ] = React.useState(false);
  const [
    tab,
    setTab
  ] = React.useState({
    ...props.tabs[28],
    keyword_groups: [{
      keywords : props.diseases
    }]
  });

  const handleQuickCreate = (d: any) => {
    const keyword_groups = [{
      keywords : props.diseases.concat({
        title : d.title,
        id : d.id
      })
    }];
    setTab({
      ...props.tabs[28],
      keyword_groups
    });
    setIsQuickCreateVisible(false);
  }

  const _dfDx = (diseaseForm.tabs[28] && diseaseForm.tabs[28].keyword_groups && diseaseForm.tabs[28].keyword_groups[0] && diseaseForm.tabs[28].keyword_groups[0].selected) ? diseaseForm.tabs[28].keyword_groups[0].selected : null;

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {/* Differential diagnosis */}
        <TabPane
          key={0}
          tab={props.tabs[28].name}
          closable={false}>
          <Col span={24}>
            <p>
              Don't see the disease you need?
              <Button
                type="default"
                shape="round"
                onClick={() => setIsQuickCreateVisible(true)}
                style={{
                  marginLeft: '15px'
                }}
              >
                Add a new disease
              </Button>
            </p>

            <QuickCreate
              isVisible={isQuickCreateVisible}
              diseases={props.diseases}
              sections={props.sections}
              tabs={props.tabs}
              onOk={() => {}}
              onCancel={() => setIsQuickCreateVisible(false)}
              onCreate={(d : any) => handleQuickCreate(d)}
            />

            <Form.Item label={t('diseaseCreation.form.diffDx')}>
              <TabKeywordGroups
                tab={tab}
                type="diffdx"
                nameField='title'
                hideWeight={true}
                readOnly={diseaseForm.form_mode === 'view'}
                selectedDiffDx={_dfDx || diseaseForm.diff_diagnoses}
              />
            </Form.Item>
          </Col>
        </TabPane>

        {/* Pitfalls */}
        <TabPane
          key={1}
          tab={props.tabs[29].name}
          closable={false}>
          <Tab
            tab={props.tabs[29]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.etiologyAndPathogenesisPlaceholder')}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DiffDx;

import React, { useState } from 'react'
import { Modal, message } from 'antd'
import { useTranslation } from 'react-i18next'

import GeneralStep from 'src/pages/diseaseCreation/components/Form/steps/General'
import { Section } from 'src/types/section'
import { DiseasesAPI } from 'src/api';

type Props = {
    onOk: any
    onCancel: any
    isVisible: boolean
    diseases: Array<{}>
    sections: Array<Section>
    tabs: Array<{}>
    onCreate: (d: any) => void
}

const QuickCreate = (props: Props) => {
    const { t } = useTranslation()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [quickDisease, setQuickDisease] = React.useState({})

    const onSetQuick = (d: any) => {
        setQuickDisease(d);
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        
        try {
          const res = await DiseasesAPI.create(quickDisease);
          console.log('Disease created quickly : ', res);
          setIsSubmitting(false);
          props.onCreate({
              ...quickDisease,
              id: res.data
          });
          message.success('Disease created');
        } catch (error) {
            message.error('There was a problem creating this disease');
          console.error('Error submitting disease : ', error);
          setIsSubmitting(false);
        }
    }

    return (
        <Modal
            visible={props.isVisible}
            title={t('diseaseCreation.title')}
            onCancel={() => props.onCancel()}
            onOk={() => onSubmit()}
            okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
            okText={t('diseaseCreation.save')}
            forceRender
            width={'80%'}
        >
            <GeneralStep
                diseases={props.diseases}
                sections={props.sections}
                tabs={props.tabs}
                mode="quick"
                onSetQuick={onSetQuick}
            />
        </Modal>
    )
}

export default QuickCreate

import React, { useEffect, useState } from "react";
import {Form, Input, Modal, Select, Tag} from "antd";
import { useTranslation } from "react-i18next";

import { SuperSection } from "src/types";
const { Option } = Select;

type Props = {
  onOk: Function;
  onCancel: Function;
  isVisible: boolean;
  supersection?: SuperSection;
  sections: any;
}

const SuperSectionModal = (props: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const { supersection } = props;
  const [sections, setSections] = useState([] as any);

  useEffect(() => {
    form.setFieldsValue({
      name: supersection?.name || '',
    });
    setSections(props.supersection?.sections || []);
  }, [form, props.supersection?.sections, supersection]);


  const onFormSubmit = () => {
    setIsSubmitting(true);
    form.validateFields(['name'])
      .then(() => {
        const sectionIDs = sections.map((u: any) => u.id)

        props.onOk(props.supersection?.id, form.getFieldsValue(), sectionIDs);
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsSubmitting(false)
      });
  }

  const handleSelectSection = (sectionId: any) => {
    const section = props.sections.find((u: any) => u.id === sectionId)
    if (!section) {
      return
    }

    setSections([...sections, section])
  }

  const handleRemoveSection = (sectionId: any) => {
    setSections(sections.filter((u: any) => u.id !== sectionId))
  }

  return (
    <Modal
      maskClosable={false}
      visible={props.isVisible}
      title={props.supersection?.id ? t("supersections.edit_supersection") : t("supersections.create_supersection")}
      onCancel={() => props.onCancel()}
      onOk={() => onFormSubmit()}
      okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
      okText={props.supersection?.id ? t("saveChanges") : t("create")}
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input placeholder={t("name")} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onFormSubmit();
            }
          }} />
        </Form.Item>

        <Form.Item
          label="Sections"
          name="section"
        >
          <Select
            placeholder="Select sections to assign to this super section"
            showSearch
            allowClear
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) !==
              -1
            }
            onSelect={(u: any) => handleSelectSection(u)}>
            {props.sections.map(
              (u: any, k: number) => (
                <Option key={u.id} value={u.id}>
                  {`${u.name} ${u.super_section ? " (" + u.super_section.name + ")" : ""}`}
                </Option>
              )
            )}
          </Select>
          {
            sections?.length ? sections.map((u: any, k: number) => (
              <Tag key={u.id} closable onClose={() => handleRemoveSection(u.id)} style={{marginTop : 5}}>
                {`${u.name}`}
              </Tag>
            )) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SuperSectionModal;

import API from './api';

const baseURL = '/images';

const Image = {
  upload: (file: any, alt = '', description = ''): any => {
    const data = new FormData();
    data.append('image', file);
    data.append('alt', alt);
    data.append('description', description);
    console.log('uploading an image :', baseURL, data);
    return API.post(`${baseURL}`, data);
  },

  update: (id: string, body: any): any => {
    return API.put(`${baseURL}/${id}`, body);
  }
};

export default Image;

import * as React from 'react'
import mixpanel from 'mixpanel-browser'

import API from 'src/api/api'
import { AuthAPI } from 'src/api/auth'
import { LoginReq } from 'src/types/auth'
import { AuthSvc } from 'src/service/auth'
import { AuthUser } from 'src/types/user'

const AuthContext = React.createContext(null)

const AuthProvider = (props: any) => {
    const [user, setUser] = React.useState(AuthSvc.getUser())

    const login = async (req: LoginReq) => {
        try {
            let res = await AuthAPI.login(req)

            if (!res || !res.data) {
                throw new Error('Bad response')
            }

            const { data } = res

            AuthSvc.setToken(data.api_token)
            AuthSvc.setRefreshToken(data.refresh_token)
            API.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${data.api_token}`
            const u : any = AuthSvc.mapUserResponseToAuthUser(data)
            setUser(u)
            AuthSvc.setUser(u)

            mixpanel.identify(u.id)
            mixpanel.people.set(u)
            
            mixpanel.track('Login')            

            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const updateUser = (newUser: AuthUser) => {
        setUser(newUser)
    }

    const logout = () => {
        AuthSvc.removeToken()
        AuthSvc.removeRefreshToken()
        AuthSvc.removeUser()
    }

    return (
        <AuthContext.Provider
            value={{ user, login, logout, updateUser }}
            {...props}
        />
    )
}

const useAuth = (): any => {
    return React.useContext(AuthContext)
}

export { AuthProvider, useAuth }

import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { Tooltip } from 'antd';
// import {
//   // SnippetsOutlined,
//   FieldTimeOutlined,
//   CloudUploadOutlined,
//   // QuestionCircleOutlined,
// } from '@ant-design/icons';

// import HelpDrawer from '../Drawers/Help';
import './style.scss';

const DiseaseCreationHeader = (props: any) => {
  // const { t } = useTranslation();

  // const [isHelpVisible, setIsHelpVisible] = React.useState(false);

  // const handleShowAction = (action: string) => {
  //   switch (action) {
  //     case 'help':
  //       setIsHelpVisible(false);
  //       setTimeout(() => {
  //         setIsHelpVisible(true);
  //       });
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    <div className='disease-creation-header'>
      <div className='container'>
        <div className='disease-creation-header-title'>
          {props.children}
        </div>
        <div className='disease-creation-header-actions'>
          {/* <Tooltip
            title={`${t('diseaseCreation.notesTooltip')} - COMING SOON`}
            placement='bottom'>
            <div onClick={() => handleShowAction('notes')}>
              <SnippetsOutlined />
              <div>{t('diseaseCreation.notes')}</div>
            </div>
          </Tooltip> */}

          {/* <Tooltip
            title={`${t('diseaseCreation.historyTooltip')} - COMING SOON`}
            placement='bottom'> */}
            {/* <div onClick={() => handleShowAction('history')}> */}
            {/* <div>
              <FieldTimeOutlined />
              <div>{t('diseaseCreation.history')}</div>
            </div> */}
          {/* </Tooltip> */}

          {/* <Tooltip
            title={t('diseaseCreation.saveProgressTooltip')}
            placement='bottom'>
            <div onClick={() => handleShowAction('saveProgress')}>
              <CloudUploadOutlined />
              <div>{t('diseaseCreation.saveProgress')}</div>
            </div>
          </Tooltip> */}

          {/* <Tooltip title={t('diseaseCreation.helpTooltip')} placement='bottom'>
            <div onClick={() => handleShowAction('help')}>
              <QuestionCircleOutlined />
              <div>{t('diseaseCreation.help')}</div>
            </div>
          </Tooltip> */}
        </div>
      </div>

      {/* Drawers */}
      {/* <HelpDrawer isVisible={isHelpVisible} /> */}
    </div>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
  return prevProps.title === nextProps.title;
};

export default React.memo(DiseaseCreationHeader, arePropsEqual);

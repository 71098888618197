import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, message } from 'antd'

import ListViewHeader from 'src/components/common/list-view/header'
import UsersAPI from 'src/api/users'
import { User } from 'src/types'
import withPrimaryLayout from 'src/components/layout/primaryLayout'

const StatsPage = () => {
    const { t } = useTranslation()
    const fetchUsersErrorMsg = t('users.fetchError')

    const [users, setUsers] = useState([])
    const [isUsersLoading, setUsersLoading] = useState<boolean>(false)

    const getUsers = useCallback(async () => {
        setUsersLoading(true)
        try {
            const res = await UsersAPI.stats()

            if (!res || !res.data || res.status !== 200 || !res.data) {
                throw new Error('Bad response')
            }

            setUsers(res.data)
        } catch (error) {
            message.error(fetchUsersErrorMsg)
        } finally {
            setUsersLoading(false)
        }
    }, [fetchUsersErrorMsg])

    useEffect(() => {
        getUsers()
    }, [getUsers])

    const renderUsersList = () => {
        const columns = [
            {
                title: '',
                render: (val: string, record: any) => record.index,
            },
            {
                title: t('users.table.columns.name'),
                dataIndex: 'name',
                key: `name`,
                render: (val: string, record: User) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {`${record.first_name} ${record.last_name}`}
                    </div>
                ),
            },
            {
                title: t('users.table.columns.email'),
                dataIndex: 'email',
                key: `email`,
                render: (val: string) => val,
            },
            {
                title: 'Created',
                dataIndex: 'created_diseases_count',
                key: `created_diseases_count`,
                render: (val: number) => val || '0',
            },
            {
                title: 'Assigned',
                dataIndex: 'assigned_diseases_count',
                key: `assigned_diseases_count`,
                render: (val: number) => val || '0',
            },
            {
                title: 'Total',
                dataIndex: 'role',
                key: `role`,
                render: (val: number, record: any) => <div>
                    {record.created_diseases_count || 0 + record.assigned_diseases_count || 0}
                </div>,
            },
        ]

        return (
            <Table
                loading={isUsersLoading}
                dataSource={users}
                columns={columns}
                pagination={false}
                rowKey={(record: User) => record.id}
            />
        )
    }

    return (
        <div className="page users">
            <div className="container">
                <ListViewHeader title={t('stats')} isLoading={isUsersLoading} hideSearch hideButton />
                <div className="page-content">{renderUsersList()}</div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(StatsPage)

import API from './api';
import { KeywordGroup } from 'src/types';

const baseURL = '/keyword_groups';

const KeywordGroups = {
  list: (page?: number, searchQuery?: string): any => {
    let qp = page ? `?page=${page}` : '';
    if (searchQuery) {
      if (qp) {
        qp += '&';
      } else {
        qp += '?';
      }
      qp += `q=${searchQuery}`;
    }
    return API.get(`${baseURL}${qp}`);
  },

  findById(id: number) {
    return API.get(`${baseURL}/${id}`);
  },

  save(keywordGroup: KeywordGroup) {
    if (keywordGroup.id) {
      return API.put(`${baseURL}/${keywordGroup.id}`, { ...keywordGroup });
    } else {
      return API.post(`${baseURL}`, { ...keywordGroup });
    }
  },

  delete(keywordGroupId: number) {
    return API.delete(`${baseURL}/${keywordGroupId}`)
  }
};

export default KeywordGroups;

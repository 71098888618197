import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Select, Tag, Typography, message } from 'antd';

import WizardTabsAPI from 'src/api/wizardTabs';
import KeywordGroupsAPI from 'src/api/keyword-groups';
import WizardStepsAPI from 'src/api/wizardSteps';
import { KeywordGroup } from 'src/types/keywordGroup';

const { Option } = Select;
const { Text } = Typography;

const TabKeywords = (props : any) => {
  const { t } = useTranslation();

  const [isLoadingTabs, setIsLoadingTabs] = React.useState(false);
  const [tabs, setTabs] = React.useState<any[]>([]);

  const [isLoadingKeywordGroups, setIsLoadingKeywordGroups] = React.useState(
    false
  );
  const [keywordGroups, setKeywordGroups] = React.useState([]);

  // Get form/wizard tabs
  React.useEffect(() => {
    const getTabs = async () => {
      setIsLoadingTabs(true);

      try {
        const res = await WizardStepsAPI.list();

        if (!res || !res.data || res.status !== 200 || !res.data) {
          throw new Error('Bad response');
        }

        let steps = res.data;
        let tabs = getTabsFromSteps(steps);
        tabs.map((tab: any, i: number) => (tab.index = i));
        setTabs(tabs);
      } catch (error) {
        message.error(t('settings.errorGettingTabs'));
      } finally {
        setIsLoadingTabs(false);
      }
    };

    const getKeywordGroups = async () => {
      setIsLoadingKeywordGroups(true);

      try {
        const res = await KeywordGroupsAPI.list();

        if (!res || !res.data || res.status !== 200 || !res.data) {
          throw new Error('Bad response');
        }

        setKeywordGroups(res.data);
      } catch (error) {
        message.error(t('keywordGroups.fetchError'));
      } finally {
        setIsLoadingKeywordGroups(false);
      }
    };

    getTabs();
    getKeywordGroups();
    // eslint-disable-next-line
  }, []);

  const handleAttachGroup = async (tabID: number, kgID: number, options? : any) => {
    const tabIndex = tabs.findIndex((s: any) => s.id === tabID);

    if (tabIndex === -1) {
      return;
    }

    let kgIndex = 0;
    const tabKeywordGroupIDs = tabs[tabIndex].keyword_groups ? tabs[tabIndex].keyword_groups.map((kg : KeywordGroup) => kg.id) || [] : [];
    if (tabKeywordGroupIDs) {
      kgIndex = tabKeywordGroupIDs.findIndex(
        (id: number) => id === kgID
      );

      if (kgIndex !== -1) {
        // Keyword Group already attached
        return;
      }      
    }

    tabKeywordGroupIDs.push(kgID);

    try {
      const res = await WizardTabsAPI.setKeywordGroups(tabID, tabKeywordGroupIDs);

      if (!res || res.status !== 200) {
        throw new Error('Bad response');
      }

      const kg = keywordGroups.find((kg: KeywordGroup) => kg.id === kgID);

      if (!kg) {
        return;
      }

      let updatedTabs = [...tabs];
      const updatedGroups = tabKeywordGroupIDs.map((id: number) => keywordGroups.find((kg: KeywordGroup) => kg.id === id));
      updatedTabs[tabIndex].keyword_groups = updatedGroups;

      setTabs(updatedTabs);
      message.success(t('settings.attachKeywordGroupSuccess'));
    } catch (error) {
      message.error(t('settings.attachKeywordGroupError'));
    } finally {
      setIsLoadingTabs(false);
    }
  };

  const handleDetachGroup = async (tabID: number, kgID: number) => {
    const tabIndex = tabs.findIndex((s: any) => s.id === tabID);
    if (tabIndex === -1) {
      return;
    }

    let kgIndex = 0;
    const tabKeywordGroupIDs = tabs[tabIndex].keyword_groups ? tabs[tabIndex].keyword_groups.map((kg : KeywordGroup) => kg.id) || [] : [];
    if (tabKeywordGroupIDs) {
      kgIndex = tabKeywordGroupIDs.findIndex(
        (id: number) => id === kgID
      );

      if (kgIndex === -1) {
        // Keyword Group already detached
        return;
      }      
    }

    tabKeywordGroupIDs.splice(kgIndex, 1);

    try {
      const res = await WizardTabsAPI.setKeywordGroups(tabID, tabKeywordGroupIDs);

      if (!res || res.status !== 200) {
        throw new Error('Bad response');
      }

      let updatedTabs = [...tabs];
      const updatedGroups = tabKeywordGroupIDs.map((id: number) => keywordGroups.find((kg: KeywordGroup) => kg.id === id));
      updatedTabs[tabIndex].keyword_groups = updatedGroups;
      setTabs(updatedTabs);
      message.success(t('settings.detachKeywordGroupSuccess'));
    } catch (error) {
      message.error(t('settings.detachKeywordGroupError'));
    } finally {
      setIsLoadingTabs(false);
    }
  };

  const renderTabs = (tabs: any[]) => {
    // const keywordGroupsFiltered = (skg: KeywordGroup[]) => {
    //   if (!skg || !skg.length) {
    //     return keywordGroups;
    //   }

    //   return keywordGroups.filter(
    //     (kg: KeywordGroup) =>
    //       skg.findIndex((skgKG: KeywordGroup) => skgKG.id === kg.id) === -1
    //   );
    // };
    // TODO: figure out the best way to do the above

    const keywordGroupsFiltered = keywordGroups;

    const res =
      !tabs || !tabs.length ? (
        <div>{t('settings.noTabs')}</div>
      ) : (
        <div>
          {tabs.map((s: any, k: number) => (
            <div key={k} className='step'>
              <h3>{s.name}</h3>
              <Select
                style={{ width: 300 }}
                placeholder={t('keywordGroups.select')}
                onSelect={(kgID: any) =>
                  handleAttachGroup(s.id, kgID)
                }
                showSearch
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }>
                {keywordGroupsFiltered.map((kg: KeywordGroup, k: number) => (
                  <Option key={k} value={kg.id}>
                    {kg.name}
                  </Option>
                ))}
              </Select>

              {s.keyword_groups && s.keyword_groups.length ? (
                <div className='m-t-4'>
                  {s.keyword_groups.map((skg: KeywordGroup, k: number) => (
                    <Tag
                      key={k}
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        handleDetachGroup(s.id, skg.id);
                      }}>
                      {skg.name}
                    </Tag>
                  ))}
                </div>
              ) : (
                <div className='m-t-2'>
                  <Text type='secondary'>No keyword groups for this step</Text>
                </div>
              )}
            </div>
          ))}
        </div>
      );

    return res;
  };

  return (
    <div className='settings-pane settings-pane-keywords'>
      <h2>Tab Keyword Groups</h2>
      <section>
        {isLoadingTabs || isLoadingKeywordGroups ? (
          <Spin />
        ) : (
          renderTabs(tabs)
        )}
      </section>
    </div>
  );
};

export default TabKeywords;


const getTabsFromSteps = (steps : any[]) => {
  if (!steps || !steps.length) {
    return [];
  }

  let tabs : any[] = [];
  steps.map(step => tabs = tabs.concat(step.tabs));
  tabs = tabs.sort((a : any, b : any) => a.name.localeCompare(b.name));
  return tabs;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import CropperModal from 'src/components/common/modals/cropper-modal';
import './style.scss';

type Props = {
  onSetImage: any;
};

const ImageUpload = (props: Props) => {
  const { t } = useTranslation();
  const { onSetImage } = props;

  const [loadedImgSrc, setLoadedImgSrc] = useState<any>(null);
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false);
  const [originalFileName, setOriginalFileName] = useState('');

  const handleCloseModal = () => {
    setIsCropperModalOpen(false);
  };

  const onSelectImage = (e: any) => {
    if (e.target.files.length === 0) {
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setLoadedImgSrc(reader?.result);
      setIsCropperModalOpen(true);
    };

    reader.readAsDataURL(e.target.files[0]);
    setOriginalFileName(e.target.files[0].name);
  };

  return (
    <>
      <div className='upload-area'>
        <Button type='primary' shape='round'>
          {t('accountSettings.selectPicture')}
        </Button>
        <input
          id='account-image-id'
          onChange={onSelectImage}
          accept='jpg, jpeg, png'
          type='file'
        />
      </div>

      <CropperModal
        isModalOpen={isCropperModalOpen}
        onCloseModal={handleCloseModal}
        onUpload={onSetImage}
        originalFileName={originalFileName}
        imgSrc={loadedImgSrc}
      />
    </>
  );
};

export default ImageUpload;

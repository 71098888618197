import API from "./api";
import { ApiResponse, Chapter, Section } from "src/types";
import { AxiosResponse } from "axios";

const baseURL = "/sections";

const Sections = {
  list: (
    page?: number,
    searchQuery?: string
  ): Promise<AxiosResponse<ApiResponse<Section[]>>> => {
    let qp = page ? `?page=${page}` : "";
    if (searchQuery) {
      if (qp) {
        qp += "&";
      } else {
        qp += "?";
      }
      qp += `q=${searchQuery}`;
    }
    return API.get(`${baseURL}${qp}`);
  },

  listChapters: (
    id: number,
    page?: number
  ): Promise<AxiosResponse<ApiResponse<Chapter[]>>> => {
    return API.get(`${baseURL}/${id}/chapters${page ? `?page=${page}` : ""}`);
  },

  save(section: Section) {
    if (section.id) {
      return API.put(`${baseURL}/${section.id}`, { ...section });
    } else {
      return API.post(`${baseURL}`, { ...section });
    }
  },

  delete: (id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
    return API.delete(`${baseURL}/${id}`);
  },

  detail: (id: number): Promise<AxiosResponse<ApiResponse<Section>>> => {
    return API.get(`${baseURL}/${id}`);
  },
};

export default Sections;

import API from './api'
import { LoginReq, CreatePasswordReq } from 'src/types/auth'

const baseURL = '/auth'

export const AuthAPI = {
    login: (req: LoginReq): any => {
        return API.post(`${baseURL}/login`, req)
    },

    activate: (req: CreatePasswordReq): Promise<any> => {
        return API.post(`${baseURL}/activate`, req)
    },

    resetPassword: (req: CreatePasswordReq): Promise<any> => {
        return API.post(`${baseURL}/reset-password`, req)
    },    

    refreshToken: (refreshToken: string): Promise<any> => {
        return API.post(`${baseURL}/refresh-token`, { refresh_token: refreshToken })
    }
}

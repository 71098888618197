import { useDisease } from 'src/context/disease';

const useDescription = (): any => {
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const setDiseaseForm: any = disease.setDisease;

  const setDescription = (tabID: number, d: string) => {
    let df = diseaseForm || {
      tabs : {}
    };

    if (!df.tabs[tabID]) {
      df.tabs[tabID] = {};
    }

    df.tabs[tabID].description = d;
    setDiseaseForm({
      ...df,
    });
  };

  return setDescription;
};

export default useDescription;

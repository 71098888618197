import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Steps, Button, Modal, Select, message, Tag } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { UsersAPI, DiseasesAPI } from 'src/api'
import { useAuth } from 'src/context/auth'
import { getRole } from 'src/util/roles'
import './style.scss'

const { Step } = Steps
const { confirm } = Modal
const { Option } = Select

const SideNavigation = (props: any) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [users, setUsers] = useState<any>([])
    const [diseaseEditors, setDiseaseEditors] = useState<any>([])

    const auth = useAuth()
    const user = auth.user
    const isEditor = user.roleID <= 20
    const isAdmin = user.roleID <= 10

    const getUsers = useCallback(async () => {
        try {
            const result = await UsersAPI.list()
            if (!result || result.status !== 200) {
                throw new Error('Bad response')
            }

            if (result?.data) {
                const assignableUsers = result.data.filter((user: any) =>
                    ['Super Admin', 'Admin', 'Editor', 'Author'].includes(getRole(user.role))
                )
                setUsers(assignableUsers)
            }
        } catch (error) {
            console.error(error)
            message.error(t('errors.apiCommunication', { api: 'users' }))
        }
    }, [t])

    useEffect(() => {
        if (isAdmin) {
            getUsers()
            setDiseaseEditors(props.disease?.assigned_users || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUsers, isAdmin])

    const handleCancel = () => {
        confirm({
            title: 'Do you want to cancel editing this disease?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Continue editing',
            okText: 'Yes, cancel editing',
            onOk() {
                navigate('/diseases')
            },
        })
    }

    const handleDelete = () => {
        confirm({
            title: 'Do you want to delete this disease?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes, delete',
            onOk() {
                props.onDelete()
            },
        })
    }

    const handleSubmit = () => {
        props.onSubmit()
    }

    const handleSelectUser = async (uID: any) => {
        if (!props.disease?.id || !uID) {
            message.error(t('chapters.errorAssigning'))
            return
        }

        const user = users.find((u: any) => u.id === uID)
        if (!user) {
            message.error(t('chapters.errorAssigning'))
            return
        }

        const ce = diseaseEditors
        if (ce.find((ce: any) => ce.id === user.id)) return

        ce.push(user)
        setDiseaseEditors(JSON.parse(JSON.stringify(ce)))

        const assignResult = await DiseasesAPI.assign(props.disease.id, user.id)
        if (!assignResult || assignResult.status !== 200) {
            message.error(t('chapters.errorAssigning'))
            return
        }
        message.success(t('chapters.assigned'))
    }

    const handleRemoveUser = async (user: any) => {
        if (!props.disease?.id || !user.id) {
            message.error(t('chapters.errorUnAssigning'))
            return
        }

        const ce = diseaseEditors.filter((u: any) => u.id !== user.id)
        setDiseaseEditors(ce)

        const unassignResult = await DiseasesAPI.unassign(props.disease.id, user.id)
        if (!unassignResult || unassignResult.status !== 200) {
            message.error(t('chapters.errorUnAssigning'))
        }
        message.success(t('chapters.unassigned'))
    }

    const renderCancel = () => {
        return (
            <Button
                type="default"
                block
                onClick={handleCancel}
                style={{
                    padding: '8px 10px',
                    height: 'auto',
                    fontSize: '14px',
                    marginBottom: 20,
                    color: '#000',
                    backgroundColor: '#fff',
                }}
            >
                Cancel Editing
            </Button>
        )
    }

    const renderSave = () => {
        return (
            <React.Fragment>
                {props.mode !== 'view' ? (
                    <Button
                        type="primary"
                        block
                        disabled={props.isSubmitDisabled}
                        onClick={handleSubmit}
                        style={{ padding: '8px 10px', height: 'auto' }}
                    >
                        {props.mode === 'create' ? t('diseaseCreation.save') : t('diseaseCreation.saveChanges')}
                    </Button>
                ) : null}
            </React.Fragment>
        )
    }

    const renderEditorial = () => {
        const showSendToReview = props.mode !== 'view' && props.disease.status === 0

        const isAssignedToDisease = props.disease?.assigned_users?.find((u: any) => u.id === user.id)
        const isAssignedToSelectedChapter = props.sectionChapters
            ?.find((c: any) => c.id === props.disease?.chapter_id)
            ?.assigned_users?.find((u: any) => u.id === user.id)

        const showPublish =
            (user.roleID < 20 || (isEditor && (isAssignedToDisease || isAssignedToSelectedChapter))) &&
            props.mode !== 'view' &&
            (props.disease.status === 1 || props.disease.status === 3)

        if (!showSendToReview && !showPublish) return null

        return (
            <div
                style={{
                    marginTop: '20px',
                    padding: '20px 0',
                    borderTop: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                }}
            >
                {showSendToReview ? (
                    <Button
                        type="default"
                        block
                        disabled={props.isSubmitDisabled}
                        onClick={() => props.onSubmitForReview()}
                        style={{ padding: '8px 10px', height: 'auto', background: '#f0a114', color: '#fff' }}
                    >
                        Send to review
                    </Button>
                ) : null}

                {showSendToReview && showPublish ? <div style={{ marginBottom: 10 }}></div> : null}

                {showPublish ? (
                    <Button
                        type="default"
                        block
                        onClick={props.onPublish}
                        style={{
                            marginTop: 5,
                            padding: '8px 10px',
                            height: 'auto',
                            background: '#41b917',
                            color: '#fff',
                        }}
                    >
                        Publish
                    </Button>
                ) : null}
            </div>
        )
    }

    const renderDelete = () => {
        return (
            <Button
                type="default"
                danger
                block
                onClick={handleDelete}
                style={{
                    padding: '8px 10px',
                    height: 'auto',
                    fontSize: '14px',
                    marginTop: 20,
                    color: '#fff',
                    backgroundColor: '#ff4d4f',
                }}
            >
                Delete Disease
            </Button>
        )
    }

    const renderDuplicate = () => {
        return (
            <div
                style={{
                    marginTop: '20px',
                    paddingBottom: '20px',
                    borderBottom: '1px solid #ccc',
                }}
            >
                <Button
                    type="default"
                    block
                    onClick={props.onDuplicate}
                    style={{ padding: '8px 10px', height: 'auto', background: '#1ba2f6', color: '#fff' }}
                >
                    Duplicate
                </Button>
            </div>
        )
    }

    const renderEdit = () => {
        return (
            <div
                style={{
                    marginTop: '20px',
                    paddingBottom: '20px',
                    borderBottom: '1px solid #ccc',
                }}
            >
                <Button
                    type="default"
                    block
                    onClick={props.onEditPublished}
                    style={{ padding: '8px 10px', height: 'auto' }}
                >
                    Edit
                </Button>

                <small>
                    Disease is <strong>Published and read-only</strong>.<br></br> Click "Edit" above to create a new
                    Draft version.
                </small>
            </div>
        )
    }

    const renderActionsForPublished = () => {
        return (
            <>
                {renderEdit()}
                {props.mode !== 'create' ? renderDuplicate() : null}
                {renderDelete()}
            </>
        )
    }

    const getStepTitle = (step: any) => {
        if (!step) return null

        if (step.name === 'IHC and Molecular') {
            return 'Additional methods'
        }

        return step.name
    }

    const renderChapterEditors = () => {
        const chapter = props.sectionChapters?.find((c: any) => c.id === props.disease?.chapter_id)

        if (!chapter) return <div>No chapter</div>

        const cu = chapter?.assigned_users

        if (!cu?.length) return <div>No assigned editors</div>

        return (
            <div>
                {cu.map((u: any, k: number) => (
                    <Tag key={u.id} style={{ marginTop: 5 }}>
                        {`${u.first_name} ${u.last_name}`}
                    </Tag>
                ))}
            </div>
        )
    }

    const renderEditors = () => {
        return (
            <div>
                <div style={{ marginTop: 10 }}>Editors</div>
                <div
                    style={{
                        marginTop: 10,
                        padding: 12,
                        borderTop: '1px solid #eee',
                        background: '#fff',
                        border: '1px solid #dedede',
                        borderRadius: 6,
                    }}
                >
                    <strong>Assigned to Chapter</strong>
                    {renderChapterEditors()}
                </div>

                <div
                    style={{
                        marginTop: 10,
                        padding: 12,
                        borderTop: '1px solid #eee',
                        background: '#fff',
                        border: '1px solid #dedede',
                        borderRadius: 6,
                    }}
                >
                    <div>
                        <strong>Assigned to Disease</strong>

                        <Select
                            placeholder="Select users to assign as editors"
                            showSearch
                            allowClear
                            style={{ width: '100%', marginTop: 5 }}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }
                            onSelect={(u: any) => handleSelectUser(u)}
                        >
                            {users.map((u: any, k: number) => (
                                <Option key={k} value={u.id}>
                                    {`${u.first_name} ${u.last_name} | ${u.email} | ${getRole(u.role)}`}
                                </Option>
                            ))}
                        </Select>
                        {diseaseEditors?.length
                            ? diseaseEditors.map((u: any, k: number) => (
                                  <Tag key={u.id} closable onClose={() => handleRemoveUser(u)} style={{ marginTop: 5 }}>
                                      {`${u.first_name} ${u.last_name}`}
                                  </Tag>
                              ))
                            : null}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            position: 'sticky',
            top: 0,
            paddingBottom: 10,
        }}>
            <Steps direction="vertical" size="small" current={props.activeStep.index}>
                {props.steps.map((s: any, i: number) => (
                    <Step key={i} title={getStepTitle(s)} onClick={() => props.onShowStep(s, i)} />
                ))}
            </Steps>

            {props.disease.status === 4 ? (
                renderActionsForPublished()
            ) : (
                <>
                    {props.mode !== 'create' ? renderCancel() : null}
                    {renderSave()}
                    {renderEditorial()}

                    {isAdmin && props.mode !== 'create' ? renderEditors() : null}
                    {props.mode !== 'create' ? renderDelete() : null}
                </>
            )}
        </div>
    )
}

export default SideNavigation

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import { Section } from 'src/types/section';

const { Option } = Select;

const SectionSelector = (props: any) => {
  const { t } = useTranslation();
  const { sections, selected, onSelect, disabled } = props;

  return (
    <Select
      placeholder={t('sections.select')}
      disabled={disabled}
      value={selected}
      onSelect={onSelect}
      showSearch
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {sections.map((s: Section, k: number) => (
        <Option key={k} value={s.id}>
          {s.name}
        </Option>
      ))}
    </Select>
  );
};

export default SectionSelector;

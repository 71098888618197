interface AppRoute {
    route: string // Actual route
    key: string // Intl key for translation files
    regexp?: RegExp // RegExp needed for dynamic routes (see src/components/layout/breadCrumbs/index.tsx)
}

export const protectedRoutes = {
    root: {
        route: '/',
        key: 'routes.root',
    } as AppRoute,
    myContribution: {
        route: '/my-contribution',
        key: 'routes.myContribution',
    } as AppRoute,
    diseases: {
        route: '/diseases',
        key: 'routes.diseases.index',
    } as AppRoute,
    account: {
        route: '/account',
        key: 'routes.account.index',
    } as AppRoute,
    keywordGroups: {
        index: {
            route: '/keyword-groups',
            key: 'routes.keywordGroups.index',
        } as AppRoute,
        keywordGroup: {
            route: '/keyword-groups/:keywordGroupId/keywords',
            key: 'routes.keywordGroups.keywords',
            regexp: /\/keyword-groups\/\d{1,}\/keywords/,
        } as AppRoute,
    },
    diseaseCreation: {
        index: {
            route: '/create-disease',
            key: 'routes.diseaseCreation.createDisease',
        } as AppRoute,
    },
    diseaseEdit: {
        index: {
            route: '/edit-disease/:id',
            key: 'routes.diseaseEdit.editDisease',
        } as AppRoute,
    },
    settings: {
        index: {
            route: '/settings',
            key: 'routes.settings.tabKeywords',
        } as AppRoute,
        keywords: {
            route: '/settings/disease/tab-keywords',
            key: 'routes.settings.tabKeywords',
        } as AppRoute,
    },
    sections: {
        index: {
            route: '/sections',
            key: 'routes.sections.index',
        } as AppRoute,
        chapters: {
            route: '/sections/:sectionId/chapters',
            key: 'routes.sections.chapters',
            regexp: /\/sections\/\d{1,}\/chapters/,
        } as AppRoute,
    },
    advancedSearch: {
        index: {
            route: '/search',
            key: 'routes.advancedSearch.index',
        } as AppRoute,
    },
    users: {
        route: '/users',
        key: 'routes.users.index',
    } as AppRoute,
    adminDiseases: {
        route: '/admin-disease',
        key: 'routes.adminDiseases.index',
    } as AppRoute,
    stats: {
        route: '/stats',
        key: 'routes.stats.index',
    } as AppRoute,
    supersections: {
        route: "/supersections",
        key: "routes.supersections.index",
    } as AppRoute,
}

export const publicRoutes = {
    login: {
        route: '/login',
        key: 'routes.login',
    } as AppRoute,
    unauthorized: {
        route: '/unauthorized',
        key: 'routes.unauthorized',
    } as AppRoute,
    createPassword: {
        route: '/activate',
        key: 'routes.activate',
    } as AppRoute,
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'

import { Validate } from 'src/util/validation'
import { LoginReq } from 'src/types/auth'
import './style.scss'

const validateInput = (input: LoginReq): string | undefined => {
    const { email } = input
    const emailValidation = Validate.email(email)
    if (!!emailValidation) return emailValidation
}

type Props = {
    loading: boolean
    onSubmit: (credentials: LoginReq) => void
}

const LoginForm = (props: Props) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMessage, setErrMessage] = useState('')

    const isSubmitDisabled: boolean = props.loading || !(email && password)

    const handleSubmit = () => {
        const errMsg = validateInput({ email, password })

        if (errMsg) {
            setErrMessage(errMsg)
            return
        }

        props.onSubmit({ email, password })
    }

    return (
        <Form
            name="login"
            layout="vertical"
            onFinish={handleSubmit}
            className="login-form"
        >
            <Form.Item
                name="email"
                rules={[{ required: true, message: t('loginForm.enterEmail') }]}
            >
                <Input
                    placeholder="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    style={{ borderRadius: 6 }}
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    { required: true, message: t('loginForm.enterPassword') },
                ]}
                style={{ marginTop: '30px' }}
            >
                <Input.Password
                    placeholder="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    style={{ borderRadius: 6 }}
                />
            </Form.Item>

            <Form.Item style={{ marginTop: '30px', marginBottom: '0' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={isSubmitDisabled}
                    style={{ borderRadius: 6 }}
                >
                    Login
                </Button>
            </Form.Item>

            {errMessage && <p>{errMessage}</p>}
        </Form>
    )
}

export default LoginForm

import API from "./api";
import { AuthUser as AccountReq } from "src/types/user";

const baseURL = "/users";

const Account = {
  update: (req: AccountReq): any => {
    return API.put(`${baseURL}/${req?.id}`, req);
  },
};

export default Account;

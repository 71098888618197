import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Form, Row, Col, Checkbox } from 'antd';
import ReactQuill from 'react-quill';

import ImagesSelector from 'src/pages/diseaseCreation/components/Form/ImagesSelector';
import TabKeywordGroups from 'src/pages/settings/components/TabKeywords';
import useDescription from 'src/hooks/diseaseCreation/useDescription';
import useTabs from 'src/hooks/diseaseCreation/useTabs';
import useImages from 'src/hooks/diseaseCreation/useImages';
import { useDisease } from 'src/context/disease';
import { editorConfig } from 'src/util/wysiwyg';

const { TabPane } = Tabs;

type Props = {
  step: any;
};

const checkboxOptions = [
  'positive',
  'negative',
  'nuclear',
  'membranous',
  'cytoplasmic',
  'scattered',
  'focal',
  'diffuse',
];

const Immunophenotype = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease ? disease.disease : { tabs : {} };
  const setDiseaseForm: any = disease.setDisease;

  const setDescription = useDescription();
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);
  const [addImage, editImage, removeImage, saveImageOrder] = useImages(props.step);

  const handleToggleOption = (selectedOptions : any) => {
    setDiseaseForm({
      ...diseaseForm,
      immunophenotype_options : selectedOptions
    });
  }

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>
        <TabPane tab={t('diseaseCreation.form.immunophenotype')} key='0'>
          <TabKeywordGroups tab={props.step} stepKey='immunophenotype' />

          <Row>
            <Col span={24}>
              <Form.Item label={t('diseaseCreation.form.checkAllThatApply')}>
                <Checkbox.Group style={{ width: '50%' }} onChange={handleToggleOption}>
                  <Row style={{ marginBottom: 10 }}>
                    {checkboxOptions.map((option, k) => (
                      <Col span={6} key={k}>
                        <Checkbox
                          value={option}
                          checked={diseaseForm?.immunophenotype_options?.includes(option)}
                          style={{
                            textTransform: 'capitalize'
                          }}>
                          {option}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t('diseaseCreation.form.images')}>
                <ImagesSelector
                  images={diseaseForm.tabs ? diseaseForm.tabs[props.step.id]?.images : []}
                  diseaseMode={diseaseForm.form_mode}
                  onAdd={addImage}
                  onEdit={editImage}
                  onRemove={removeImage}
                  onSaveOrder={saveImageOrder}
                />
              </Form.Item>
            </Col>

            <Col span={20}>
              <Form.Item label={t('diseaseCreation.form.IHCDescription')}>
                <ReactQuill
                  theme='snow'
                  formats={editorConfig.formats}
                  modules={editorConfig.modules}
                  defaultValue={diseaseForm?.immunophenotype || ''}
                  onChange={setDescription}
                  placeholder={t('diseaseCreation.form.IHCPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Immunophenotype;

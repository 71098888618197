import * as React from 'react';
import { Layout } from 'antd';

import './style.scss';

const { Content } = Layout;

const PublicLayout: React.FC = (props: any) => {
  return (
    <Content className='public-layout'>
      {props.children}
    </Content>
  );
};

export default PublicLayout;

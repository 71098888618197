import { createSearchParams, URLSearchParamsInit } from 'react-router-dom'

type SearchQueryBuilderProps = {
    searchQuery: string
    page?: number
    section_id?: number
    chapter_id?: number
}

export const qp = ({ searchQuery, page, section_id, chapter_id }: SearchQueryBuilderProps) => {
    let queryParam: URLSearchParamsInit = ''
    const trimmedSearchQuery = searchQuery.trim()
    if (!!trimmedSearchQuery || page || section_id || chapter_id) {
        queryParam = `?${createSearchParams({
            q: trimmedSearchQuery,
            page: String(page) ?? '1',
            section_id: String(section_id) ?? '',
            chapter_id: String(chapter_id) ?? '',
        })}`
    }
    return queryParam
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
};

const TreatmentPrognosis = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {/* Treatment */}
        <TabPane
          key={0}
          tab={props.tabs[30].name}
          closable={false}>
          <Tab
            tab={props.tabs[30]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.TreatmentPrognosisPlaceholder')}
          />
        </TabPane>

        {/* Prognosis */}
        <TabPane
          key={1}
          tab={props.tabs[31].name}
          closable={false}>
          <Tab
            tab={props.tabs[31]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.etiologyAndPathogenesisPlaceholder')}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TreatmentPrognosis;

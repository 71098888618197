import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthSvc } from 'src/service/auth'
import { publicRoutes } from 'src/router/routes'

interface Props {
    element: ReactElement
    roles?: string[]
}

export const AuthenticatedRoute = ({ element, roles = [] }: Props): JSX.Element => {
    const user = AuthSvc.getUser()
    const isAuthenticated = !!user
    let isAuthorized = true

    if (roles.length && user && user.role) {
        isAuthorized = roles.includes(user.role)
    }

    return isAuthenticated && isAuthorized ? (
        element
    ) : (
        <Navigate
            to={{
                pathname: !isAuthenticated
                    ? publicRoutes.login.route
                    : publicRoutes.unauthorized.route,
            }}
        />
    )
}

import React, { useCallback, useEffect, useState } from 'react'
import { Button, message, Popconfirm, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import withPrimaryLayout from 'src/components/layout/primaryLayout'
import ListViewHeader from 'src/components/common/list-view/header'

import { DiseasesAPI } from 'src/api'
import { Disease } from 'src/types'
import { getDiseaseStatus } from 'src/util/diseases'
import { qp } from 'src/util/url'

const AdminDiseases = () => {
    const { t } = useTranslation()
    const fetchDiseasesErrorMsg = t('diseases.fetchError')
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '')
    const [currentPage, setCurrentPage] = useState(
        Number(searchParams.get('page') || 1)
    )
    const [isDataLoading, setDiseasesLoading] = useState<boolean>(false)
    const [diseases, setDiseases]: [Array<Disease>, any] = useState([])
    const [totalDiseases, setTotalDiseases] = useState(0)

    const memoizedGetDiseases = useCallback(
        async (page: number, searchQuery?: string) => {
            setDiseasesLoading(true)
            try {
                const res = await DiseasesAPI.list({
                    page,
                    q: searchQuery || searchParams.get('q') || '',
                })
                if (
                    !res ||
                    !res.data
                ) {
                    throw new Error('Bad response')
                }

                if (res?.data) {
                    const data = res.data.map((item: Disease) => {
                        return { key: item.id, ...item }
                    })
                    setDiseases(data)
                    setTotalDiseases(parseInt(res.headers['total-items']))
                }
            } catch (error) {
                console.error(error)
                message.error(fetchDiseasesErrorMsg)
            } finally {
                setDiseasesLoading(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchDiseasesErrorMsg]
    )

    const onDeleteDisease = async (disease: Disease) => {
        try {
            const result = await DiseasesAPI.delete(disease.id)
            if (result?.data?.code === 200) {
                message.success(
                    t('diseases.diseaseDeleted', { title: disease.title })
                )
                onPageChange(1)
            }
        } catch (error) {
            console.error(error)
            message.error(t('diseases.errorDeleting'))
        }
    }

    const onPageChange = (page: number) => {
        const q = searchParams.get('q') || ''
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: q, page }),
        })
        setCurrentPage(page)
        if (page === currentPage) {
            memoizedGetDiseases(page)
        }
    }

    const onSearch = (searchQuery: string) => {
        onPageChange(1)
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery }),
        })
        setSearchQuery(searchQuery.trim())
    }

    useEffect(() => {
        memoizedGetDiseases(currentPage, searchQuery)
    }, [memoizedGetDiseases, currentPage, searchQuery])

    const renderDiseasesList = () => {
        const diseasesPagination: TablePaginationConfig = {
            pageSize: 30,
            total: totalDiseases,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: onPageChange,
        }

        const columns = [
            {
                title: t('diseases.table.columns.title'),
                dataIndex: 'title',
                key: 'title',
                width: 300,
                ellipsis: {
                    showTitle: false,
                },
                render: (title: string) => (
                    <Tooltip placement="topLeft" title={title}>
                        {title}
                    </Tooltip>
                ),
            },
            {
                title: t('diseases.table.columns.user'),
                dataIndex: 'user',
                key: 'user',
                render: (user: string, disease: Disease) =>
                    `${disease?.user?.first_name} ${disease?.user?.last_name}`,
            },
            {
                title: t('diseases.table.columns.createdAt'),
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at: string) =>
                    new Date(created_at).toLocaleString(),
            },
            {
                title: t('diseases.table.columns.updatedAt'),
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (updated_at: string) =>
                    new Date(updated_at).toLocaleString(),
            },
            {
                title: t('diseases.table.columns.status'),
                dataIndex: 'status',
                key: 'status',
                render: (status: number) => getDiseaseStatus(status, t),
            },
            {
                title: 'Edit/Delete',
                key: 'action',
                align: 'right' as const,
                render: (value: any, record: Disease) => (
                    <>
                        <Popconfirm
                            placement="topLeft"
                            title={t('diseases.deletePopup', {
                                title: record.title,
                            })}
                            onConfirm={() => onDeleteDisease(record)}
                            okText={t('yes')}
                            cancelText={t('no')}
                        >
                            <Button
                                danger
                                type="primary"
                                shape="round"
                                size="small"
                                style={{ marginLeft: 10 }}
                            >
                                <CloseCircleOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                ),
            },
        ]

        return (
            <Table
                pagination={diseasesPagination}
                columns={columns}
                dataSource={diseases}
                loading={isDataLoading}
            />
        )
    }

    let renderContent = () => {
        return <div>{renderDiseasesList()}</div>
    }

    return (
        <div className="page">
            <div className="container">
                <ListViewHeader
                    buttonLabel={t('diseases.add')}
                    title={t('diseases.diseases')}
                    onSearch={onSearch}
                    isLoading={isDataLoading}
                    onButtonClick={() => {
                        navigate('/create-disease')
                    }}
                />
                {renderContent()}
            </div>
        </div>
    )
}

export default withPrimaryLayout(AdminDiseases)

import API from './api'
import { ApiResponse, SuperSection } from 'src/types'
import { AxiosResponse } from 'axios'

const baseURL = '/supersections'

const SuperSections = {
  list() {
    return API.get(baseURL)
  },

  save(supersection: SuperSection) {
    if (supersection.id) {
      return API.put(`${baseURL}/${supersection.id}`, { ...supersection })
    } else {
      return API.post(`${baseURL}`, { ...supersection })
    }
  },

  delete: (supersection_id: number): Promise<AxiosResponse<ApiResponse<any>>> => {
    return API.delete(`${baseURL}/${supersection_id}`)
  },
}

export default SuperSections

import { ReactNode } from 'react'

export class CardItem {
    icon: ReactNode
    titleTranslationKey: string
    descriptionTranslationKey: string
    buttonTextTranslationKey: string
    link: string
    targetBlank: boolean

    constructor(
        icon: ReactNode,
        [titleTranslationKey, descriptionTranslationKey, buttonTextTranslationKey]: string[],
        link: string,
        targetBlank: boolean = false
    ) {
        this.icon = icon
        this.titleTranslationKey = titleTranslationKey
        this.descriptionTranslationKey = descriptionTranslationKey
        this.buttonTextTranslationKey = buttonTextTranslationKey
        this.link = link
        this.targetBlank = targetBlank
    }
}

import * as React from 'react';

import { useAuth } from 'src/context/auth';

const UserContext = React.createContext(null);

const UserProvider = (props: any) => {
  const auth = useAuth();
  const user = auth ? auth.user : null;

  return <UserContext.Provider value={user} {...props} />;
};

const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };

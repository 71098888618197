import Title from 'antd/lib/typography/Title'
import withPrimaryLayout from 'src/components/layout/primaryLayout'

const NotFound = () => {
    return (
        <div className="page">
            <div className="container">
              <Title level={2}>404 Page Not Found</Title>
            </div>
        </div>
    )
}

export default withPrimaryLayout(NotFound)

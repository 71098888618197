import { AuthUser } from 'src/types/user'
import { getRoleEnum } from 'src/util/roles'

const mapUserResponseToAuthUser = (res: any): AuthUser => {
    return {
        id: res.id,
        roleID: res.role,
        role: getRoleEnum(res.role),
        email: res.email,
        firstName: res.first_name,
        lastName: res.last_name,
        avatar: res.avatar,
    }
}

const setUser = (user: AuthUser) => {
    localStorage.setItem('pdds_auth_user', JSON.stringify(user))
}

const removeUser = () => {
    localStorage.removeItem('pdds_auth_user')
}

const getUser = (): AuthUser => {
    const user = localStorage.getItem('pdds_auth_user')
    return user ? JSON.parse(user) : null
}

const setToken = (token: string) => {
    localStorage.setItem('pdds_auth_token', JSON.stringify(token))
}

const removeToken = () => {
    localStorage.removeItem('pdds_auth_token')
}

const getToken = (): string => {
    const token = localStorage.getItem('pdds_auth_token')
    return token ? JSON.parse(token) : null
}

const setRefreshToken = (token: string) => {
    localStorage.setItem('pdds_auth_refresh_token', JSON.stringify(token))
}

const removeRefreshToken = () => {
    localStorage.removeItem('pdds_auth_refresh_token')
}

const getRefreshToken = (): string => {
    const token = localStorage.getItem('pdds_auth_refresh_token')
    return token ? JSON.parse(token) : null
}

export const AuthSvc = {
    setUser,
    getUser,
    removeUser,
    setToken,
    removeToken,
    getToken,
    mapUserResponseToAuthUser,
    setRefreshToken,
    removeRefreshToken,
    getRefreshToken,
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Form, Row, Col } from 'antd';
import ReactQuill from 'react-quill';

import ImagesSelector from 'src/pages/diseaseCreation/components/Form/ImagesSelector';
import TabKeywordGroups from 'src/pages/settings/components/TabKeywords';
import useDescription from 'src/hooks/diseaseCreation/useDescription';
import useTabs from 'src/hooks/diseaseCreation/useTabs';
import useImages from 'src/hooks/diseaseCreation/useImages';
import { useDisease } from 'src/context/disease';
import { editorConfig } from 'src/util/wysiwyg';

const { TabPane } = Tabs;

type Props = {
  step: any;
};

const Molecular = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease ? disease.disease : { tabs : {} };

  const setDescription = useDescription();
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);
  const [addImage, editImage, removeImage, saveImageOrder] = useImages(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>
        <TabPane tab={t('diseaseCreation.form.molecularFeatures')} key='0'>
          <TabKeywordGroups tab={props.step} stepKey='molecular' />

          <Row>
            <Col span={24}>
              <Form.Item label={t('diseaseCreation.form.images')}>
                <ImagesSelector
                  images={diseaseForm.tabs? diseaseForm.tabs[props.step.id]?.images : []}
                  diseaseMode={diseaseForm.form_mode}
                  onAdd={addImage}
                  onEdit={editImage}
                  onRemove={removeImage}
                  onSaveOrder={saveImageOrder}
                />
              </Form.Item>
            </Col>

            <Col span={20}>
              <Form.Item
                label={t('diseaseCreation.form.molecularFeaturesDescription')}>
                <ReactQuill
                  theme='snow'
                  formats={editorConfig.formats}
                  modules={editorConfig.modules}
                  value={diseaseForm?.molecular || ''}
                  onChange={setDescription}
                  placeholder={t(
                    'diseaseCreation.form.molecularFeaturesPlaceholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>          
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Molecular;

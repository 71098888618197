import React from 'react'
import { useParams } from 'react-router-dom'

import { DiseaseProvider } from 'src/context/disease'
import { Disease } from 'src/types'
import DiseaseFormLayout from 'src/pages/diseaseCreation/components/PageLayout'
import withPrimaryLayout from 'src/components/layout/primaryLayout'
import DiseasesAPI from 'src/api/diseases'

const CreateDiseasePage = () => {
    const { id }: any = useParams()
    const [isLoading, setIsLoading]: [boolean, any] = React.useState(true)
    const [disease, setDisease]: [object, any] = React.useState({ form_mode: 'create', tabs: {} })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getMode = (d: Disease) => {
        if (d && d.status === 1) return 'view'
        if (d.id) return 'edit'
        return 'create'
    }

    React.useEffect(() => {
        const preventUnload = (event: BeforeUnloadEvent) => {
            const message = 'Sure you want to leave?'
            event.preventDefault()
            event.returnValue = message
        }

        if (!id) {
            window.addEventListener('beforeunload', preventUnload)

            return () => {
                window.removeEventListener('beforeunload', preventUnload)
            }
        }
    }, [id])

    React.useEffect(() => {
        // Check if disease is being edited. If so, fetch it and set it.
        const getDisease = async () => {
            try {
                const res = await DiseasesAPI.get(id)
                let disease = res.data
                disease.alternative_titles_original = disease.alternative_titles
                // disease.form_mode = getMode(disease); Status is not returned currently, so set mode to edit for now
                disease.form_mode = 'edit'
                setDisease(disease)
            } catch (error) {
                console.log('Error getting disease : ', error)
                alert('Problem getting the disease')
            } finally {
                setIsLoading(false)
            }
        }

        if (id) {
            getDisease()
        } else {
            setIsLoading(false)
        }
    }, [id])

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <DiseaseProvider
            disease={{
                ...disease,
                id,
            }}
        >
            <DiseaseFormLayout
                disease={{
                    ...disease,
                    id,
                }}
            ></DiseaseFormLayout>
        </DiseaseProvider>
    )
}

export default withPrimaryLayout(CreateDiseasePage, false)

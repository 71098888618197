import { PageHeader } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import withPrimaryLayout from "src/components/layout/primaryLayout";
import DashboardNavigation from "./components/dashboardNavigation";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="page dashboard">
      <div className="container">
        <PageHeader ghost={true} title={t("dashboard")} />
        <DashboardNavigation />
      </div>
    </div>
  );
};

export default withPrimaryLayout(Dashboard);

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Pagination, Hits, Stats, useSearchBox, useHits } from 'react-instantsearch-hooks-web'
import { debounce } from 'lodash'

import withPrimaryLayout from 'src/components/layout/primaryLayout'
import ListViewHeader from 'src/components/common/list-view/header'
import './style.scss'

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID || '',
    process.env.REACT_APP_ALGOLIA_TOKEN || ''
)

const statuses: any = {
    0: 'Draft',
    1: 'Review',
    2: 'Changes requested',
    3: 'Approved',
    4: 'Published',
}

const DiseasesPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const searchBoxQueryHook = debounce(function (query, search) {
        search(query)
    }, 300)

    const handleSelect = (id: string) => {
        window.open(`edit-disease/${id}`, '_blank')
    }

    const renderSearchResult = (res: any) => {
        return (
            <div className="disease-result">
                <div className="disease-result-title">
                    <div className="disease-result-title-link" onClick={() => handleSelect(res.hit.disease_id)}>
                        {res.hit.title}
                    </div>
                    <div className="disease-result-title-alternatives">
                        {res.hit?.alternative_titles?.map((title: any, index: number) => {
                            return <div key={index}>{title}</div>
                        })}
                    </div>
                </div>
                <div className="disease-result-section">{res.hit.section}</div>
                <div className="disease-result-chapter">{res.hit.chapter}</div>
                <div className="disease-result-status">
                    <span className={`status-label status-${res.hit.status}`}>{statuses[res.hit.status]}</span>
                </div>
                <div className="disease-result-editors">{res.hit.editors}</div>
                <div className="disease-result-created">{dayjs(res.hit.created_at).format('DD.MM.YYYY')}</div>
                <div className="disease-result-updated">{dayjs(res.hit.updated_at).format('DD.MM.YYYY')}</div>
            </div>
        )
    }

    const Results = () => {
        const { query } = useSearchBox()
        const { hits } = useHits()

        // if (!query) return null

        if (query && hits.length === 0) {
            return (
                <div className="disease-results-wrapper">
                    <p
                        style={{
                            margin: 0,
                            padding: '15px 0',
                            textAlign: 'center',
                            color: '#5e5e5e',
                        }}
                    >
                        No results for <strong>{query}</strong>
                    </p>
                </div>
            )
        }

        return (
            <Hits
                hitComponent={renderSearchResult}
                classNames={{
                    root: 'disease-results-wrapper',
                    list: 'disease-results-list',
                    item: 'disease-results-item',
                }}
            />
        )
    }

    return (
        <div className="page all-diseases">
            <InstantSearch searchClient={searchClient} indexName={process.env.REACT_APP_ALGOLIA_DISEASE_INDEX_NAME}>
                <div className="container">
                    <ListViewHeader
                        buttonLabel={t('diseases.add')}
                        title={t('diseases.allDiseases')}
                        hideSearch
                        isLoading={false}
                        onButtonClick={() => {
                            navigate('/create-disease')
                        }}
                    />

                    <div className="disease-search-wrapper">
                        <SearchBox
                            classNames={{
                                root: 'search-box',
                                form: 'search-box-form',
                                input: 'search-box-input',
                                submit: 'search-box-submit',
                                reset: 'search-box-reset',
                            }}
                            queryHook={searchBoxQueryHook}
                            placeholder="Search for a disease"
                        />

                        <Stats
                            translations={{
                                stats(nbHits) {
                                    return `${nbHits.nbHits} diseases found`
                                },
                            }}
                        />
                    </div>

                    <div className="page-content">
                        <div className="disease-result-header">
                            <div className="disease-result-header-title">Title</div>
                            <div className="disease-result-header-section">Section</div>
                            <div className="disease-result-header-chapter">Chapter</div>
                            <div className="disease-result-header-status">Status</div>
                            <div className="disease-result-header-editors">Editors</div>
                            <div className="disease-result-header-created">Created</div>
                            <div className="disease-result-header-updated">Updated</div>
                        </div>

                        <Results />

                        <div className="disease-pagination-wrapper">
                            <Pagination />
                        </div>
                    </div>
                </div>
            </InstantSearch>
        </div>
    )
}

export default withPrimaryLayout(DiseasesPage)

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Modal, Select, Checkbox, Button } from 'antd'

import { User } from 'src/types'
const { Option } = Select

type Props = {
    onOk: any
    onActivateTrial: any
    onCancel: any
    isVisible: boolean
    user?: User | null
}

const UserModal = (props: Props) => {
    const { t } = useTranslation()
    const { user } = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form]: [any] = Form.useForm()

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                active: user?.active || false,
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                email: user?.email || '',
                role: user?.role || 0,
            })
        }
    }, [form, user])

    const onFormSubmit = () => {
        setIsSubmitting(true)
        form.validateFields(['first_name', 'last_name', 'email', 'role'])
            .then(() => {
                props.onOk(props.user?.id, form.getFieldsValue())
                setIsSubmitting(false)
            })
            .catch((err: any) => {
                console.error(err)
                setIsSubmitting(false)
            })
    }

    const handleActivateTrial = () => {
        props.onActivateTrial(props.user?.id)
    }

    return (
        <Modal
            visible={props.isVisible}
            title={props.user?.id ? t('users.edit') : t('users.add')}
            onCancel={() => props.onCancel()}
            onOk={() => onFormSubmit()}
            okButtonProps={{ disabled: isSubmitting || form.valid, loading: isSubmitting }}
            okText={props.user?.id ? t('saveChanges') : t('add')}
            forceRender
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item label={t('users.form.status')} name="active" valuePropName="checked">
                    <Checkbox>{t('users.form.active')}</Checkbox>
                </Form.Item>

                <Button
                    type="ghost"
                    onClick={handleActivateTrial}
                    style={{
                        marginTop: -15,
                        marginBottom: 20,
                        borderRadius: 6,
                    }}
                >
                    Activate Trial
                </Button>

                <Form.Item
                    label={t('users.form.first_name')}
                    name="first_name"
                    rules={[{ required: true, message: t('errors.required') }]}
                >
                    <Input placeholder={t('users.form.first_name')} />
                </Form.Item>

                <Form.Item
                    label={t('users.form.last_name')}
                    name="last_name"
                    rules={[{ required: true, message: t('errors.required') }]}
                >
                    <Input placeholder={t('users.form.last_name')} />
                </Form.Item>

                <Form.Item
                    label={t('users.form.email')}
                    name="email"
                    rules={[{ required: true, type: 'email', message: t('errors.enterValidEmail') }]}
                >
                    <Input placeholder={t('users.form.email')} />
                </Form.Item>

                <Form.Item
                    label={t('users.form.role')}
                    name="role"
                    rules={[{ required: true, message: t('errors.required') }]}
                >
                    <Select placeholder={t('users.form.role')} allowClear>
                        <Option value={0}>Super Admin</Option>
                        <Option value={10}>Admin</Option>
                        <Option value={20}>Editor</Option>
                        <Option value={30}>Author</Option>
                        <Option value={40}>User</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default UserModal

import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/context/auth';
import NavigationCard from '../navigationCard';
import { CardsNavigation } from './CardsNavigation';

import './style.scss';

const DashboardNavigation = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const navigation = new CardsNavigation(user.roleID).build();

  return (
    <div>
      {navigation.length && (
        navigation.map(navItem => (
          <div className='category-container' key={navItem.category}>
            <div className='category-title'>
              <Text>{t(navItem.category)}</Text>
            </div>
            <Row gutter={[16, 16]}>
              {navItem.cardItems.map(cardItem => {
                return (
                  <Col xs={24} sm={12} md={6} lg={5} key={cardItem.titleTranslationKey}>
                    <NavigationCard cardItem={cardItem} />
                  </Col>
                )
              })}
            </Row>
          </div>
        ))
      )}
    </div>
  );
};

export default DashboardNavigation;

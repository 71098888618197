import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
};

const ClinicalFeatures = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {/* Clinical */}
        <TabPane
          key={0}
          tab={props.tabs[2].name}
          closable={false}>
          <Tab
            tab={props.tabs[2]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.clinicalFeaturesPlaceholder')}
          />
        </TabPane>

        {/* Etiology and Pathogenesis */}
        <TabPane
          key={1}
          tab={props.tabs[3].name}
          closable={false}>
          <Tab
            tab={props.tabs[3]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.etiologyAndPathogenesisPlaceholder')}
          />
        </TabPane>

        {/* Presentation */}
        <TabPane
          key={2}
          tab={props.tabs[5].name}
          closable={false}>
          <Tab
            tab={props.tabs[5]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.presentationPlaceholder')}
          />
        </TabPane>

        {/* Imaging */}
        <TabPane
          key={3}
          tab={props.tabs[7].name}
          closable={false}>
          <Tab
            tab={props.tabs[7]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.imagingPlaceholder')}
          />
        </TabPane>

        {/* Lab Findings */}
        <TabPane
          key={4}
          tab={props.tabs[8].name}
          closable={false}>
          <Tab
            tab={props.tabs[8]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.labFindingsPlaceholder')}
          />
        </TabPane>

        {/* Syndromes */}
        <TabPane
          key={5}
          tab={props.tabs[9].name}
          closable={false}>
          <Tab
            tab={props.tabs[9]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.syndromesPlaceholder')}
          />
        </TabPane>

        {/* Metastatic sites */}
        <TabPane
          key={6}
          tab={props.tabs[10].name}
          closable={false}>
          <Tab
            tab={props.tabs[10]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.metastaticSitesPlaceholder')}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ClinicalFeatures;

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Table,  
  Popconfirm,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from "antd/lib/table";

import ListViewHeader, { EditableTitle } from 'src/components/common/list-view/header';
import withPrimaryLayout from "src/components/layout/primaryLayout";
import { Chapter, Section } from "src/types";
import { SectionsAPI, ChaptersAPI, UsersAPI } from 'src/api';
import { AuthSvc } from 'src/service/auth'
import ChapterModal from "./modal/chapter"
import { getRole } from 'src/util/roles';

const Chapters = () => {
  const { t } = useTranslation();
  const { sectionId }: any = useParams();
  const fetchChaptersErrorMsg = t('chapters.fetchError');

  const [isChaptersLoading, setChaptersLoading] = useState<boolean>(false);
  const [chapters, setChapters]: [Array<Chapter>, any] = useState([]);
  const [section, setSection]: [Section, any] = useState({} as Section);
  const [newSectionName, setNewSectionName] = useState<any>('');
  const [sectionNameEditVisible, setSectionNameEditVisible] = useState(false);
  const [totalChapters, setTotalChapters] = useState(0);
  const [activeChapter, setActiveChapter]: [Chapter, any] = useState({} as Chapter);
  const [isChapterModalVisible, setChapterModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredChapters, setFilteredChapters] = useState<any>([]);
  const [users, setUsers] = useState<any>([])

  const user = AuthSvc.getUser()

  const getChapters = useCallback(
    async (page: number) => {
      setChaptersLoading(true)

      try {
        const result = await SectionsAPI.listChapters(sectionId, page)
        if (!result || result.status !== 200) {
          throw new Error('Bad response');
        }

        if (result?.data) {
          const data = result.data.map((item: Chapter) => {
            return { key: item.id, ...item };
          });
          setChapters(data);
          setFilteredChapters(data);
          setTotalChapters(parseInt(result.headers['total-items']));
        }
      } catch (error) {
        console.error(error);
        message.error(fetchChaptersErrorMsg);
      } finally {
        setChaptersLoading(false)
      }
    }, [fetchChaptersErrorMsg, sectionId]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredChapters(
        chapters.filter((chapter: any) => chapter.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
      );
    } else {
      setFilteredChapters(chapters);
    }
  }, [searchQuery, chapters]);

  const getSectionDetail = useCallback(
    async () => {
      try {
        const result = await SectionsAPI.detail(sectionId)
        if (!result || result.status !== 200) {
          throw new Error('Bad response');
        }

        if (result?.data) {
          setSection(result?.data)
          setNewSectionName(result?.data.name)
        }
      } catch (error) {
        console.error(error);
        message.error(t('errors.apiCommunication', { api: 'section detail' }));
      }
    }, [t, sectionId]);

  const getUsers = useCallback(
    async () => {
      try {
        const result = await UsersAPI.list()
        if (!result || result.status !== 200) {
          throw new Error('Bad response');
        }

        if (result?.data) {
          const assignableUsers = result.data.filter((user: any) => ['Super Admin', 'Admin', 'Editor', 'Author'].includes(getRole(user.role)))
          setUsers(assignableUsers)
        }
      } catch (error) {
        console.error(error);
        message.error(t('errors.apiCommunication', { api: 'users' }));
      }
    }, [t]);

  const onSaveChapterModalChanges = async (id: number, formValues: any, editors: any) => {
    try {
      const chapter = { id, section_id: Number(sectionId), ...formValues } as Chapter;
      const result = await ChaptersAPI.save(chapter);
      if (!result || result.status !== 200) {
        throw new Error('Bad response');
      }

      setActiveChapter(null);
      setChapterModalVisible(false);
      message.success(id ? t('chapters.updated', { name: chapter.name }) : t('chapters.added', { name: chapter.name }));
      getChapters(1)

    } catch (error) {
      console.error(error)
      message.error(t('chapters.errorCreating'));
    }
  }

  const onDeleteChapter = async (chapter: Chapter) => {
    try {
      const result = await ChaptersAPI.delete(chapter.id)
      if (result?.status === 200) {
        message.success(t('chapters.chapterDeleted', { name: chapter.name }));
        getChapters(1);
      }
    } catch (error) {
      console.error(error);
      message.error(t('chapters.errorDeleting'));
    }
  }

  useEffect(() => {
    getChapters(1);
    getSectionDetail();

    if (user) {
      const r = getRole(user.roleID)
      if (r === 'Super Admin' || r === 'Admin') {
        getUsers();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChapters, getSectionDetail, getUsers]);

  const renderChaptersList = () => {
    const chaptersPagination: TablePaginationConfig = {
      pageSize: 10,
      size: 'small',
      total: totalChapters,
      hideOnSinglePage: true,
      showSizeChanger: false,
      onChange: page => getChapters(page)
    }
    const columns = [
      {
        title: t('chapters.table.columns.name'),
        dataIndex: 'name',
        key: 'name',
        width: 400,
        render: (name: string) => name,
      },
      {
        title: t('chapters.table.columns.editors'),
        dataIndex: 'assigned_users',
        key: 'assigned_users',
        ellipsis: {
          showTitle: false,
        },
        render: (assigned_users: []) => (
          <div>
            {assigned_users?.map((user: any) => (
              <span key={user.id} style={{
                marginRight: 5,
                border: '1px solid #efefef',
                padding: '4px 8px',
                borderRadius: 6  
              }}>{user.first_name} {user.last_name}</span>
            ))}
          </div>
        ),
      },
      {
        title: 'Edit/Delete',
        key: 'action',
        align: 'right' as const,
        render: (value: any, record: any) => (
          <>
            <Button
              type='primary'
              shape='round'
              size='small'
              onClick={() => {
                setActiveChapter(record)
                setChapterModalVisible(true)
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              placement='topLeft'
              title={t('chapters.deletePopup', { name: record.name })}
              onConfirm={() => onDeleteChapter(record)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                danger
                type='primary'
                shape='round'
                size='small'
                style={{ marginLeft: 10 }}
              >
                <CloseCircleOutlined />
              </Button>
            </Popconfirm>
          </>
        )
      },
    ];
    return (
      <Table
        pagination={chaptersPagination}
        columns={columns}
        dataSource={filteredChapters}
        loading={isChaptersLoading}
      />
    );
  };

  const onSearch = (searchQuery: string) => setSearchQuery(searchQuery);

  const editableTitle = {
    isTitleEditable: true,
    newTitleEditVisible: sectionNameEditVisible,
    setNewTitleVisible: setSectionNameEditVisible,
    newTitleValue: newSectionName,
    setNewTitleValue: setNewSectionName,
    onTitleClick: () => {
      setSectionNameEditVisible(!sectionNameEditVisible);
      setNewSectionName(section?.name);
    },
    onSaveNewTitle: () => {
      if (section) {
        section.name = newSectionName;
        setSectionNameEditVisible(!sectionNameEditVisible);
        try {
          SectionsAPI.save(section);
        } catch (error) {
          console.error(error);
        }
      }
    }
  } as EditableTitle;

  return (
    <div className="page">
      <ChapterModal
        isVisible={isChapterModalVisible}
        onCancel={() => {
          setChapterModalVisible(false);
          setActiveChapter({});
        }}
        chapter={activeChapter}
        users={users}
        onOk={onSaveChapterModalChanges}
      />
      <div className="container m-t-11">
        <ListViewHeader
          editableTitle={editableTitle}
          title={section?.name || ''}
          buttonLabel={t('chapters.add')}
          isLoading={isChaptersLoading}
          onButtonClick={() => setChapterModalVisible(true)}
          onSearch={onSearch}
        />
        <div className='page-content m-t-8'>
          {renderChaptersList()}
        </div>
      </div>
    </div>
  );
};

export default withPrimaryLayout(Chapters);

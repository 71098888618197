import API from "./api";

const baseURL = "/steps";

const WizardSteps = {
  list: (): any => {
    return API.get(baseURL);
  }
};

export default WizardSteps;

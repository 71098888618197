import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Form, Row, Col } from 'antd';
import ReactQuill from 'react-quill';

import { useDisease } from 'src/context/disease';
import { editorConfig } from 'src/util/wysiwyg';

const { TabPane } = Tabs;

type Props = {
  step: any;
};

const Reporting = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease ? disease.disease : {};
  const setDiseaseForm: any = disease.setDisease;

  const handleReportingChange = (d: string) => {
    let df = diseaseForm || {};
    df.reporting = d;
    setDiseaseForm({ ...df });
  };

  return (
    <div>
      <Tabs defaultActiveKey='0' type='card' size='large'>
        <TabPane tab={t('diseaseCreation.form.reporting')} key='0'>
          <Row>
            <Col span={20}>
              <Form.Item label={t('diseaseCreation.form.reporting')}>
                <ReactQuill
                  theme='snow'
                  formats={editorConfig.formats}
                  modules={editorConfig.modules}
                  defaultValue={diseaseForm?.reporting || ''}
                  onChange={handleReportingChange}
                  placeholder={t('diseaseCreation.form.reportingPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Reporting;

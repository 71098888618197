import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from 'src/locales/en/translation.json';
import translationDE from 'src/locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

// We can add lazy loading of translation files later (can probably be configured with weblate if needed):
// https://react.i18next.com/legacy-v9/step-by-step-guide#a-adding-lazy-loading-
// f or-translations
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'

import TabKeywordGroups from 'src/pages/diseaseCreation/components/Form/TabKeywordGroups'
import ImagesSelector from 'src/pages/diseaseCreation/components/Form/ImagesSelector'
import useDescription from 'src/hooks/diseaseCreation/useDescription'
import useImages from 'src/hooks/diseaseCreation/useImages'
import { editorConfig } from 'src/util/wysiwyg'
import EditorNotes from './EditorNotes'
import RichText from 'src/components/common/inputs/rich-text'

type Props = {
    tab: any
    tabDescPlaceholder: string
    diseaseForm: any
    kgCol?: number
}

const Tab = (props: Props) => {
    const { tab, diseaseForm, kgCol } = props

    const { t } = useTranslation()
    const [addImage, editImage, removeImage, saveImageOrder] = useImages(tab)
    const setDescription = useDescription()

    return (
        <Row
            style={{
                position: 'relative',
            }}
        >
            <EditorNotes tabID={tab.id} tabName={tab.name} notes={tab.note} diseaseForm={diseaseForm} />
            <Col span={20}>
                <Form.Item label={tab.name}>
                    <RichText
                        editorConfig={editorConfig}
                        value={
                            diseaseForm && diseaseForm.tabs[tab.id] && diseaseForm.tabs[tab.id].description
                                ? diseaseForm.tabs[tab.id].description
                                : ''
                        }
                        placeholder={t('diseaseCreation.form.descriptionPlaceholder')}
                        enableRephrase={true}
                        onChange={(s: any) => setDescription(tab.id, s)}
                        onUseRephrased={(text: string) => {
                            setDescription(tab.id, text)
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={kgCol || 20}>
                <Form.Item label={t('diseaseCreation.form.keywords')}>
                    <TabKeywordGroups tab={tab} readOnly={diseaseForm.form_mode === 'view'} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label={t('diseaseCreation.form.images')}>
                    <ImagesSelector
                        tabId={tab.id}
                        tabName={tab.name}
                        groupedTabs={diseaseForm.groupedTabs}
                        images={diseaseForm.tabs ? diseaseForm.tabs[tab.id]?.images : []}
                        diseaseMode={diseaseForm.form_mode}
                        onAdd={addImage}
                        onEdit={editImage}
                        onRemove={removeImage}
                        onSaveOrder={saveImageOrder}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default Tab

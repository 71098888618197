import Role from 'src/types/role';

export const getRole = (roleID: number) => {
  switch (roleID) {
    case 0:
      return 'Super Admin';
    case 10:
      return 'Admin';
    case 20:
      return 'Editor';
    case 30:
      return 'Author';
    case 40:
      return 'User';
    default:
      return '';
  }
};

export const getRoleEnum = (roleID: number): Role => {
  switch (roleID) {
    case 0:
      return Role.SuperAdmin;
    case 10:
      return Role.Admin;
    case 20:
      return Role.Editor;
    case 30:
      return Role.Author;
    case 40:
      return Role.User;
    default:
      return Role.User;
  }
};

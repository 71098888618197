import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
};

const Gross = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {/* Gross */}
        <TabPane
          key={0}
          tab={props.tabs[11].name}
          closable={false}>
          <Tab
            tab={props.tabs[11]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.grossPlaceholder')}
          />
        </TabPane>

        {/* Handling */}
        <TabPane
          key={1}
          tab={props.tabs[12].name}
          closable={false}>
          <Tab
            tab={props.tabs[12]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.handlingPlaceholder')}
          />
        </TabPane>

        {/* Grossing */}
        <TabPane
          key={2}
          tab={props.tabs[13].name}
          closable={false}>
          <Tab
            tab={props.tabs[13]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.grossingPlaceholder')}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Gross;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import Tab from 'src/pages/diseaseCreation/components/Form/Tab';
import { useDisease } from 'src/context/disease';
import useTabs from 'src/hooks/diseaseCreation/useTabs';

const { TabPane } = Tabs;

type Props = {
  step: any;
  tabs: any;
};

const Immunohistochemistry = (props: Props) => {
  const { t } = useTranslation();
  const disease: any = useDisease();
  const diseaseForm = disease
    ? disease.disease
    : {
        tabs: {},
      };
  const [
    activeTabKey,
    setActiveTabKey
  ] = useTabs(props.step);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        activeKey={activeTabKey}
        size='large'
        type='card'
        onChange={(activeKey) => {
          setActiveTabKey(activeKey);
        }}>

        {/* Immunohistochemistry */}
        <TabPane
          key={0}
          tab={props.tabs[25].name}
          closable={false}>
          <Tab
            tab={props.tabs[25]}
            diseaseForm={diseaseForm}
            kgCol={24}
            tabDescPlaceholder={t('diseaseCreation.form.immunohistochemistryPlaceholder')}
          />
        </TabPane>

        {/* Molecular */}
        <TabPane
          key={1}
          tab={props.tabs[26].name}
          closable={false}>
          <Tab
            tab={props.tabs[26]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.molecularPlaceholder')}
          />
        </TabPane>

        {/* Special stains */}
        <TabPane
          key={2}
          tab={props.tabs[27].name}
          closable={false}>
          <Tab
            tab={props.tabs[27]}
            diseaseForm={diseaseForm}
            tabDescPlaceholder={t('diseaseCreation.form.specialStainsPlaceholder')}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Immunohistochemistry;
import { Space, Spin } from 'antd';
import * as React from 'react';

const LoadingPlaceholder = (props: any) => {
  return (
    <Space className='container-loader-wrapper' size='large'><Spin size='large' /></Space>
  );
};

export default LoadingPlaceholder;

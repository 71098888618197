import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader, Button, Card, Form, Input, Divider, Row, Col, Avatar, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';

import withPrimaryLayout from 'src/components/layout/primaryLayout'
import { useAuth } from 'src/context/auth'
import { Validate } from 'src/util/validation'
import AccountAPI from 'src/api/account'
import ImageUpload from 'src/components/common/image-upload'
import { AuthSvc } from 'src/service/auth'
import './style.scss'

const IMG_BASE_URL = process.env.REACT_APP_IMG_BASE_URL

const AccountPage = () => {
    const { t } = useTranslation()
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [firstName, setFirstname] = useState(auth.user.firstName)
    const [lastName, setLastname] = useState(auth.user.lastName)
    const [email, setEmail] = useState(auth.user.email)
    const [profileImg, setProfileImg] = useState(auth.user.avatar)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState('')

    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        const isFormValid =
            !!firstName &&
            !!lastName &&
            !Validate.email(email) &&
            (((!!newPassword || !!newPasswordConfirmation) && newPassword === newPasswordConfirmation) ||
                (!newPassword && !newPasswordConfirmation))

        if (
            (firstName !== auth.user.firstName ||
                lastName !== auth.user.lastName ||
                email !== auth.user.email ||
                profileImg !== auth.user.avatar ||
                newPassword ||
                newPasswordConfirmation) &&
            isFormValid
        ) {
            setIsDirty(true)
        } else {
            setIsDirty(false)
        }

        if (!newPassword && !newPasswordConfirmation) {
            setNewPasswordErrorMsg('')
        }
    }, [
        firstName,
        lastName,
        email,
        newPassword,
        newPasswordConfirmation,
        profileImg,
        auth.user.firstName,
        auth.user.lastName,
        auth.user.email,
        auth.user.avatar,
    ])

    // const onSetPasswordConfirmation = (passwordConfirmation: string) => {
    //     if (newPassword === passwordConfirmation) {
    //         setNewPasswordErrorMsg('')
    //     } else {
    //         setNewPasswordErrorMsg('Passwords have to be equal.')
    //     }
    //     setNewPasswordConfirmation(passwordConfirmation)
    // }

    const handleSaveAccountDetails = async () => {
        setIsLoading(true)
        const user = {
            id: auth.user.id,
            first_name: firstName,
            last_name: lastName,
            email,
            avatar: profileImg,
            active: true
        } as any

        try {
            let res = await AccountAPI.update(user)

            if (!res || res.status !== 200) {
                throw new Error('Bad response')
            }
            user.roleID = auth.user.roleID
            user.role = auth.user.role
            auth.updateUser({ ...user, firstName, lastName  })
            AuthSvc.setUser({ ...user, firstName, lastName  })
            message.success(t('accountSettings.success'))
        } catch (e) {
            message.error(t('accountSettings.error'))
        } finally {
            setIsLoading(false)
        }
    }

    const handleSetImage = (imgName: string) => {
        const newImg = IMG_BASE_URL + '/2/' + imgName
        setProfileImg(newImg)
    }

    return (
        <div className="page">
            <div className="container-m">
                <PageHeader
                    ghost={true}
                    title={t('accountSettings.title')}
                    extra={[
                        <Button
                            key={1}
                            type="primary"
                            shape="round"
                            loading={isLoading}
                            disabled={!isDirty}
                            onClick={handleSaveAccountDetails}
                        >
                            {t('accountSettings.save')}
                        </Button>,
                    ]}
                ></PageHeader>

                <div className="page-content">
                    <Card>
                        <Form layout="vertical">
                            <Row gutter={32}>
                                <Col className="gutter-row" span={12}>
                                    <section>
                                        <Form.Item label={t('accountSettings.form.firstName')}>
                                            <Input
                                                placeholder={t('accountSettings.form.firstName')}
                                                value={firstName}
                                                onChange={(e) => setFirstname(e.target.value)}
                                            />
                                        </Form.Item>

                                        <Form.Item label={t('accountSettings.form.lastName')}>
                                            <Input
                                                placeholder={t('accountSettings.form.lastName')}
                                                value={lastName}
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </Form.Item>

                                        <Form.Item label={t('accountSettings.form.email')}>
                                            <Input
                                                placeholder={t('accountSettings.form.email')}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Item>

                                        <Divider />

                                        {/* <Form.Item label={t('accountSettings.form.newPassword')}>
                                            <Input
                                                type='password'
                                                placeholder={t('accountSettings.form.newPassword')}
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </Form.Item>

                                        <Form.Item label={t('accountSettings.form.newPasswordConfirmation')}>
                                            <Input
                                                type='password'
                                                placeholder={t('accountSettings.form.newPasswordConfirmation')}
                                                value={newPasswordConfirmation}
                                                onChange={(e) => onSetPasswordConfirmation(e.target.value)}
                                            />
                                        </Form.Item> */}

                                        {newPasswordErrorMsg && <div>{newPasswordErrorMsg}</div>}
                                    </section>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    {/* Image section */}
                                    <section className="avatar-upload">
                                        {!profileImg ? <Avatar size={250} className="avatar-placeholder" icon={<UserOutlined />} /> : <img src={profileImg} alt="profile img" />}
                                        <ImageUpload onSetImage={handleSetImage} />
                                    </section>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(AccountPage)

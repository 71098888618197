import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, message } from 'antd'
import mixpanel from 'mixpanel-browser'

import { AuthAPI } from 'src/api/auth'
import PublicLayout from 'src/components/layout/publicLayout'
import CreatePasswordForm from './components/form'
import { CreatePasswordReq } from 'src/types/auth'

function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

const CreatePasswordPage = () => {
    mixpanel.track('Visit Create Password Page')

    const navigate = useNavigate()
    let query = useQuery()
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    let token = ''
    if (query && query.get('token')) {
        try {
            token = query.get('token') || ''
        } catch {
            console.error('Create Password Page: Error with parsing query params')
        }
    }

    const handleSubmit = async (formData: CreatePasswordReq) => {
        setLoading(true)
        try {
            await AuthAPI.resetPassword(formData)

            if (query && query.get('t') === 'p') {
                const t: string = process.env.REACT_APP_PUBLIC_APP_URL || 'https://app.pathriver.com' // Fallback to PROD if no env var set
                window.location.href = t
            } else {
                navigate('/login')
            }
            message.success(t('createPassword.success'))
        } catch (error) {
            message.error(t('createPassword.error'))
        } finally {
            setLoading(false)
        }
    }

    return (
        <PublicLayout>
            <Row>
                <Col span={24}>
                    <h2 className="login-logo text-center">Create your password</h2>
                    <CreatePasswordForm loading={loading} token={token} onSubmit={handleSubmit} />

                    <div>
                        <p className="text-center" style={{ marginTop: 10 }}>
                            Already created your password?{' '}
                            <a href="https://app.pathriver.com">
                                <b>Go to Login</b>
                            </a>
                        </p>
                    </div>
                </Col>
            </Row>
        </PublicLayout>
    )
}

export default CreatePasswordPage

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, Row, Col } from 'antd';
import {
  Outlet,
  NavLink,
} from 'react-router-dom';

import withPrimaryLayout from 'src/components/layout/primaryLayout';
import './style.scss';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <div className='page my-contribution'>
      <div className='container'>
        <PageHeader ghost={true} title={t('settings.settings')}></PageHeader>

        <Row gutter={16}>
          <Col span={4} className='menu-wrapper'>
            <ul>
              <li>
                <NavLink
                  to='/settings/disease/tab-keywords'
                  className={({ isActive }) => "" + (isActive ? " activated" : "")}
                  >
                  {t('settings.tabKeywords')}
                </NavLink>
              </li>
            </ul>
          </Col>
          <Col span={20} className='pane-wrapper'>
              <Outlet />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withPrimaryLayout(Settings);

import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Table, Popconfirm, message } from 'antd'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'

import ListViewHeader from 'src/components/common/list-view/header'
import UsersAPI from 'src/api/users'
import { User, SearchParams } from 'src/types'
import UserModal from './modal/user'
import withPrimaryLayout from 'src/components/layout/primaryLayout'
import { getRole } from 'src/util/roles'
import { qp } from 'src/util/url'

const UsersPage = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchUsersErrorMsg = t('users.fetchError')

    const [users, setUsers] = useState([])
    const [isDataLoading, setUsersLoading] = useState<boolean>(false)
    const [totalItems, setTotalItems] = useState(0)
    const [modalUser, setModalUser] = useState<any>()
    const [isModalVisible, setModalVisible] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [searchQParams, setSearchParams] = useState<SearchParams>({
        q: searchParams.get('q') || '',
        page: Number(searchParams.get('page') || 1),
    })

    const memoizedGetUsers = useCallback(
        async (searchQParams: SearchParams) => {
            setUsersLoading(true)
            try {
                const res = await UsersAPI.list(searchQParams.page, searchQParams.q)

                if (res.status !== 200) {
                    throw new Error('Bad response')
                }

                setUsers(res.data || [])
                setTotalItems(parseInt(res.headers['total-items']))
                setTotalPages(parseInt(res.headers['total-pages']))
            } catch (error) {
                message.error(fetchUsersErrorMsg)
            } finally {
                setUsersLoading(false)
            }
        },
        [fetchUsersErrorMsg]
    )

    useEffect(() => {
        memoizedGetUsers(searchQParams)
    }, [memoizedGetUsers, searchQParams])

    const renderUsersList = () => {
        const dataSource = users.map((user: User, i: number) => ({
            index: (Number(searchQParams?.page - 1) * 30) + i + 1,
            key: user.id,
            ...user,
        }))
        const columns = [
            {
                title: '',
                render: (val: string, record: any) => record.index,
            },       
            {
                title: t('users.table.columns.name'),
                dataIndex: 'name',
                key: `name`,
                render: (val: string, record: User) => <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <span style={{
                    background: record.active ? 'rgb(82, 196, 26)' : '#ccc',
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    display: 'inline-block'
                }} />
                    {`${record.first_name} ${record.last_name}`}
                </div>,
            },
            {
                title: t('users.table.columns.email'),
                dataIndex: 'email',
                key: `email`,
                render: (val: string) => val,
            },
            {
                title: t('users.table.columns.role'),
                dataIndex: 'role',
                key: `role`,
                render: (val: number) => getRole(val),
            },
            {
                title: t('users.table.columns.activation_url'),
                dataIndex: 'token',
                key: `token`,
                render: (t: string) =>
                    t ? (
                        <a
                            href={`https://admin.pathriver.com/activate?token=${t}&t=p`}
                            target="_blank"
                            title="Activation URL" rel="noopener noreferrer"
                        >
                            URL
                        </a>
                    ) : null,
            },
            {
                title: 'Edit/Delete',
                key: 'action',
                align: 'right' as const,
                render: (value: any, record: User) => (
                    <>
                        <Button
                            type="primary"
                            shape="round"
                            size="small"
                            onClick={() => {
                                setModalUser(record)
                                setModalVisible(true)
                            }}
                        >
                            <EditOutlined />
                        </Button>

                        <Popconfirm
                            placement="topLeft"
                            title={t('users.deletePopup', {
                                name: `${record.first_name} ${record.last_name}`,
                            })}
                            onConfirm={() => onDeleteUser(record)}
                            okText={t('yes')}
                            cancelText={t('no')}
                        >
                            <Button danger type="primary" shape="round" size="small" style={{ marginLeft: 10 }}>
                                <CloseCircleOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                ),
            },
        ]

        return (
            <Table
                pagination={{
                    total: totalItems,
                    pageSize: 30,
                    current: Number(searchQParams.page),
                    onChange: onPageChange,
                    pageSizeOptions: [],
                }}
                loading={isDataLoading}
                dataSource={dataSource}
                columns={columns}
            />
        )
    }

    const onPageChange = (page: number) => {
        const q = searchParams.get('q') || ''
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery: q, page }),
        })

        setSearchParams({
            page: page,
            q,
        })

        if (page === searchQParams.page) {
            memoizedGetUsers(searchQParams)
        }
    }

    const onSearch = (searchQuery: string) => {
        navigate({
            pathname: location.pathname,
            search: qp({ searchQuery }),
        })

        setSearchParams({
            q: searchQuery.trim(),
            page: 1,
        })
    }

    const onApplyUserModalChanges = async (id: any, formValues: any) => {
        if (formValues.firstName) formValues.first_name = formValues.firstName
        if (formValues.lastName) formValues.last_name = formValues.lastName

        const user = { id, ...formValues } as User
        try {
            const res = await UsersAPI.save(user)

            if (!res || res.status !== 200) {
                throw new Error('Bad response')
            }

            setModalUser(null)
            setModalVisible(false)
            message.success(
                id
                    ? t('users.updated', {
                          name: `${user.first_name} ${user.last_name}`,
                      })
                    : t('users.added', {
                          name: `${user.first_name} ${user.last_name}`,
                      })
            )
            if (!id) {
                onPageChange(totalPages)
            } else {
                onPageChange(1)
            }
        } catch (error) {
            message.error(t('errors.apiCommunication', { api: 'users' }))
        }
    }

    const onActivateTrial = async (userID: number) => {
        try {
            const res = await UsersAPI.updateSubscription(userID, 1)

            if (!res || res.status !== 200) {
                throw new Error('Bad response')
            }

            setModalUser(null)
            setModalVisible(false)
            message.success('Trial activated');
        } catch (error) {
            message.error(t('errors.apiCommunication', { api: 'users' }))
        }        
    }

    const onDeleteUser = async (user: User) => {
        try {
            await UsersAPI.delete(user.id)
            message.success(
                t('users.deleted', {
                    name: `${user.first_name} ${user.last_name}`,
                })
            )
            onPageChange(1)
        } catch (error) {
            message.error(t('errors.apiCommunication', { api: 'users' }))
        }
    }

    return (
        <div className="page users">
            {isModalVisible && (
                <UserModal
                    onOk={onApplyUserModalChanges}
                    onActivateTrial={onActivateTrial}
                    onCancel={() => {
                        setModalVisible(false)
                        setModalUser(null)
                    }}
                    user={modalUser}
                    isVisible={true}
                />
            )}
            <div className="container">
                <ListViewHeader
                    buttonLabel={t('users.add')}
                    title={t('users.users')}
                    onSearch={onSearch}
                    defaultValue={searchQParams?.q}
                    isLoading={isDataLoading}
                    onButtonClick={() => setModalVisible(true)}
                />
                <div className="page-content">{renderUsersList()}</div>
            </div>
        </div>
    )
}

export default withPrimaryLayout(UsersPage)

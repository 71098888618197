import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Input, InputNumber, Form, Row, Col, message, Alert, Select, Popover, Button, Tooltip } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { useDisease } from 'src/context/disease'
import SectionsAPI from 'src/api/sections'

import SectionSelector from './../SectionSelector'
import ChapterSelector from './../ChapterSelector'
import TabKeywordGroups from './../TabKeywordGroups'
import EditorNotes from './../EditorNotes'

import RichText from 'src/components/common/inputs/rich-text'

import { editorConfig } from 'src/util/wysiwyg'

import { Section } from 'src/types/section'
import { Chapter } from 'src/types/chapter'

const { TabPane } = Tabs
const { Option } = Select

type Props = {
    sections: Array<Section>
    diseases: Array<{}>
    tabs: any
    mode?: string
    onSetQuick?: (d: {}) => void
    onGotChapters?: (chapters: any) => void
}

const General = (props: Props) => {
    const { t } = useTranslation()
    let disease: any = useDisease()
    const [quickDisease, setQuickDisease] = React.useState({})

    const diseaseForm = props.mode !== 'quick' && disease ? disease.disease : { tabs: {} }
    const setDiseaseForm: any = props.mode !== 'quick' ? disease.setDisease : setQuickDisease
    const [chapters, setChapters]: [Array<Chapter>, any] = React.useState([])
    const diseaseTitles = props.diseases && props.diseases.length ? props.diseases.map((d: any) => d.title) : []
    const [diseaseTitleExists, setDiseaseTitleExists] = React.useState(false)

    const diseaseObject = props.mode !== 'quick' ? diseaseForm : quickDisease

    React.useEffect(() => {
        const handleChapter = async () => {
            if (diseaseObject && diseaseObject.section_id) {
                getChaptersForSection(diseaseObject.section_id)
            }
        }

        handleChapter()
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (props.onSetQuick) props.onSetQuick(quickDisease)
        // eslint-disable-next-line
    }, [quickDisease])

    const getChaptersForSection = async (sectionID: number) => {
        try {
            const res = await SectionsAPI.listChapters(sectionID)

            if (!res || !res.data) {
                return
            }

            setChapters(res.data)
            if (props.onGotChapters) props.onGotChapters(res.data)
        } catch (error) {
            message.error(t('chapters.errorGetting'))
            console.error('Error getting chapters for section: ', error)
        }
    }

    const handleSelectSection = (sectionID: number) => {
        if (sectionID === diseaseObject.section_id) {
            return
        }

        setDiseaseForm({ ...diseaseObject, section_id: sectionID, chapter_id: null })
        getChaptersForSection(sectionID)
    }

    const handleSelectChapter = (chapterID: number) => {
        setDiseaseForm({ ...diseaseObject, chapter_id: chapterID })
    }

    // Check if a disease with this name already exists
    const checkDiseaseTitle = (title: string) => {
        if (!diseaseTitles || !diseaseTitles.length || !diseaseTitles.includes(title)) {
            setDiseaseTitleExists(false)
            return
        }

        setDiseaseTitleExists(true)
    }

    const handleAddSynonym = (list: string[], s: string) => {
        let at = list || []
        at.push(s)
        setDiseaseForm({ ...diseaseObject, alternative_titles: [...at] })
    }

    const handleRemoveSynonym = (list: string[], i: number) => {
        let at = list
        at.splice(i, 1)

        let df = diseaseObject
        df.alternative_titles = [...at]

        setDiseaseForm({ ...df })
    }

    const handleDefinitionChange = (d: string) => {
        setDiseaseForm({ ...diseaseObject, definition: d })
    }

    let tabBarStyle = {}
    if (props.mode === 'quick') {
        tabBarStyle = {
            display: 'none',
        }
    }

    const renderNotes = (notes: any) => {
        return (
            <Popover content={<div>{notes}</div>} title="Notes" trigger="click">
                <Button>Notes</Button>
            </Popover>
        )
    }

    const renderIcds = () => {
        const icds = diseaseObject.icds || [{}]

        return icds.map((icd: any, i: number) => {
            return (
                <Row gutter={12} key={i}>
                    <Col span={6}>
                        <Form.Item label={t('diseaseCreation.form.icd-o-morphology')}>
                            <InputNumber
                                style={{ textAlign: 'center', width: '100%' }}
                                placeholder={t('diseaseCreation.form.icd-o-morphology-code-example')}
                                readOnly={diseaseObject.form_mode === 'view'}
                                value={icd.code}
                                type="number"
                                onChange={(v) => {
                                    setDiseaseForm({
                                        ...diseaseObject,
                                        icds: [...icds.slice(0, i), { ...icd, code: v }, ...icds.slice(i + 1)],
                                    })
                                }}
                                max={9999}
                                controls={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={t('diseaseCreation.form.icd-o-behavior')}>
                            <Select
                                placeholder={t('diseaseCreation.form.icd-o-behavior-example')}
                                value={icd.category}
                                onSelect={(icd_category) => {
                                    setDiseaseForm({
                                        ...diseaseObject,
                                        icds: [
                                            ...icds.slice(0, i),
                                            { ...icd, category: parseInt(icd_category) },
                                            ...icds.slice(i + 1),
                                        ],
                                    })
                                }}
                                allowClear
                                onClear={() => {
                                    setDiseaseForm({
                                        ...diseaseObject,
                                        icds: [...icds.slice(0, i), { ...icd, category: null }, ...icds.slice(i + 1)],
                                    })
                                }}
                                defaultValue={null}
                                showSearch
                            >
                                <Option value={0}>0</Option>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={6}>6</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Row
                            gutter={2}
                            style={{
                                marginTop: 40,
                            }}
                        >
                            {diseaseObject.form_mode !== 'view' ? (
                                <Col span={11} className="keywordGroup-control">
                                    {icds.length > 1 && (
                                        <Tooltip title="Remove this ICD" placement="bottom">
                                            <MinusCircleOutlined onClick={() => handleRemoveIcd(i)} />
                                        </Tooltip>
                                    )}
                                </Col>
                            ) : null}
                            {diseaseObject.form_mode !== 'view' ? (
                                <Col span={11} offset={1} className="keywordGroup-control">
                                    {i === icds.length - 1 && (
                                        <Tooltip title="Add another ICD" placement="bottom">
                                            <PlusCircleOutlined onClick={handleAddIcd} />
                                        </Tooltip>
                                    )}
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )
        })
    }

    const handleAddIcd = () => {
        setDiseaseForm({
            ...diseaseObject,
            icds: [
                ...diseaseObject.icds,
                {
                    code: null,
                    category: null,
                },
            ],
        })
    }

    const handleRemoveIcd = (i: number) => {
        let icds = diseaseObject.icds
        icds.splice(i, 1)

        setDiseaseForm({
            ...diseaseObject,
            icds: [...icds],
        })
    }

    const renderIcd11s = () => {
        let icdsArray = diseaseObject?.icd_11_coding?.split(',') || [''];

        return icdsArray.map((icd: string, i: number) => {
            return (
                <Row gutter={12} key={i}>
                    <Col span={6}>
                        <Form.Item label={t('diseaseCreation.form.icd-11-coding')}>
                            <Input
                                style={{ width: '100%' }}
                                placeholder={t('diseaseCreation.form.icd-11-coding')}
                                readOnly={diseaseObject.form_mode === 'view'}
                                value={icd}
                                onChange={(e) => {
                                    const newIcds = [...icdsArray]
                                    newIcds[i] = e.target.value;
                                    const formattedIcd11s = newIcds.join(',')

                                    setDiseaseForm({
                                        ...diseaseObject,
                                        icd_11_coding: formattedIcd11s,
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Row
                            gutter={2}
                            style={{
                                marginTop: 40,
                            }}
                        >
                            {diseaseObject.form_mode !== 'view' ? (
                                <Col span={11} className="keywordGroup-control">
                                    {icdsArray.length > 1 && (
                                        <Tooltip title="Remove this ICD 11" placement="bottom">
                                            <MinusCircleOutlined onClick={() => handleRemoveIcd11(i)} />
                                        </Tooltip>
                                    )}
                                </Col>
                            ) : null}
                            {diseaseObject.form_mode !== 'view' ? (
                                <Col span={11} offset={1} className="keywordGroup-control">
                                    {i === icdsArray.length - 1 && (
                                        <Tooltip title="Add another ICD 11" placement="bottom">
                                            <PlusCircleOutlined onClick={handleAddIcd11} />
                                        </Tooltip>
                                    )}
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )
        })
    }

    const handleAddIcd11 = () => {
        setDiseaseForm({
            ...diseaseObject,
            icd_11_coding: [
                ...diseaseObject.icd_11_coding.split(','),
                '',
            ].join(','),
        })
    }

    const handleRemoveIcd11 = (i: number) => {
        let icds = diseaseObject.icd_11_coding.split(',')
        icds.splice(i, 1)

        setDiseaseForm({
            ...diseaseObject,
            icd_11_coding: [...icds].join(','),
        })
    }

    return (
        <div>
            <Tabs defaultActiveKey="1" type="card" size="large" tabBarStyle={tabBarStyle}>
                <TabPane tab="General" key="1">
                    {props.tabs[1] && props.tabs[1].notes ? renderNotes(props.tabs[1].notes) : null}
                    <Row gutter={12} style={{ position: 'relative' }}>
                        <EditorNotes
                            tabID={props.tabs[1].id}
                            tabName="General"
                            notes={props.tabs[1].note}
                            diseaseForm={diseaseForm}
                        />
                        <Col span={8}>
                            <Form.Item label={`${t('sections.section')}*`}>
                                <SectionSelector
                                    disabled={diseaseObject.form_mode === 'view'}
                                    sections={props.sections}
                                    selected={diseaseObject.section_id}
                                    onSelect={handleSelectSection}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label={`${t('chapters.chapter')}*`}>
                                <ChapterSelector
                                    disabled={diseaseObject.form_mode === 'view'}
                                    chapters={chapters}
                                    selected={chapters ? diseaseObject.chapter_id : null}
                                    onSelect={handleSelectChapter}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20}>
                            <Form.Item label={`${t('diseaseCreation.form.title')}*`}>
                                <Input
                                    placeholder={t('diseaseCreation.form.title')}
                                    readOnly={diseaseObject.form_mode === 'view'}
                                    value={diseaseObject.title}
                                    onChange={(e) => {
                                        setDiseaseForm({ ...diseaseObject, title: e.target.value })
                                        checkDiseaseTitle(e.target.value)
                                    }}
                                />
                                {diseaseTitleExists ? (
                                    <Alert
                                        message={t('diseaseCreation.form.titleTaken')}
                                        type="warning"
                                        showIcon={true}
                                        style={{ marginTop: 2 }}
                                    />
                                ) : null}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20}>
                            <Form.Item label={t('diseaseCreation.form.titleSynonyms')}>
                                <TitleSynonyms
                                    readOnly={diseaseObject.form_mode === 'view'}
                                    list={diseaseObject.alternative_titles}
                                    onAdd={handleAddSynonym}
                                    onRemove={handleRemoveSynonym}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20}>
                            <Form.Item label={t('diseaseCreation.form.definition')}>
                                <RichText
                                    readOnly={diseaseObject.form_mode === 'view'}
                                    editorConfig={editorConfig}
                                    value={diseaseObject?.definition || ''}
                                    onChange={handleDefinitionChange}
                                    enableRephrase={true}
                                    onUseRephrased={(text: string) => {
                                        setDiseaseForm({ ...diseaseObject, definition: text })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {renderIcds()}

                    <div style={{ borderTop: '1px solid #eee', margin: '10px 0 20px 0' }}></div>

                    {renderIcd11s()}

                    <Row
                        gutter={12}
                        style={{
                            borderTop: '1px solid #eee',
                            borderBottom: '1px solid #eee',
                            paddingTop: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        <Col span={6}>
                            <Form.Item label={t('diseaseCreation.form.snomed-ct-code')}>
                                <Input
                                    placeholder={t('diseaseCreation.form.snomed-ct-code')}
                                    readOnly={diseaseObject.form_mode === 'view'}
                                    value={diseaseObject.snomed_ct_code}
                                    onChange={(e) => {
                                        setDiseaseForm({
                                            ...diseaseObject,
                                            snomed_ct_code: e.target.value,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <TabKeywordGroups tab={props.tabs[1]} readOnly={diseaseObject.form_mode === 'view'} />
                </TabPane>
            </Tabs>
        </div>
    )
}

const { Search } = Input

const TitleSynonyms = (props: any) => {
    const { t } = useTranslation()
    const { onAdd, onRemove, list, readOnly } = props
    const [title, setTitle] = React.useState('')

    return (
        <>
            <Row gutter={[8, 16]}>
                {!readOnly ? (
                    <Col span={24}>
                        <Search
                            allowClear
                            className="title-synonym-input"
                            placeholder={t('diseaseCreation.form.addTitleSynonym')}
                            enterButton={t('add')}
                            value={title}
                            onChange={(e) => {
                                e.preventDefault()
                                setTitle(e.target.value)
                            }}
                            onSearch={(s) => {
                                if (s) {
                                    setTitle('')
                                    onAdd(list, s)
                                }
                            }}
                        />
                    </Col>
                ) : null}
                {list && list.length ? (
                    <Col span={24}>
                        {list.map((s: string, i: number) => (
                            <span
                                key={`synonym-${i}`}
                                onClick={() => {
                                    console.log('remove')
                                    onRemove(list, i)
                                }}
                                className="synonym-tag"
                            >
                                {s}
                                {!readOnly ? <span>X</span> : null}
                            </span>
                        ))}
                    </Col>
                ) : (
                    <small style={{ marginLeft: 5 }}>{t('diseaseCreation.form.noSynonymsAdded')}</small>
                )}
            </Row>
        </>
    )
}

export default General

import React from 'react'
import { Drawer } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import ReactQuill from 'react-quill'

import useEditorNotes from 'src/hooks/diseaseCreation/useEditorNotes'
import { editorConfig } from 'src/util/wysiwyg'

type Props = {
    tabID: number
    tabName: string
    notes: string
    diseaseForm: any
}

const EditorNotes = (props: Props) => {
    const [visible, setVisible] = React.useState(false)
    const setEditorNotes = useEditorNotes()

    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }

    const diseaseForm = props.diseaseForm
    const hasNote = diseaseForm && diseaseForm.tabs[props.tabID] && diseaseForm.tabs[props.tabID].note

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    fontSize: 22,
                    color: hasNote ? '#6213ea' : '#5d5d5d'
                }}
            >
                <FormOutlined onClick={showDrawer} color="red" />
            </div>
            <Drawer
                title={`${props.tabName} Notes`}
                placement="right"
                onClose={onClose}
                visible={visible}
                size="large"
                className="editor-notes-drawer"
            >   
                <ReactQuill
                    theme="snow"
                    formats={editorConfig.formats}
                    modules={editorConfig.modules}
                    value={hasNote ? diseaseForm.tabs[props.tabID].note : ''}
                    onChange={(s) => setEditorNotes(props.tabID, s)}
                    placeholder="You can use this area to capture notes for the current tab"
                />
            </Drawer>
        </React.Fragment>
    )
}

export default EditorNotes
